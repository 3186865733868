import React from "react";

function Obgcon() {
  return (
    <>
      <div>
        <img className="w-full mb-4" src="../photo/aa12.jpg" alt="" />
        <h1 style={{ color: "#3d7cc9" }} className="text-lg font-medium mb-4">
          Optimal Home Obstetrics and Gynecology Collection Services in
          Whitefield, Bangalore.
        </h1>
        <p className="mb-4">
          Welcome to ELV Health Care Polyclinics, a leading center for
          Obstetrics and Gynecology services, where our dedicated team of
          healthcare professionals is committed to providing comprehensive and
          compassionate care for women's health. At ELV, we understand the
          unique healthcare needs of women throughout their life stages, and our
          Obstetrics and Gynecology services are designed to cater to diverse
          needs, from routine wellness checks to specialized maternity care.
        </p>
        <p className="mb-4">
          {" "}
          ELV Health Care Polyclinics offers a wide range of Obstetrics and
          Gynecology services, including prenatal care, childbirth services,
          gynecological screenings, family planning, and reproductive health.
          Our obstetricians and gynecologists are dedicated to ensuring the
          well-being of women, addressing conditions such as infertility,
          menstrual disorders, and gynecological cancers. From routine
          examinations to complex gynecological surgeries, ELV is your trusted
          partner in achieving optimal women's health.
        </p>
        <p className="mb-4">
          {" "}
          Explore our website to discover the comprehensive Obstetrics and
          Gynecology services available at ELV Health Care Polyclinics. We
          prioritize accessibility and convenience, offering online appointment
          scheduling and telemedicine options for consultations. ELV is
          committed to providing a patient-centered approach, ensuring that each
          woman receives personalized care that aligns with her specific
          obstetric and gynecological needs and concerns.
        </p>
        <p className="mb-4">
          {" "}
          ELV Health Care Polyclinics takes pride in offering specialized
          Obstetrics and Gynecology subspecialty services, including high-risk
          pregnancy care, gynecological oncology, and menopausal health. Our
          multidisciplinary approach allows us to provide tailored care for
          women of all ages, addressing a wide range of reproductive and
          gynecological conditions. Our collaborative team of obstetricians and
          gynecologists works together to deliver comprehensive and integrated
          healthcare solutions for women with diverse health challenges.
        </p>
        <p className="mb-4">
          {" "}
          Experience the difference at ELV Health Care Polyclinics, where our
          commitment to excellence in Obstetrics and Gynecology services is
          evident in the quality of our care, the expertise of our healthcare
          professionals, and the positive impact we strive to make on the health
          and well-being of women in our community. Whether you require routine
          obstetric and gynecological check-ups or specialized maternity care,
          ELV is your preferred destination for women's health, where our focus
          on expertise and compassionate service sets us apart as a leader in
          Obstetrics and Gynecology services.
        </p>
      </div>
    </>
  );
}

export default Obgcon;
