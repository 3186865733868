import { DiAtom } from "react-icons/di";
import { GiHospitalCross } from "react-icons/gi";
import { FaHospital } from "react-icons/fa6";
import { FaUserDoctor } from "react-icons/fa6";
import { ImTrophy } from "react-icons/im";
import { useState } from "react";
export default function NumberInfo({ test }: { test: string }) {
  const [conts, setConts] = useState("");
  function sender() {
    let url = "https://wa.me/+919535264855?text=";
    url = `${url}` + encodeURIComponent(conts);

    const whatsappWindow = window.open(url, "_blank");

    if (whatsappWindow) {
      whatsappWindow.focus();
    }

    setConts("");
  }
  const menu = [
    {
      name: "Happy Patients",
      num: "18k+",
      icon: <GiHospitalCross className="text-5xl font-bold" />,
    },
    {
      name: "OPD Departments",
      num: "14+",
      icon: <FaHospital className="text-5xl font-bold" />,
    },
    {
      name: "Experienced Doctors",
      num: "25+",
      icon: <FaUserDoctor className="text-5xl font-bold" />,
    },
    {
      name: "Awards",
      num: "6+",
      icon: <ImTrophy className="text-5xl font-bold" />,
    },
  ];
  return (
    <>
      <section className="flex justify-center pt-3 pb-4">
        <div className="containerbox">
          <div className="w-full pt-4 flex flex-wrap">
            {menu.map((elem) => {
              return (
                <>
                  <div className="w-full sm:w-1/2 lg:w-1/4 p-2">
                    <div
                      // style={{
                      //   boxShadow:
                      //     "rgba(50, 50, 93, 0.1) 0px 50px 100px -20px, rgba(0, 0, 0, 0.1) 0px 30px 60px -30px, rgba(10, 37, 64, 0.1) 0px -2px 6px 0px inset",
                      // }}
                      style={{
                        border: "1.5px solid #3d7cc9",
                      }}
                      className="flex justify-between bg-white shadow-sm rounded-md p-3 relative items-center border"
                    >
                      <div>
                        <h4 className="text-3xl font-medium">{elem.num}</h4>
                        <h6 style={{ color: "#3d7cc9" }} className="font-bold">
                          {elem.name}
                        </h6>
                      </div>
                      <div style={{ color: "#48b95f" }}>{elem.icon}</div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          <h1
            className={`text-2xl font-bold leading-snug text-center mt-3 ${test}`}
          >
            Contact via WhatsApp.
          </h1>
          <p className={`text-center ${test}`}>
            Please contact us with your question and needs, our team would be
            delighted to assist you promptly
          </p>
          <div className="flex flex-wrap justify-center my-2">
            <input
              className="w-full sm:w-2/3 lg:w-1/3 h-10 border rounded pl-2"
              type="text"
              placeholder="Whatsapp Message"
              onChange={(e) => {
                setConts(e.target.value);
              }}
            />
            <button
              onClick={() => {
                sender();
              }}
              style={{ backgroundColor: "#48b95f" }}
              className="border-inherit border px-4 py-2 rounded-md mt-2 sm:mt-0 text-white"
            >
              Send
            </button>
          </div>
        </div>
      </section>
    </>
  );
}
