import { data } from "../../../App";
import React, { useContext } from "react";

function Dentalcon() {
  const blo = [
    {
      name: "Dental Aesthetics",
      img: "../photo/den1.jpg",
      cont1:
        "Welcome to ELV Health Care Polyclinics, where we prioritize your dental health and aesthetics through our specialized Dental Aesthetics services. Our hospital is committed to providing a comprehensive range of dental treatments that not only address oral health but also enhance the aesthetic appeal of your smile. At ELV, our team of skilled dental professionals combines the latest technology with artistic precision to deliver personalized and transformative dental aesthetic solutions.",
      cont2:
        "ELV Health Care Polyclinics offers a spectrum of Dental Aesthetics services, including teeth whitening, cosmetic bonding, veneers, and smile makeovers. Our experienced dentists understand the importance of a radiant smile in boosting confidence and overall well-being. Whether you're seeking subtle enhancements or a complete smile transformation, ELV is dedicated to tailoring dental aesthetic solutions that align with your unique preferences and goals.",
      cont3:
        "Explore our website to discover the variety of Dental Aesthetics services available at ELV Health Care Polyclinics. From routine dental cleanings to advanced cosmetic procedures, our commitment to enhancing your dental aesthetics is evident in every aspect of our care. Trust ELV to be your partner in achieving a healthy, beautiful smile that reflects your individuality. Experience the difference at ELV, where Dental Aesthetics is not just a service but a commitment to helping you achieve the smile of your dreams.",
    },
    {
      name: "Smile Designing",
      img: "../photo/den2.jpg",
      cont1:
        "Welcome to ELV Health Care Polyclinics, where we take pride in our innovative Smile Designing services, dedicated to transforming and enhancing your smile. Our hospital understands the impact of a confident and radiant smile on your overall well-being, and our skilled team of dental professionals is committed to delivering personalized and transformative Smile Designing solutions. At ELV, we leverage the latest advancements in cosmetic dentistry to create smiles that not only look stunning but also reflect your individuality.",
      cont2:
        "ELV Health Care Polyclinics offers a comprehensive range of Smile Designing services, including cosmetic dentistry procedures such as veneers, teeth whitening, and orthodontic treatments. Whether you're looking to correct misalignments, brighten your smile, or achieve a complete smile makeover, our team is dedicated to tailoring solutions that align with your unique preferences and aesthetic goals. With a focus on precision and artistic detail, ELV ensures that each Smile Designing treatment is a personalized journey toward achieving the perfect smile.",
      cont3:
        "Explore our website to discover the transformative Smile Designing services available at ELV Health Care Polyclinics. From initial consultations to the final result, our commitment to excellence is evident in every step of the Smile Designing process. Trust ELV to be your partner in achieving the smile you've always dreamed of, where our skilled professionals combine artistry with dental expertise to craft smiles that radiate confidence and beauty. Experience the difference at ELV, where Smile Designing is not just a service but a personalized journey toward a more confident and radiant you.",
    },
    {
      name: "Braces And Clear Aligners",
      img: "../photo/den3.jpg",
      cont1:
        "Welcome to ELV Health Care Polyclinics, where we offer advanced and personalized Braces and Clear Aligners services to help you achieve the smile you've always desired. Our hospital understands the importance of orthodontic treatments in enhancing both oral health and aesthetics. At ELV, our experienced orthodontists are dedicated to providing comprehensive solutions, including traditional braces and modern Clear Aligners, to address misalignments and achieve optimal dental alignment.",
      cont2:
        "ELV Health Care Polyclinics offers a range of orthodontic services, including traditional braces and Clear Aligners, tailored to meet the unique needs and preferences of our patients. Whether you're seeking the reliability of traditional braces or the discreet and convenient option of Clear Aligners, our orthodontic team is committed to delivering personalized and effective solutions. We utilize cutting-edge technology to design treatment plans that ensure not only a straighter smile but also improved oral health and function.",
      cont3:
        "Explore our website to discover the Braces and Clear Aligners services available at ELV Health Care Polyclinics. From initial consultations to the completion of your orthodontic journey, our commitment to precision and patient satisfaction is evident. Trust ELV to be your partner in achieving a straighter and more confident smile, where our orthodontic services are designed to blend seamlessly with your lifestyle. Experience the transformative power of orthodontics at ELV, where your journey to a beautifully aligned smile begins with personalized and expert care.",
    },
    {
      name: "Dental Implants",
      img: "../photo/den4.jpg",
      cont1:
        "Welcome to ELV Health Care Polyclinics, where we pride ourselves on offering cutting-edge Dental Implants services to restore the beauty and functionality of your smile. Our hospital understands the significance of a healthy and complete set of teeth, and our skilled team of dental professionals is dedicated to providing personalized and effective Dental Implants solutions. At ELV, we leverage advanced technology and precision techniques to deliver dental implant treatments that not only replace missing teeth but also enhance your overall oral health.",
      cont2:
        "ELV Health Care Polyclinics offers a comprehensive range of Dental Implants services, including single-tooth implants, implant-supported bridges, and full-mouth restorations. Whether you're missing a single tooth or require a complete smile makeover, our experienced implantologists work closely with you to create a tailored treatment plan that suits your unique needs and goals. We understand that dental implants play a crucial role in restoring confidence and functionality, and our commitment is to provide seamless and long-lasting solutions.",
      cont3:
        "Explore our website to discover the transformative Dental Implants services available at ELV Health Care Polyclinics. From initial consultations to the final result, our focus on precision and patient satisfaction is evident in every step of the Dental Implants process. Trust ELV to be your partner in achieving a fully restored and functional smile, where our skilled professionals combine expertise with compassion to deliver dental implant solutions that enhance both aesthetics and oral health. Experience the confidence that comes with a complete smile at ELV, where Dental Implants are not just a service but a personalized journey toward dental wellness.",
    },
    {
      name: "Teeth Extraction",
      img: "../photo/den5.jpg",
      cont1:
        "Welcome to ELV Health Care Polyclinics, where our Teeth Extraction services are designed to prioritize your oral health and well-being. Our hospital understands that there are instances when tooth extraction becomes necessary, whether due to severe decay, trauma, or overcrowding. At ELV, our experienced team of dental professionals is dedicated to providing safe, efficient, and personalized teeth extraction services. We aim to make the process as comfortable as possible, ensuring a smooth and stress-free experience for our patients.",
      cont2:
        "ELV Health Care Polyclinics offers a range of teeth extraction services, including simple extractions and surgical extractions for more complex cases. Our skilled dentists and oral surgeons utilize the latest techniques and anesthesia options to minimize discomfort and expedite the healing process. Whether you require the removal of a damaged tooth or need wisdom teeth extraction, ELV is committed to delivering expert care with a focus on your comfort and overall dental health.",
      cont3:
        "Explore our website to learn more about the Teeth Extraction services available at ELV Health Care Polyclinics. From detailed pre-extraction consultations to post-extraction care, our commitment to precision and patient satisfaction is evident throughout the process. Trust ELV to be your partner in achieving optimal oral health, where our Teeth Extraction services are delivered with expertise, compassion, and a dedication to your overall well-being. Experience the difference at ELV, where tooth extraction is not just a service but a vital step toward maintaining a healthy and pain-free smile.",
    },
    {
      name: "Kids Dentistry",
      img: "../photo/den6.jpg",
      cont1:
        "Welcome to ELV Health Care Polyclinics, where we understand the unique needs of our youngest patients, and our Kids Dentistry services are designed to create positive and enjoyable dental experiences. At ELV, our team of skilled pediatric dentists is committed to providing specialized and child-friendly dental care for children of all ages. We recognize the importance of early dental interventions to establish a foundation for a lifetime of optimal oral health.",
      cont2:
        "ELV Health Care Polyclinics offers a comprehensive range of Kids Dentistry services, including routine check-ups, preventive treatments, and age-appropriate dental education. Our pediatric dentists create a welcoming and fun environment to ensure that children feel comfortable during their dental visits. From gentle cleanings to dental sealants and cavity treatments, ELV is dedicated to promoting healthy smiles and instilling positive dental habits in our young patients.",
      cont3:
        "Explore our website to learn more about the Kids Dentistry services available at ELV Health Care Polyclinics. Our commitment to creating a positive and engaging dental experience for children is evident in every aspect of our pediatric dental services. Trust ELV to be your partner in nurturing your child's oral health, where our Kids Dentistry services combine expertise with a child-centered approach, ensuring that your little ones receive the best care possible. Experience the difference at ELV, where kids' smiles are our top priority.",
    },
  ];

  const changer = useContext(data);
  return (
    <>
      <div>
        <img className="w-full mb-4" src="../photo/aa3.jpg" alt="" />
        <h1 style={{ color: "#3d7cc9" }} className="text-lg font-medium mb-4">
          Best Dental care Services in Whitefield, Bangalore.
        </h1>
        <p className="mb-4">
          Welcome to ELV Health Care Polyclinics, where your oral health is our
          priority. Our hospital is dedicated to providing comprehensive and
          expert Dental Care services to ensure the well-being of your teeth and
          gums. At ELV, our team of skilled dentists offers a wide range of
          dental treatments, including preventive care, routine check-ups, and
          specialized procedures, all designed to cater to your unique dental
          needs.
        </p>
        <p className="mb-4">
          ELV Health Care Polyclinics is committed to promoting good oral health
          through accessible and patient-centered Dental Care services. Our
          services encompass routine dental cleanings, restorative procedures,
          and emergency dental care to address a variety of dental concerns.
          Whether you're looking for a routine check-up or require more
          extensive dental work, ELV is here to deliver personalized care,
          emphasizing both the health and aesthetics of your smile.
        </p>
      </div>
      <div className="w-full grid grid-cols-1 md:grid-cols-1 gap-3">
        {blo.map((elem: any, num: number) => {
          return (
            <>
              <div>
                <div className="w-full h-full grid grid-cols-1 md:grid-cols-3 rounded-xl md:gap-0 p-2">
                  <div style={{ aspectRatio: 3 / 2 }} className="py-0 w-full">
                    <div
                      style={{ backgroundImage: `url(${elem.img})` }}
                      className="w-full h-full mainbanner rounded-md"
                    ></div>
                  </div>
                  <div className=" col-span-2 mt-4 md:mt-0 py-0 md:px-3">
                    <h5 className="text-lg font-medium mb-1 ">{elem.name}</h5>
                    <p className="mt-0 line-clamp-4 mb-2 ">{elem.cont1}</p>
                    <button
                      onClick={() => {
                        changer.setTopser(elem);
                      }}
                      style={{ color: "#3d7cc9" }}
                      className="font-medium text-sm"
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}

export default Dentalcon;
