import React, { useEffect } from "react";
import Servicebanner from "./Services/Servicebanner";
import NumberInfo from "./Home/NumberInfo";
import Doctorbox from "./Home/Docterbox";
import Serviceinfo from "./Services/Serviceinfo";
import ServiceReview from "./Services/ServiceReview";
import Divider from "./Divider";
import Wecare from "./Home/Wecare";
import Divser from "./About/Divser";
import Callsection from "./Home/Callsection";
import Contactbynum from "./Services/Contactbynum";
import Infiry from "./About/Infiry";
import Qns from "./Services/Qns";
import Callin from "./About/Callin";
import Aboutbanner from "./About/Aboutbanner";
import Infobox from "./About/Infobox";
import Aboutcon from "./About/Aboutcon";
import ContactwithWat from "./Services/ContactwithWat";
import Contactform from "./About/Contactform";
import Aboutinfo from "./About/Aboutinfo";
import ServiceList from "./Services/ServiceList";
import Firstmenu from "./Mobile/Firstmenu";
import { movetop } from "../fun";

function About() {
  const height: number = NaN;
  type elem = {
    oneBody?: JSX.Element;
    twoBody?: JSX.Element;
    threeBody?: JSX.Element;
  };
  const elemnt: elem = {
    oneBody: <Aboutcon />,
    twoBody: <Infiry />,
    threeBody: <Callin />,
  };
  const elemnt2: elem = {
    oneBody: <Qns />,
    twoBody: <Contactform />,
  };

  useEffect(() => {
    movetop();
  }, []);
  return (
    <>
      <Aboutbanner name="About Us" banner={"../photo/sam2.jpg"} />
      {/* <div style={{ backgroundColor: "#eff6ff" }} className="pb-2">
        <Infobox />
      </div> */}
      <Serviceinfo />
      <ServiceList />
      {/* <Aboutinfo /> */}
      {/* <Doctorbox /> */}
      {/* <div className="py-4">
        <Contactbynum color={"#fafafa"} />
      </div> */}
      {/* <ServiceList /> */}
      {/* <Serviceinfo /> */}
      <div className="pt-0 pb-6"></div>
      <Divser elemnt={elemnt} />
      <div className="pt-10 pb-4">
        <Divider />
      </div>
      <Divser elemnt={elemnt2} />
      <div className="pt-10 pb-3">
        <Divider />
      </div>
      {/* <div className="pt-10">
        <ContactwithWat />
      </div> */}
      {/* <ServiceReview /> */}
      <Wecare />
    </>
  );
}

export default About;
