import React from "react";

function Xraycon() {
  return (
    <>
      <div>
        <img className="w-full mb-4" src="../photo/aa22.jpg" alt="" />
        <h1 style={{ color: "#3d7cc9" }} className="text-lg font-medium mb-4">
          Best X-ray Services in Whitefield, Bangalore.
        </h1>
        <p className="mb-4">
          X-ray scans are a fundamental diagnostic imaging tool that uses
          electromagnetic radiation to produce detailed images of the internal
          structures of the body. At ELV Health Care Polyclinics, our Radiology
          Department, which includes X-ray services, is dedicated to providing
          high-quality and efficient diagnostic imaging to aid in the diagnosis
          and monitoring of various medical conditions.
        </p>
        <p className="mb-4">
          {" "}
          X-rays are invaluable for visualizing bones, organs, and tissues,
          making them an essential tool for diagnosing fractures, detecting
          abnormalities in the chest or abdomen, and assisting in the management
          of various medical conditions. Our team of skilled radiographers and
          imaging specialists utilize X-ray technology to capture detailed
          images that play a crucial role in guiding healthcare providers in
          their diagnostic and treatment decisions.
        </p>
        <p className="mb-4">
          {" "}
          Our Radiology Department provides a range of X-ray examinations,
          including skeletal X-rays to assess bones and joints, chest X-rays for
          evaluating the lungs and heart, and abdominal X-rays for visualizing
          abdominal structures. These examinations are performed with a focus on
          precision and safety, ensuring that patients receive accurate and
          reliable diagnostic information.
        </p>
        <p className="mb-4">
          {" "}
          Our X-ray facilities are equipped with state-of-the-art digital X-ray
          machines that produce high-quality images with reduced radiation
          exposure. Our team of radiographers and imaging specialists are
          trained to perform X-ray examinations with efficiency and care. This
          commitment to technology and expertise ensures that patients receive
          timely and accurate diagnostic imaging services.
        </p>
        <p className="mb-4">
          {" "}
          ELV Health Care Polyclinics understand the importance of diagnostic
          imaging in patient care. Our X-ray services are delivered with a
          commitment to open communication, ensuring that patients are informed
          about the procedure, potential risks, and post-examination
          recommendations. By combining advanced technology with compassionate
          care, ELV Health Care Polyclinics aim to provide a positive and
          patient-friendly experience for individuals undergoing X-ray
          examinations, contributing to the overall well-being of our patients.
        </p>
      </div>
    </>
  );
}

export default Xraycon;
