import { data } from "../../App";
import React, { useContext, useEffect, useRef } from "react";
import { MdCancel } from "react-icons/md";

function Topservices() {
  const changer = useContext(data);
  const elem = changer.topser;

  return (
    <div
      style={{
        display: changer.topser == undefined ? "none" : "flex",
        backgroundColor: "rgba(0,0,0,.5)",
      }}
      className={`justify-center h-full w-full z-50 fixed items-start lg:items-center overflow-x-auto`}
    >
      <div className="containerbox relative">
        <div className="py-10 lg:py-0">
          <div className="h-14 absolute right-2 hidden lg:flex items-center">
            <MdCancel
              onClick={() => {
                changer.setTopser(undefined);
              }}
              style={{ zIndex: "900" }}
              className="text-3xl text-red-700 bg-white rounded-full p-0"
            />
          </div>

          <div className="w-full flex justify-start">
            <a
              style={{ backgroundColor: "#00263e" }}
              className="text-white w-52 text-center h-14 flex items-center text-lg font-bold justify-center rounded-tl-2xl rounded-tr-2xl"
              href=""
            >
              ELV Health Care
            </a>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 p-5 bg-white rounded-b-xl rounded-tr-xl gap-4">
            <div>
              <img
                className="w-full"
                src={elem == undefined ? "" : elem.img}
                alt=""
              />
            </div>
            <div className="h-full">
              <div className="overflow-y-scroll h-full lg:h-96">
                <h5 className="text-xl font-medium mb-2">
                  {elem == undefined ? "" : elem.name}
                </h5>
                <p className="mb-2">{elem == undefined ? "" : elem.cont1}</p>
                <p className="mb-2">{elem == undefined ? "" : elem.cont2}</p>
                <p className="mb-2 lg:mb-0">
                  {elem == undefined ? "" : elem.cont3}
                </p>
                <div className="flex lg:hidden justify-center">
                  <button
                    onClick={() => {
                      changer.setTopser(undefined);
                    }}
                    className="border-inherit border py-1 px-3 rounded-md text-white bg-red-800 "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Topservices;
