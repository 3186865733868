import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { MdCancel, MdOutlineRestorePage } from "react-icons/md";
import { IoMdCall } from "react-icons/io";
import { FaGoogle, FaLocationDot, FaYoutube } from "react-icons/fa6";
import { LuMapPin } from "react-icons/lu";
import { AiFillInstagram } from "react-icons/ai";
import { data } from "../../App";

function Firstmenu() {
  const changer = useContext(data);
  return (
    <div
      style={{ transition: "0.4s" }}
      className={`fixed top-0 z-40 ${
        changer.menufirst ? "w-full" : "w-0"
      } h-full scroll-container overflow-y-scroll scrollbar-hidden`}
    >
      <div
        style={{ backgroundColor: "#fafafa" }}
        className="w-56 h-full border-r-2"
      >
        <div className="flex justify-center pt-5">
          <img className="w-44" src="../photo/logo.png" alt="" />
        </div>
        <div style={{ backgroundColor: "#fafafa" }} className="w-full">
          <div className="pt-5 px-3 w-full">
            <Link
              to="/"
              onClick={() => {
                changer.setMenufirst(false);
              }}
              className="px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 relative flex "
            >
              <MdOutlineRestorePage className="text-xl mr-1" />
              Home
            </Link>
            <Link
              to={"/about"}
              onClick={() => {
                changer.setMenufirst(false);
              }}
              className=" flex  px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 relative"
            >
              <MdOutlineRestorePage className="text-xl mr-1" />
              About Us
            </Link>
            <Link
              onClick={() => {
                changer.setMenufirst(false);
              }}
              to={"/bestdoctor"}
              className=" flex  px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 relative"
            >
              <MdOutlineRestorePage className="text-xl mr-1" />
              Our Doctor
            </Link>
            <button
              onClick={() => {
                changer.setMenuThree(true);
              }}
              className="flex w-full px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 relative"
            >
              <MdOutlineRestorePage className="text-xl mr-1" />
              Medical Services
            </button>
            <button
              onClick={() => {
                changer.setMenusecond(true);
              }}
              className="w-full flex  px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 relative"
            >
              <MdOutlineRestorePage className="text-xl mr-1" />
              Departments
            </button>
            <Link
              onClick={() => {
                changer.setMenufirst(false);
              }}
              to={"/bestspecilitiesinwhitefield"}
              className=" flex  px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 relative"
            >
              <MdOutlineRestorePage className="text-xl mr-1" />
              Specialized Clinics
            </Link>
            <button
              onClick={() => {
                changer.setMenufour(true);
              }}
              className="w-full flex text-left justify-start px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 relative"
            >
              <MdOutlineRestorePage className="text-xl mr-1" />
              Diagnostic Services
            </button>
            <Link
              onClick={() => {
                changer.setMenufirst(false);
              }}
              to={"/bestemergencyservicesinwhitefield"}
              className=" flex  px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 relative"
            >
              <MdOutlineRestorePage className="text-xl mr-1" />
              Emergency Services
            </Link>
            <Link
              onClick={() => {
                changer.setMenufirst(false);
              }}
              to="/blog"
              className=" flex  px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 relative"
            >
              <MdOutlineRestorePage className="text-xl mr-1" />
              Blog
            </Link>
            <Link
              onClick={() => {
                changer.setMenufirst(false);
              }}
              to="/contact"
              className=" flex  px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 relative"
            >
              <MdOutlineRestorePage className="text-xl mr-1" />
              Contact Us
            </Link>
          </div>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="py-3 px-3 w-full"
          >
            <h5 style={{ color: "#3d7cc9" }} className="text-lg font-medium">
              Contact Info
            </h5>
            <p>
              ELV Health Care Polyclinic, MR ASTER, Ground Floor Vijayanagar,
              Whitefield, Bengaluru, Karnataka 560066
            </p>
            <a
              href="tel:095352 64855"
              className=" flex pt-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 relative"
            >
              <IoMdCall className="text-xl mr-1" />
              095352 64855
            </a>
            <a
              href="tel:9535264855"
              className=" flex pt-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 relative"
            >
              <IoMdCall className="text-xl mr-1" />
              9535264855
            </a>
          </div>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="w-full flex justify-center pb-5"
          >
            <div className="flex">
              <FaYoutube className="text-2xl p-1" />
              <FaGoogle className="text-2xl p-1" />
              <FaLocationDot className="text-2xl p-1" />
              <AiFillInstagram className="text-2xl p-1" />
            </div>
          </div>
        </div>
      </div>
      <MdCancel
        onClick={() => {
          changer.setMenufirst(false);
        }}
        style={{ color: "#3d7cc9" }}
        className="text-3xl absolute z-40 top-2 right-2"
      />
    </div>
  );
}

export default Firstmenu;
