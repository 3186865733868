import { AiOutlineClockCircle } from "react-icons/ai";
import { IoLocationOutline } from "react-icons/io5";
import { LiaHospitalSolid } from "react-icons/lia";
import { MdOutlineWifiCalling3 } from "react-icons/md";
export default function Servicebanner({
  name,
  banner,
}: {
  name: string;
  banner: string;
}) {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${banner})`,
        }}
        className="w-full block pt-5 md:pt-8 pb-0 md:py-10 home-banner-in"
      >
        <div className=" flex justify-center">
          <div className="containerbox">
            <div className="flex flex-wrap">
              <div className="w-full md:w-2/5 lg:w-1/2 relative h-72 md:h-80">
                <div>
                  <img
                    className="w-20 md:w-40 mb-1"
                    src="../photo/logobanner.png"
                    alt=""
                  />
                </div>
                <div className="relative md:absolute bottom-4 md:bottom-0 mt-24 md:mt-0">
                  <h5
                    style={{ color: "#00808e" }}
                    className="text-4xl mb-1 font-bold"
                  >
                    {name}
                  </h5>
                  <h6 className="text-lg">Join the ELV Health Care Program!</h6>
                  <div className="flex">
                    <a
                      href="tel:9535264855"
                      style={{ backgroundColor: "#48b95f" }}
                      className="border-inherit border p-2 rounded-md mt-4 text-white flex items-center mr-2 justify-between"
                    >
                      <MdOutlineWifiCalling3 className="mr-1 text-xl" />
                      Book Appointment
                    </a>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-3/5 lg:w-1/2 hidden md:block"></div>
            </div>
          </div>
        </div>
        {/* <div style={{ padding: "0px 10px" }} className="block md:hidden">
          <div className="w-full flex justify-start">
            <a
              style={{ backgroundColor: "#00263e" }}
              className="text-white w-52 text-center h-14 flex items-center text-lg font-bold justify-center rounded-tl-2xl rounded-tr-2xl"
              href=""
            >
              Hospital Details
            </a>
          </div>
        </div> */}
      </div>
      {/* <div style={{ padding: "0px 10px" }} className="block md:hidden">
        <div
          style={{
            boxShadow:
              "rgba(0, 0, 0, 0.02) 0px 1px 2px, rgba(0, 0, 0, 0.02) 0px 2px 4px, rgba(0, 0, 0, 0.02) 0px 4px 8px, rgba(0, 0, 0, 0.02) 0px 8px 16px, rgba(0, 0, 0, 0.02) 0px 16px 32px, rgba(0, 0, 0, 0.02) 0px 16px 30px",
          }}
          className="w-full bg-white py-8 px-8 rounded-tr-xl rounded-bl-xl rounded-br-xl mb-8"
        >
          <h6 className="font-medium mb-5">
            Share your details and we will reach out to you with the next steps
          </h6>
          <div className="w-full sm:mb-3 flex flex-wrap">
            <div className="sm:pr-1.5 w-full sm:w-1/2">
              <div
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.001) 0px 0px 0px 0.1px",
                }}
                className="bg-white py-2.5 px-2 sm:px-4 lg:px-1 xl:px-4 border rounded-md flex items-center"
              >
                <div>
                  <AiOutlineClockCircle className="text-3xl font-light text-gray-600" />
                </div>
                <div className="pl-1 sm:pl-3 lg:pl-2 xl:pl-3">
                  <h6 className="text-sm font-medium text-gray-600">
                    24/7 service
                  </h6>
                </div>
              </div>
            </div>
            <div className="sm:pl-1.5 w-full sm:w-1/2">
              <div
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.001) 0px 0px 0px 0.1px",
                }}
                className="bg-white py-2.5 px-2 sm:px-4 lg:px-1 xl:px-4 border rounded-md flex items-center"
              >
                <div>
                  <LiaHospitalSolid className="text-3xl font-light text-gray-600" />
                </div>
                <div className="pl-1 sm:pl-3 lg:pl-2 xl:pl-3">
                  <h6 className="text-sm font-medium text-gray-600">
                    NABL certified lab reports will receive with in 6 hrs
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-wrap">
            <div className="w-full">
              <div
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.001) 0px 0px 0px 0.1px",
                }}
                className="bg-white py-2.5 px-2 sm:px-4 lg:px-1 xl:px-4 border rounded-md flex items-center"
              >
                <div>
                  <IoLocationOutline className="text-3xl font-light text-gray-600" />
                </div>
                <div className="pl-1 sm:pl-3 lg:pl-2 xl:pl-3">
                  <h6 className="text-sm font-medium text-gray-600">
                    ELV Health Care Polyclinic, MR ASTER, Ground Floor
                    Vijayanagar, Whitefield, Bengaluru, Karnataka 560066
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-center mt-5">
            <a
              href="tel:9535264855"
              style={{ backgroundColor: "#48b95f" }}
              className="w-1/2 h-12 text-center text-lg font-bold text-white rounded-md"
            >
              Submit
            </a>
          </div>
        </div>
      </div> */}
    </>
  );
}
