import React from "react";

function Pregnancyscanscon() {
  return (
    <>
      <div>
        <img className="w-full mb-4" src="../photo/aa21.jpg" alt="" />
        <h6 style={{ color: "#3d7cc9" }} className="text-lg font-medium mb-4">
          Pregnancy scan
        </h6>
        <p className="mb-4">
          Pregnancy scans, also known as prenatal ultrasound examinations, play
          a pivotal role in monitoring the development and well-being of both
          the fetus and the expectant mother during pregnancy. At ELV Health
          Care Polyclinics, our Pregnancy Scan Department is committed to
          providing comprehensive and advanced prenatal imaging services to
          ensure a healthy and safe pregnancy journey.
        </p>
        <p className="mb-4">
          {" "}
          Our team of skilled sonographers and imaging specialists utilizes
          ultrasound technology to create detailed images of the developing
          fetus and the surrounding structures. These scans are instrumental in
          assessing fetal growth, detecting any abnormalities, and providing
          valuable information to both healthcare providers and expectant
          parents, contributing to the overall well-being of the pregnancy.
        </p>
        <p className="mb-4">
          {" "}
          Our Pregnancy Scan Department provides a series of ultrasound
          examinations throughout different stages of pregnancy. These include
          early pregnancy scans to confirm the viability of the pregnancy,
          anatomy scans to assess fetal development and detect any congenital
          anomalies, and growth scans to monitor the baby's size and well-being.
          These scans are performed with a focus on providing valuable insights
          into the health of both the mother and the developing fetus.
        </p>
        <p className="mb-4">
          {" "}
          Our pregnancy scanning facilities are equipped with state-of-the-art
          ultrasound machines that produce high-resolution images, ensuring
          detailed and accurate assessments. Our team of sonographers and
          imaging specialists are trained to perform a variety of pregnancy
          scans with precision and care. This commitment to technology and
          expertise ensures that expectant parents receive reliable and
          meaningful information about their pregnancy.
        </p>
        <p className="mb-4">
          {" "}
          ELV Health Care Polyclinics recognize the emotional significance of
          pregnancy scans for expectant parents. Our pregnancy scanning services
          are delivered with a commitment to open communication, ensuring that
          expectant parents are provided with detailed explanations and insights
          during each scan. By combining advanced technology with compassionate
          care, ELV Health Care Polyclinics aim to provide a positive and
          reassuring experience for expectant parents, contributing to a healthy
          and well-monitored pregnancy journey.
        </p>
      </div>
    </>
  );
}

export default Pregnancyscanscon;
