import { IoLogoWhatsapp } from "react-icons/io";
import { IoCall } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { SiFacebook } from "react-icons/si";
import { FaYoutube } from "react-icons/fa6";
import { AiFillInstagram } from "react-icons/ai";
import { AiFillGoogleCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <footer
        style={{ backgroundColor: "#0f172a" }}
        className="flex justify-center flex-wrap"
      >
        <div className="containerbox w-full">
          <div className="w-full py-4 flex flex-wrap justify-center md:justify-between">
            <div className="w-full md:w-auto flex justify-center mb-4 md:mb-0">
              <Link to={"/"}>
                <img width={270} src="../photo/logo.png" alt="" />
              </Link>
            </div>
            <div className=" w-full md:w-auto flex flex-wrap justify-center">
              <a
                href="https://api.whatsapp.com/send?phone=9535264855&text=Hello"
                className=" w-full sm:w-auto flex justify-center"
              >
                <div className="bg-white flex p-1 items-center border rounded sm:mr-2 mb-3 sm:mb-0">
                  <IoLogoWhatsapp
                    className="text-5xl mx-2"
                    style={{ color: "#4ec72e" }}
                  />
                  <div className="mr-2">
                    <h6>Whatsapp</h6>
                    <h5 className="text-1xl font-bold">+91 9535264855</h5>
                  </div>
                </div>
              </a>
              <a
                href="tel:9535264855"
                className=" w-full sm:w-auto flex justify-center"
              >
                <div className="bg-white flex p-1 items-center border rounded">
                  <IoCall
                    className="text-5xl mx-2"
                    style={{ color: "#4ec72e" }}
                  />
                  <div className="mr-2">
                    <h6>Phone Call</h6>
                    <h5 className="text-1xl font-bold">+91-953526 4855</h5>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div
          style={{ backgroundColor: "#00263e" }}
          className="w-full flex justify-center"
        >
          <div className="containerbox">
            <div className="grid grid-cols-1 gap-y-5 lg:gap-y-0 sm:grid-cols-2 lg:grid-cols-4 py-12">
              <div>
                <button className="w-full flex justify-start">
                  <h6 className="text-2xl font-bold text-white">Direct Link</h6>
                </button>
                <button className="w-full flex justify-start mt-2">
                  <Link to={"/"} className="text-1xl text-white">
                    Home
                  </Link>
                </button>
                <button className="w-full flex justify-start mt-2">
                  <Link to={"/about"} className="text-1xl text-white">
                    About Us
                  </Link>
                </button>
                <button className="w-full flex justify-start mt-2">
                  <Link to={"/bestdoctor"} className="text-1xl text-white">
                    Doctor
                  </Link>
                </button>
                <button className="w-full flex justify-start mt-2">
                  <Link
                    to={"/bestspecilitiesinwhitefield"}
                    className="text-1xl text-white"
                  >
                    Services
                  </Link>
                </button>
                <button className="w-full flex justify-start mt-2">
                  <Link to={"/blog"} className="text-1xl text-white">
                    Blog
                  </Link>
                </button>
                <button className="w-full flex justify-start mt-2">
                  <a href="" className="text-1xl text-white">
                    Testimonials
                  </a>
                </button>
                <button className="w-full flex justify-start mt-2">
                  <Link to={"/contact"} className="text-1xl text-white">
                    Contact Us
                  </Link>
                </button>
              </div>
              <div>
                <button className="w-full flex justify-start">
                  <h6 className="text-2xl font-bold text-white">Departments</h6>
                </button>
                <button className="w-full flex justify-start mt-2">
                  <Link
                    to={"/bestinternalmedicineinwhitefield"}
                    className="text-1xl text-white"
                  >
                    Internal Medicine
                  </Link>
                </button>
                <button className="w-full flex justify-start mt-2">
                  <Link
                    to={"/bestdiabetesinwhitefield"}
                    className="text-1xl text-white"
                  >
                    Diabetes
                  </Link>
                </button>
                <button className="w-full flex justify-start mt-2">
                  <Link
                    to={"/bestdentalinwhitefield"}
                    className="text-1xl text-white"
                  >
                    Dental care
                  </Link>
                </button>
                <button className="w-full flex justify-start mt-2">
                  <Link
                    to={"/bestorthopedicservicesinwhitefield"}
                    className="text-1xl text-white"
                  >
                    Orthopedic
                  </Link>
                </button>
                <button className="w-full flex justify-start mt-2">
                  <Link
                    to={"/bestgeneralsurgeryinwhitefield"}
                    className="text-1xl text-white"
                  >
                    General surgery
                  </Link>
                </button>
                <button className="w-full flex justify-start mt-2">
                  <Link
                    to={"/bestpediatricsservicesinwhitefield"}
                    className="text-1xl text-white"
                  >
                    Pediatrics
                  </Link>
                </button>
                <button className="w-full flex justify-start mt-2">
                  <Link
                    to="/bestentinwhitefield"
                    className="text-1xl text-white"
                  >
                    ENT
                  </Link>
                </button>
              </div>
              <div>
                <button className="w-full flex justify-start">
                  <h6 className="text-2xl font-bold text-white">
                    Diagnostic Services
                  </h6>
                </button>
                <button className="w-full flex justify-start mt-2">
                  <Link
                    to={"/bestultrascoundservicesinwhitefield"}
                    className="text-1xl text-white"
                  >
                    Ultrascound
                  </Link>
                </button>
                <button className="w-full flex justify-start mt-2">
                  <Link
                    to={"/bestechoservicesinwhitefield"}
                    className="text-1xl text-white"
                  >
                    ECHO
                  </Link>
                </button>
                <button className="w-full flex justify-start mt-2">
                  <Link
                    to={"/bestpregnancyscansinwhitefield"}
                    className="text-1xl text-white"
                  >
                    Pregnancy scans
                  </Link>
                </button>
                <button className="w-full flex justify-start mt-2">
                  <Link
                    to={"/bestxrayinwhitefield"}
                    className="text-1xl text-white"
                  >
                    X ray
                  </Link>
                </button>
                <button className="w-full flex justify-start mt-2">
                  <Link
                    to={"/bestbloodtestinwhitefield"}
                    className="text-1xl text-white"
                  >
                    Blood test
                  </Link>
                </button>
                <button className="w-full flex justify-start mt-2">
                  <Link
                    to={"/besthomebloodservicesinwhitefield"}
                    className="text-1xl text-white"
                  >
                    Home blood sample collection
                  </Link>
                </button>
              </div>
              <div>
                <button className="w-full flex justify-start">
                  <h6 className="text-2xl font-bold text-white">Contact Us</h6>
                </button>
                <button className="w-full flex justify-start mt-2">
                  <h6 className="text-1xl text-white text-left">
                    ELV Health Care Polyclinic, MR ASTER, Ground Floor
                    Vijayanagar, Whitefield, Bengaluru, Karnataka 560066
                  </h6>
                </button>
                <button className="w-full justify-start mt-2 flex items-center">
                  <IoCall className="text-white mr-2 text-1xl" />
                  <h6 className="text-1xl text-white">95352 64855</h6>
                </button>
                <button className="w-full justify-start mt-2 flex items-center">
                  <IoCall className="text-white mr-2 text-1xl" />
                  <h6 className="text-1xl text-white">95352 64854</h6>
                </button>
                <div className="flex mt-4 justify-center">
                  <a href="https://www.facebook.com/elvhealthcarepolyclinics/">
                    <SiFacebook className="text-white m-2 text-2xl" />
                  </a>
                  <a href="https://www.youtube.com/channel/UCRRSkndfw4871WiJmv4liLQ">
                    <FaYoutube className="text-white m-2 text-2xl" />
                  </a>
                  <a href="https://www.instagram.com/elvhealthcare/">
                    <AiFillInstagram className="text-white m-2 text-2xl" />
                  </a>
                  <a href="https://www.google.co.in/maps?sca_esv=590870356&sxsrf=AM9HkKnBVoQs1oCUaydc0i-gH-hCLXTu_A:1702554843068&iflsig=AO6bgOgAAAAAZXr660FKwQdnoUlC5zzT1p0xPduE41aC&gs_lp=Egdnd3Mtd2l6IgNlbHYqAggAMgoQIxiABBiKBRgnMgQQIxgnMgoQIxiABBiKBRgnMggQLhixAxiABDILEC4YgwEYsQMYgAQyCBAAGIAEGLEDMggQABiABBixAzILEAAYgAQYsQMYgwEyCBAAGIAEGLEDMgsQABiABBixAxiDAUifGlAAWMMJcAB4AJABAJgB2AGgAfwDqgEFMC4yLjG4AQHIAQD4AQHCAhEQLhiABBixAxiDARjHARjRA8ICDhAAGIAEGIoFGLEDGIMBwgIFEC4YgATCAggQLhiABBixAw&um=1&ie=UTF-8&fb=1&gl=in&sa=X&geocode=KXmXvmCwEa47MSGhqJ90Q98Y&daddr=MR+ASTER,+Ground+Floor+Vijayanagar,+Whitefield,+Bengaluru,+Karnataka+560066">
                    <AiFillGoogleCircle className="text-white m-2 text-2xl" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="containerbox my-6 flex flex-wrap justify-between">
          <a
            className="text-white w-full sm:w-auto flex text-center mb-2 justify-center sm:justify-start sm:mb-0 "
            href=""
          >
            Adhventha Hospital, Design By appaddindia.com
          </a>
          <div className=" w-full sm:w-auto flex justify-center sm:justify-end">
            <a className="bg-white px-2 rounded flex items-center " href="">
              <IoCall className="mr-2" />
              Call Us
            </a>
          </div>
        </div>
      </footer>
    </>
  );
}
