import React from "react";
import { Link } from "react-router-dom";

function EmergencyServicescon() {
  const menu = [
    {
      name: "Immediate Response",
      dis1: "Emergency services at ELV Health Care Polyclinics are designed to provide an immediate response to critical medical situations.",
      dis2: "Trained emergency medical personnel are available to assess and address urgent healthcare needs.",
      link: "",
      img: "../photo/ee1.jpg",
    },
    {
      name: "Urgent Medical Care",
      dis1: "The emergency services are equipped to handle a range of urgent medical conditions, including trauma, severe injuries, chest pain, respiratory distress, and other life-threatening situations.",
      dis2: "",
      link: "",
      img: "../photo/ee2.jpg",
    },
    {
      name: "Advanced Medical Facilities",
      dis1: "ELV's emergency services likely include access to advanced medical facilities, diagnostic equipment, and treatment options to ensure comprehensive and rapid care.",
      dis2: "",
      link: "",
      img: "../photo/ee3.jpg",
    },
    {
      name: "Coordination with Specialized Departments",
      dis1: "In the case of emergencies requiring specialized care, ELV Health Care Polyclinics may have protocols for coordinating with specialized departments within the facility.",
      dis2: "",
      link: "",
      img: "../photo/ee4.jpg",
    },
  ];
  return (
    <>
      <div className="flex justify-center">
        <div className="containerbox">
          <div className="w-full grid grid-cols-1 gap-3">
            {menu.map((elem) => {
              return (
                <>
                  <div>
                    <div
                      style={{
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                      }}
                      className="w-full grid grid-cols-1 md:grid-cols-4 rounded-xl md:gap-4 p-4"
                    >
                      <div style={{ aspectRatio: 8 / 7 }} className="py-0">
                        <div
                          style={{ backgroundImage: `url(${elem.img})` }}
                          className="w-full h-full mainbanner rounded-md"
                        ></div>
                      </div>
                      <div className="col-span-3 mt-4 md:mt-0">
                        <h5
                          style={{ color: "#3d7cc9" }}
                          className="text-lg font-medium mb-2"
                        >
                          {elem.name}
                        </h5>
                        <p className="">{elem.dis1}</p>
                        <p className="">{elem.dis2}</p>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default EmergencyServicescon;
