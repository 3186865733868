export default function Serviceinfo() {
  return (
    <>
      <section
        style={{ backgroundColor: "#fdfffe" }}
        className="flex justify-center pt-12"
      >
        <div className="containerbox">
          {/* <h1
            style={{
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
            }}
            className="text-3xl font-bold leading-snug"
          >
            ELV Health Care Polyclinics
          </h1> */}
          <h1 className="text-4xl font-bold leading-snug mb-0">
            ELV Health Care Polyclinics
          </h1>
          <p className="pt-4">
            ELV Health Care Poly Clinics stand as pillars of comprehensive and
            accessible healthcare services, fostering a haven of well-being in
            the heart of Bengaluru. With a spectrum of medical offerings, these
            clinics cater to a diverse range of needs, ensuring that every
            individual receives the attention and care they deserve. From a
            state-of-the-art day care center and minor OT facilities to a fully
            stocked pharmacy and primary care services, ELV Health Care strives
            to be a one-stop solution for various medical requirements.
          </p>
          <p className="pt-4">
            Distinguished by specialized departments such as Internal Medicine,
            Orthopedics, Cardiology, and more, ELV Health Care Poly Clinics
            house a team of dedicated professionals committed to delivering
            top-notch healthcare. Cutting-edge diagnostic services, including
            ultrasonography, ECHO, X-rays, and blood tests, further bolster
            their commitment to accurate and timely medical assessments.
            Noteworthy is their emphasis on patient convenience, exemplified by
            home blood sample collection services, ensuring that healthcare is
            not just a necessity but a seamlessly integrated part of ones life.
            Situated at MR ASTER, Ground Floor Vijayanagar, Whitefield,
            Bengaluru, Karnataka 560066, ELV Health Care Polyclinic stands as a
            beacon of health and healing in the community, embodying the ethos
            of compassionate and comprehensive healthcare.
          </p>
          {/* <div className="mt-10">
            <div className="grid grid-cols-1 sm:grid-cols-3 h-96 md:h-72 lg:h-96 gap-0.5 sm:gap-2">
              <div
                style={{ backgroundImage: "url(../photo/hos1.jpg" }}
                className="border bg-cover bg-left"
              ></div>
              <div className="hidden sm:grid w-full grid-rows-2 gap-0.5 sm:gap-2">
                <div
                  style={{ backgroundImage: "url(../photo/hos2.jpg" }}
                  className=" bg-cover bg-center border h-full"
                ></div>
                <div
                  style={{ backgroundImage: "url(../photo/hos3.jpg" }}
                  className=" bg-cover bg-center border h-full"
                ></div>
              </div>
              <div
                style={{ backgroundImage: "url(../photo/hos4.jpg" }}
                className="border bg-cover bg-right"
              ></div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
}
