import React from "react";

function Daycarecon() {
  return (
    <>
      <div>
        <img className="w-full mb-4" src="../photo/aa15.jpg" alt="" />
        <h1 style={{ color: "#3d7cc9" }} className="text-lg font-medium mb-4">
          The Best Daycare Center Services in Whitefield, Bangalore.
        </h1>
        <p className="mb-4">
          Welcome to ELV Health Care Polyclinics, where we redefine healthcare
          with a unique integration of day care services within our hospital
          setting. Our commitment is to provide a comprehensive and
          compassionate approach to wellness, ensuring that our patients not
          only receive expert medical care but also experience a supportive
          environment that fosters healing and comfort.
        </p>
        <p className="mb-4">
          {" "}
          Within the walls of ELV Health Care Polyclinics, our daycare center
          stands as a testament to our dedication to holistic healthcare. From
          pediatric to elder care, our daycare services are tailored to meet the
          diverse needs of our community. Our team of experienced professionals
          is devoted to delivering personalized attention, ensuring that each
          individual receives the care they deserve. We understand that health
          goes beyond the physical, and our daycare center incorporates
          recreational and educational activities to enhance the overall
          well-being of our patients.
        </p>
        <p className="mb-4">
          {" "}
          In our daycare center, children find a space where their unique needs
          are met with warmth and expertise. Elderly individuals discover a
          haven of dignity and companionship, where their golden years are
          celebrated with respect and care. ELV Health Care Polyclinics is more
          than a hospital; it's a community hub for wellness, where families can
          entrust their loved ones to receive the best in medical care while
          benefiting from a nurturing and uplifting atmosphere.
        </p>
        <p className="mb-4">
          Our daycare services are seamlessly integrated with our medical
          facilities, ensuring a smooth transition between care and recovery.
          ELV Health Care Polyclinics aims to not only treat illnesses but to
          empower individuals on their journey to well-being. As you explore our
          website, we invite you to discover the comprehensive range of services
          offered at the intersection of medical excellence and compassionate
          day care, where ELV Health Care Polyclinics stands as a beacon of
          health and healing for our community.
        </p>
      </div>
    </>
  );
}

export default Daycarecon;
