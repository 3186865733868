import React from "react";

function Dicontent() {
  return (
    <>
      <div>
        <img className="w-full mb-4" src="../photo/aa2.jpg" alt="" />
        <h1 style={{ color: "#3d7cc9" }} className="text-lg font-medium mb-4">
          Best Diabetes Services in Whitefield, Bangalore.
        </h1>
        <p className="mb-4">
          Welcome to ELV Health Care Polyclinics, your dedicated partner in
          managing diabetes and promoting overall well-being. At ELV, we
          understand the unique challenges posed by diabetes, and our
          specialized Diabetes Care services are designed to provide
          comprehensive and compassionate care for individuals living with this
          condition. Our hospital is equipped with a team of experienced
          healthcare professionals who are committed to delivering personalized
          and evidence-based diabetes management.
        </p>
        <p className="mb-4">
          ELV Health Care Polyclinics offers a range of Diabetes Care services,
          including diagnostic screenings, personalized treatment plans, and
          ongoing support for individuals with diabetes. Our multidisciplinary
          team collaborates to address the various aspects of diabetes, from
          medication management to lifestyle modifications, ensuring a holistic
          approach to diabetes care. We strive to empower our patients with the
          knowledge and tools they need to effectively manage their condition
          and lead a healthy life.
        </p>
        <p className="mb-4">
          {" "}
          Accessibility and patient education are key components of our Diabetes
          Care services at ELV. We provide convenient appointment scheduling,
          telemedicine options, and educational resources to support our
          patients on their diabetes journey. Whether you are newly diagnosed or
          seeking specialized care for long-term management, ELV is here to
          offer expert guidance and support.
        </p>
        <p className="mb-4">
          Explore our website to discover the comprehensive Diabetes Care
          services available at ELV Health Care Polyclinics. From routine
          check-ups to diabetes education programs, our hospital is committed to
          helping individuals with diabetes thrive. ELV is more than just a
          healthcare provider; we are your partner in diabetes management,
          offering a blend of medical expertise and compassionate care.
        </p>
        <p className="mb-4">
          {" "}
          Experience the difference at ELV Health Care Polyclinics, where our
          Diabetes Care services are tailored to meet the unique needs of each
          patient. Trust ELV for personalized and comprehensive diabetes
          management, where our commitment to your health shines through in
          every aspect of our services. Let us be your ally in navigating the
          challenges of diabetes and achieving a better quality of life.
        </p>
      </div>
    </>
  );
}

export default Dicontent;
