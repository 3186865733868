import { data } from "../../../App";
import React, { useContext } from "react";

function Cosmeticbody() {
  const blo = [
    {
      name: "Eyelid surgery (Blepharoplasty)",
      img: "../photo/cost1.jpg",
      cont1:
        "Welcome to ELV Health Care Polyclinics, where we prioritize the harmonious fusion of health and aesthetics through our specialized Eyelid Surgery (Blepharoplasty) services. Our clinic is dedicated to providing a comprehensive range of transformative treatments that not only address functional concerns but also enhance the natural beauty of your eyes.",
      cont2:
        "At ELV, our team of skilled professionals, specializing in eyelid surgery, combines advanced surgical techniques with an artistic touch to deliver personalized and rejuvenating solutions for your eyes. ELV Health Care Polyclinics offers a spectrum of Eyelid Surgery services, including upper and lower blepharoplasty, aimed at addressing issues such as sagging skin, puffiness, and wrinkles around the eyes. Our experienced surgeons understand the significance of revitalized eyes in boosting overall confidence and well-being. Whether you're seeking subtle enhancements or a more comprehensive eye transformation, ELV is dedicated to tailoring eyelid surgery solutions that align with your unique preferences and goals.",
      cont3:
        "Explore our website to discover the variety of Eyelid Surgery services available at ELV Health Care Polyclinics. From initial consultation to post-surgery care, our commitment to enhancing the natural beauty of your eyes is evident in every step of our process. Trust ELV to be your partner in achieving refreshed and youthful eyes that reflect your individuality. Experience the difference at ELV, where Eyelid Surgery is not just a procedure but a commitment to helping you achieve the vibrant gaze of your dreams.",
    },
    {
      name: "Nose correction / reshaping surgery",
      img: "../photo/cost2.jpg",
      cont1:
        "Welcome to ELV Health Care Polyclinics, where we specialize in the delicate art of Nose Correction and Reshaping Surgery. Our commitment is to blend medical expertise with aesthetic precision, ensuring not only functional improvements but also harmonious facial aesthetics. At ELV, our team of skilled professionals, specializing in nose surgery, utilizes advanced techniques to deliver personalized and transformative solutions for your nasal concerns.",
      cont2:
        "ELV Health Care Polyclinics offers a spectrum of Nose Correction and Reshaping Surgery services, including rhinoplasty, aimed at addressing issues such as nasal asymmetry, size, or shape concerns. Our experienced surgeons understand the significance of a balanced and aesthetically pleasing nose in enhancing overall facial harmony. Whether you're seeking subtle refinements or a more comprehensive nose transformation, ELV is dedicated to tailoring surgical solutions that align with your unique preferences and goals.",
      cont3:
        "Explore our website to discover the variety of Nose Correction and Reshaping Surgery services available at ELV Health Care Polyclinics. From initial consultation to post-surgery care, our commitment to achieving natural and harmonious nasal aesthetics is evident in every step of our process. Trust ELV to be your partner in achieving a nose that complements your facial features and reflects your individuality. Experience the difference at ELV, where Nose Correction and Reshaping Surgery is not just a procedure but a commitment to helping you achieve the facial balance of your dreams.",
    },
    {
      name: "Cleft Lip and palate surgery",
      img: "../photo/cost3.jpg",
      cont1:
        "Welcome to ELV Health Care Polyclinics, where we specialize in providing compassionate and expert care for Cleft Lip and Palate Surgery. Our commitment is to transform lives through comprehensive surgical solutions that not only address functional challenges but also restore confidence and well-being. At ELV, our team of skilled professionals, specializing in cleft lip and palate surgery, combines surgical expertise with a compassionate approach to deliver personalized and transformative solutions for individuals facing these conditions.",
      cont2:
        "ELV Health Care Polyclinics offers a spectrum of services for Cleft Lip and Palate Surgery, including corrective procedures aimed at addressing the challenges associated with cleft lip and palate conditions. Our experienced surgeons understand the significance of restoring facial symmetry and function, improving speech, and enhancing overall quality of life. Whether you're seeking treatment for yourself or a loved one, ELV is dedicated to tailoring surgical solutions that align with individual needs and aspirations.",
      cont3:
        "Explore our website to discover the variety of Cleft Lip and Palate Surgery services available at ELV Health Care Polyclinics. From initial consultation to post-surgery care, our commitment to comprehensive and compassionate care is evident in every step of our process. Trust ELV to be your partner in the journey towards transformation and empowerment. Experience the difference at ELV, where Cleft Lip and Palate Surgery is not just a procedure but a commitment to helping you achieve a brighter and more confident future.",
    },
    {
      name: "Ear Lobe Repair",
      img: "../photo/cost4.jpg",
      cont1:
        "Welcome to ELV Health Care Polyclinics, where we specialize in the delicate art of Ear Lobe Repair. Our commitment is to provide expert and compassionate care, restoring the natural beauty and symmetry of your earlobes. At ELV, our team of skilled professionals, specializing in ear lobe repair, utilizes advanced techniques to address concerns such as torn or stretched earlobes. Whether it's due to heavy earrings, accidents, or other factors, we understand the significance of restoring the aesthetics and functionality of your earlobes.",
      cont2:
        "ELV Health Care Polyclinics offers a range of Ear Lobe Repair services, including surgical and non-surgical procedures, tailored to meet individual needs. Our experienced practitioners prioritize achieving natural and harmonious results, ensuring your earlobes regain their original form.",
      cont3:
        "Explore our website to discover the variety of Ear Lobe Repair services available at ELV Health Care Polyclinics. From initial consultation to post-procedure care, our commitment to personalized and attentive care is evident in every step of our process. Trust ELV to be your partner in the journey towards restoring the beauty and symmetry of your earlobes. Experience the difference at ELV, where Ear Lobe Repair is not just a procedure but a commitment to helping you feel confident and comfortable in your own skin.",
    },
    {
      name: "Ear deformity correction",
      img: "../photo/cost5.jpg",
      cont1:
        "Welcome to ELV Health Care Polyclinics, where we specialize in the art of Ear Deformity Correction. Our commitment is to provide expert and compassionate care, addressing a range of ear deformities to enhance both form and function. At ELV, our skilled professionals, specializing in ear deformity correction, utilize advanced techniques to address concerns such as protruding ears, misshapen earlobes, or other congenital deformities. We understand the importance of restoring natural aesthetics and promoting overall well-being.",
      cont2:
        "ELV Health Care Polyclinics offers a spectrum of Ear Deformity Correction services, including surgical and non-surgical interventions tailored to meet individual needs. Our experienced practitioners prioritize achieving natural and harmonious results, ensuring your ears regain their intended form.",
      cont3:
        "Explore our website to discover the variety of Ear Deformity Correction services available at ELV Health Care Polyclinics. From initial consultation to post-procedure care, our commitment to personalized and attentive care is evident in every step of our process. Trust ELV to be your partner in the journey towards correcting ear deformities, promoting confidence and comfort. Experience the difference at ELV, where Ear Deformity Correction is not just a procedure but a commitment to helping you embrace your unique and natural features.",
    },
    {
      name: "Ear reconstruction",
      img: "../photo/cost6.jpg",
      cont1:
        "Welcome to ELV Health Care Polyclinics, where we specialize in the intricate art of Ear Reconstruction. Our commitment is to provide expert and compassionate care, offering comprehensive solutions for individuals seeking to restore or enhance the appearance of their ears.",
      cont2:
        "At ELV, our team of skilled professionals, specializing in ear reconstruction, utilizes advanced surgical techniques and artistic precision to address a variety of concerns, including congenital malformations, trauma-related injuries, or aesthetic enhancements. ELV Health Care Polyclinics offers a spectrum of Ear Reconstruction services, tailored to meet individual needs. Our experienced practitioners prioritize achieving natural and harmonious results, ensuring that the reconstructed ears seamlessly integrate with your facial features.",
      cont3:
        "Explore our website to discover the variety of Ear Reconstruction services available at ELV Health Care Polyclinics. From initial consultation to post-reconstruction care, our commitment to personalized and attentive care is evident in every step of our process. Trust ELV to be your partner in the journey towards reconstructing or enhancing your ears, promoting confidence and a sense of wholeness. Experience the difference at ELV, where Ear Reconstruction is not just a procedure but a commitment to helping you embrace your unique and natural features.",
    },
  ];

  const changer = useContext(data);
  return (
    <>
      <div>
        <img className="w-full mb-4" src="../photo/cos1.jpg" alt="" />
        <h1 style={{ color: "#3d7cc9" }} className="text-lg font-medium mb-4">
          Best Cosmetic Treatments in Whitefield, Bangalore.
        </h1>
        <p className="mb-4">
          Welcome to ELV Health Care Polyclinics, where we prioritize the beauty
          and health of your smile through our dedicated Cosmetic Treatments.
          Our clinic is committed to providing comprehensive and expert Cosmetic
          Dentistry services to enhance the aesthetics of your teeth while
          ensuring their overall well-being.
        </p>
        <p className="mb-4">
          At ELV, our team of skilled dentists specializes in a variety of
          cosmetic treatments tailored to meet your unique dental needs. From
          teeth whitening and veneers to smile makeovers, we offer a range of
          procedures aimed at rejuvenating and enhancing the appearance of your
          smile.
        </p>
        <p className="mb-4">
          ELV Health Care Polyclinics is devoted to promoting not only good oral
          health but also the confidence that comes with a beautiful smile. Our
          cosmetic treatments go beyond routine care, addressing aesthetic
          concerns and helping you achieve the radiant and confident smile you
          desire.
        </p>
        <p className="mb-4">
          Whether you seek a subtle enhancement or a more transformative change,
          ELV is here to provide personalized cosmetic dental care that combines
          expertise, innovation, and a commitment to your satisfaction. Trust us
          to bring out the best in your smile.
        </p>
      </div>
      <div className="w-full grid grid-cols-1 md:grid-cols-1 gap-3">
        {blo.map((elem: any, num: number) => {
          return (
            <>
              <div>
                <div className="w-full h-full grid grid-cols-1 md:grid-cols-3 rounded-xl md:gap-0 p-2">
                  <div style={{ aspectRatio: 3 / 2 }} className="py-0 w-full">
                    <div
                      style={{ backgroundImage: `url(${elem.img})` }}
                      className="w-full h-full mainbanner rounded-md"
                    ></div>
                  </div>
                  <div className=" col-span-2 mt-4 md:mt-0 py-0 md:px-3">
                    <h5 className="text-lg font-medium mb-1 ">{elem.name}</h5>
                    <p className="mt-0 line-clamp-4 mb-2 ">{elem.cont1}</p>
                    <button
                      onClick={() => {
                        changer.setTopser(elem);
                      }}
                      style={{ color: "#3d7cc9" }}
                      className="font-medium text-sm"
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}

export default Cosmeticbody;
