"use client";
import { useEffect } from "react";
import Header from "../header";
import { useState } from "react";

export default function FixHeader() {
  const [isHeaderVisible, setIsHeaderVisible] = useState<string>("none");
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsHeaderVisible("block");
      } else {
        setIsHeaderVisible("none");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div
        style={{ display: `${isHeaderVisible}`, backgroundColor: "white" }}
        className="fixed z-30 w-full trasi"
      >
        <Header />
      </div>
    </>
  );
}
