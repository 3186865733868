"use client";
import React, { useEffect, useState } from "react";
import { TfiTimer } from "react-icons/tfi";

function Contactform() {
  const [conts, setConts] = useState("");
  const [names, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  function sender() {
    let url = "https://wa.me/+919535264855?text=";
    url =
      `${url}` +
      "Name " +
      encodeURIComponent(names) +
      "%0a" +
      "Email: " +
      encodeURIComponent(email) +
      "%0a" +
      "Phone: " +
      encodeURIComponent(conts) +
      "%0a" +
      "Message: " +
      encodeURIComponent(message);

    const whatsappWindow = window.open(url, "_blank");

    if (whatsappWindow) {
      whatsappWindow.focus();
    }

    setName("");
    setEmail("");
    setConts("");
    setMessage("");
  }
  return (
    <>
      <div
        style={{
          backgroundColor: "#fafafa",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
        }}
        className="p-4 rounded-xl"
        id="yourSectionId"
      >
        <div>
          <h5
            style={{ color: "#3d7cc9" }}
            className="text-lg text-center font-medium"
          >
            ELV Health Care Polyclinics
          </h5>
          <div className="mt-3">
            <div>
              <input
                onChange={(e) => {
                  setName(e.target.value);
                }}
                placeholder="Enter Name"
                className="bg-white py-2 mt-3 px-4 border rounded-md flex items-cente w-full"
              />
            </div>
          </div>
          <div className="mt-3">
            <div>
              <input
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder="Enter Email"
                className="bg-white py-2 mt-3 px-4 border rounded-md flex items-cente w-full"
              />
            </div>
          </div>
          <div className="mt-3">
            <div>
              <input
                onChange={(e) => {
                  setConts(e.target.value);
                }}
                type="number"
                placeholder="Enter Phone"
                className="bg-white py-2 mt-3 px-4 border rounded-md flex items-cente w-full"
              />
            </div>
          </div>
          <div className="mt-3">
            <div>
              <input
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                placeholder="Enter Message"
                className="bg-white py-2 mt-3 px-4 border rounded-md flex items-cente w-full"
              />
            </div>
          </div>
          <div className="mt-4 flex justify-center">
            <div>
              <button
                onClick={() => {
                  sender();
                }}
                style={{ backgroundColor: "#3d7cc9" }}
                className="border-inherit border py-1 px-3 rounded-md text-white w-full"
              >
                Appointment
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contactform;
