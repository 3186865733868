import React from "react";

function Contactbutton() {
  return (
    <div
      style={{ padding: "13.5px 10px" }}
      className="fixed md:hidden bottom-0 z-40 bg-white w-full h-14 border-t"
    >
      <div className="grid grid-cols-2 gap-2">
        <div>
          <a
            href="tel:9535264855"
            style={{ border: "1.5px solid #3d7cc9" }}
            className="border-inherit border-2 text-black p-1 rounded-md w-full flex justify-center text-sm"
          >
            Call Us
          </a>
        </div>
        <div>
          <a
            href={`https://api.whatsapp.com/send?phone=+919535264855&text=Hello`}
            style={{ border: "1.5px solid #3d7cc9" }}
            className="border-inherit border-2 text-black p-1 rounded-md w-full flex justify-center text-sm"
          >
            Whatsapp Us
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contactbutton;
