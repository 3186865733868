import React, { useEffect, useState } from "react";
import ServiceReview from "../Services/ServiceReview";
import ContactwithWat from "../Services/ContactwithWat";
import Divser from "../About/Divser";
import Divider from "../Divider";
import Doctorbox from "../Home/Docterbox";
import Serviceinfo from "../Services/Serviceinfo";
import Infobox from "../About/Infobox";
import Aboutbanner from "../About/Aboutbanner";
import Contactform from "../About/Contactform";
import Qns from "../Services/Qns";
import Callin from "../About/Callin";
import Infiry from "../About/Infiry";
import Blogcon from "./Blogcon";
import DisBlog from "./DisBlog";
import Contactbynum from "../Services/Contactbynum";
import Youtube from "../Services/Youtube";
import Blogslide from "./Blogslide";
import ServiceList from "../Services/ServiceList";
import Obg from "../Servicepage/Obg/Obg";
import { movetop } from "../../fun";

function Blog() {
  useEffect(() => {
    movetop();
  }, []);
  const blo = [
    {
      name: "What Conditions does a urologist treat?",
      img: "../photo/bb1.jpg",
      cont1:
        "Urologists are medical specialists who diagnose and treat a wide range of conditions related to the urinary system and male reproductive system. These highly trained physicians are equipped to address issues affecting the kidneys, bladder, ureters, and urethra. Common conditions treated by urologists include urinary tract infections (UTIs), kidney stones, bladder dysfunction, and urinary incontinence. They also play a crucial role in managing benign prostatic hyperplasia (BPH), a condition that causes an enlarged prostate and can lead to urinary problems in men. Urologists are skilled in using various diagnostic tools, such as imaging studies and urodynamic tests, to identify and assess these conditions accurately.",
      cont2:
        "In addition to urinary concerns, urologists specialize in addressing male reproductive health issues. This includes the diagnosis and treatment of conditions such as erectile dysfunction, male infertility, and prostate cancer. Urologists may perform surgical procedures, prescribe medications, or recommend lifestyle modifications to manage and alleviate these conditions. Their expertise extends to the comprehensive care of patients, ensuring that individuals receive personalized treatment plans tailored to their specific urological and reproductive health needs.",
      cont3: "",
    },
    {
      name: "Everything about Cardiac Arrest",
      img: "../photo/bb2.jpg",
      cont1:
        "Cardiac arrest is a sudden and life-threatening event where the heart unexpectedly stops beating, leading to a cessation of blood flow to vital organs. This medical emergency requires immediate intervention to restore the heart's normal rhythm and increase the chances of survival. Unlike a heart attack, which is caused by a blockage in the blood vessels supplying the heart, cardiac arrest results from a malfunction in the heart's electrical system, leading to an abrupt loss of cardiac function.",
      cont2:
        "The symptoms of cardiac arrest are immediate and dramatic, often including a sudden collapse, loss of consciousness, and the absence of a detectable pulse. Prompt action is crucial, and bystanders can play a vital role by initiating cardiopulmonary resuscitation (CPR) to maintain blood circulation until professional medical help arrives. Automated External Defibrillators (AEDs) are also valuable tools in the early stages of cardiac arrest, delivering electric shocks to the heart to restore its normal rhythm. Prevention strategies involve addressing underlying heart conditions, maintaining a heart-healthy lifestyle, and promoting widespread awareness of CPR and AED use to empower communities to respond swiftly to cardiac emergencies.",
      cont3: "",
    },
    {
      name: "Covid Vaccines and their Protective Power",
      img: "../photo/bb3.jpg",
      cont1:
        "Covid vaccines have emerged as powerful tools in the global fight against the COVID-19 pandemic, providing a robust shield against severe illness, hospitalization, and death caused by the virus. These vaccines work by training the immune system to recognize and combat the virus, either by using a weakened or inactivated form of the virus, viral proteins, or genetic material. Not only do these vaccines contribute to individual protection, but they also play a critical role in preventing the spread of the virus within communities, contributing to the achievement of herd immunity.",
      cont2:
        "The protective power of COVID-19 vaccines is evident in the significant reduction of severe outcomes among those who have been vaccinated. Vaccinated individuals are far less likely to experience severe illness or require hospitalization compared to their unvaccinated counterparts. Moreover, vaccines have proven effective against various variants of the virus, further highlighting their versatility and adaptability in the face of an evolving threat. The widespread distribution and administration of COVID vaccines have become pivotal in global efforts to curb the pandemic's impact, offering a pathway toward a safer and more resilient future.",
      cont3: "",
    },
    {
      name: "Doctors to be Consulted for Back Pain",
      img: "../photo/bb4.jpg",
      cont1:
        "For individuals experiencing persistent or severe back pain, consulting with healthcare professionals specializing in musculoskeletal health is crucial. Orthopedic doctors, who specialize in the musculoskeletal system, including the spine, are often the first point of contact for back pain assessment. These specialists can conduct thorough examinations, order diagnostic tests, and provide a comprehensive evaluation of the underlying causes of back pain. Orthopedic doctors may recommend various treatment options, including physical therapy, medications, or in some cases, surgical interventions, depending on the severity and nature of the back pain.",
      cont2:
        "Another valuable healthcare professional to consult for back pain is a physiatrist, also known as a physical medicine and rehabilitation specialist. Physiatrists specialize in non-surgical approaches to manage musculoskeletal conditions and can develop personalized treatment plans focused on rehabilitation, pain management, and improving overall functional abilities. They may incorporate modalities such as physical therapy, exercise regimens, and interventional procedures to address back pain and enhance the patient's quality of life. Consulting with both orthopedic doctors and physiatrists allows for a comprehensive and multidisciplinary approach to back pain management, ensuring that individuals receive tailored and effective care for their specific condition.",
      cont3: "",
    },
    {
      name: "The Most Common Diseases of Childhood",
      img: "../photo/bb5.jpg",
      cont1:
        "Childhood is often marked by a susceptibility to certain common diseases, with respiratory infections topping the list. Respiratory illnesses like the common cold, influenza, and various types of viral and bacterial infections frequently affect children. These conditions can lead to symptoms such as cough, congestion, fever, and general discomfort. Fortunately, most respiratory infections are usually mild and resolve with proper care and supportive measures. Additionally, childhood vaccinations have significantly reduced the prevalence and severity of certain infectious diseases, providing a crucial layer of protection for children.",
      cont2:
        "Another prevalent group of childhood diseases includes various types of gastrointestinal infections. Conditions like gastroenteritis, often caused by viruses or bacteria, can result in symptoms such as diarrhea, vomiting, and abdominal pain. While these infections can be uncomfortable and sometimes lead to dehydration, they are typically self-limiting and resolve with appropriate hydration and, in some cases, medical intervention. Maintaining good hygiene practices, such as regular handwashing, is essential in preventing the spread of these common childhood diseases, fostering overall well-being in the pediatric population.",
      cont3: "",
    },
    {
      name: "Teenage Health Problems and How to Deal with Them",
      img: "../photo/bb6.jpg",
      cont1:
        "Teenagers often face a range of health challenges unique to their stage of development. Mental health issues, such as anxiety and depression, can be prevalent during adolescence due to various factors, including academic pressures, social dynamics, and hormonal changes. It's crucial for parents, educators, and healthcare professionals to be vigilant in recognizing signs of mental health struggles in teens and providing necessary support. Open communication, access to mental health resources, and fostering a supportive environment can significantly contribute to addressing and managing these challenges.",
      cont2:
        "Another common health concern for teenagers is the development of unhealthy lifestyle habits, including poor nutrition, lack of physical activity, and the potential for substance use. Encouraging healthy habits, such as a balanced diet and regular exercise, is essential for promoting overall well-being during adolescence. Additionally, fostering an open dialogue about the risks associated with substance use and providing education on making informed choices empower teenagers to make healthier decisions. Engaging in positive activities, building strong social connections, and having a reliable support system are vital components of addressing teenage health problems and promoting a foundation for lifelong well-being.",
      cont3: "",
    },
  ];
  const [select, setSelect] = useState<any>(blo[0]);
  const height: number = NaN;
  type elem = {
    oneBody?: JSX.Element;
    twoBody?: JSX.Element;
    threeBody?: JSX.Element;
  };
  const elemnt: elem = {
    oneBody: <Blogcon blo={blo} setSelect={setSelect} />,
    twoBody: <Infiry />,
    threeBody: <Callin />,
  };
  const elemnt2: elem = {
    oneBody: <Qns />,
    twoBody: <Contactform />,
  };
  return (
    <>
      <Aboutbanner name="Blog" banner={"../photo/sam3.jpg"} />
      {/* <div style={{ backgroundColor: "#eff6ff" }} className="pb-2">
        <Infobox />
      </div> */}
      <div id="section-heading" className="-z-10 -top-56"></div>

      {/* <ServiceList /> */}
      {/* <Blogslide />
      <div className="py-6">
        <Contactbynum color={"#3d7cc9"} />
      </div> */}
      <div className="pt-10 pb-0"></div>
      <Divser elemnt={elemnt} />
      {/* <div className="pt-10 pb-4">
        <Divider />
      </div> */}
      {/* <Divser elemnt={elemnt2} /> */}
      {/* <div className="pt-10">
        <ContactwithWat />
      </div> */}
      {/* <ServiceReview /> */}
      <Youtube />
    </>
  );
}

export default Blog;
