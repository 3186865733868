import React, { useEffect } from "react";
import Qns from "../../Services/Qns";
import Contactform from "../../About/Contactform";
import Servicebanner from "../../Services/Servicebanner";
import Infobox from "../../About/Infobox";
import Divser from "../../About/Divser";
import Divider from "../../Divider";
import ServiceReview from "../../Services/ServiceReview";
import EmergencyServicescon from "./EmergencyServicescon";
import Infiry from "../../About/Infiry";
import Callin from "../../About/Callin";
import { movetop } from "../../../fun";

function EmergencyServices() {
  type elem = {
    oneBody?: JSX.Element;
    twoBody?: JSX.Element;
    threeBody?: JSX.Element;
  };
  const elemnt: elem = {
    oneBody: <EmergencyServicescon />,
    twoBody: <Infiry />,
    threeBody: <Callin />,
  };
  const elemnt2: elem = {
    oneBody: <Qns />,
    twoBody: <Contactform />,
  };

  useEffect(() => {
    movetop();
  }, []);
  return (
    <>
      <Servicebanner name="Emergency Services" banner={"../photo/dd12.jpg"} />
      <Infobox />
      {/* <Doctorbox /> */}
      <div className="flex justify-center py-6">
        <div className="containerbox">
          <p className="mb-4">
            As of my last knowledge update in January 2023, I don't have
            specific details about ELV Health Care Polyclinics' emergency
            services. However, I can provide you with a general outline of what
            emergency services at a healthcare facility might include. For the
            most accurate and up-to-date information, I recommend checking
            directly with ELV Health Care Polyclinics through their official
            channels. Here's a general point-wise outline:
          </p>
        </div>
      </div>
      <Divser elemnt={elemnt} />

      <div className="pt-10 pb-10">
        <Divider />
      </div>
      <Divser elemnt={elemnt2} />

      <div className="pt-10 pb-4">
        <Divider />
      </div>
      <ServiceReview />
    </>
  );
}

export default EmergencyServices;
