import React from "react";

function Primarycarecon() {
  return (
    <>
      <div>
        <img className="w-full mb-4" src="../photo/aa18.jpg" alt="" />
        <h1 style={{ color: "#3d7cc9" }} className="text-lg font-medium mb-4">
          Best Primary Care Services in Whitefield, Bangalore.
        </h1>
        <p className="mb-4">
          Welcome to ELV Health Care Polyclinics, your premier destination for
          comprehensive Primary Care services. As a trusted healthcare provider,
          ELV is dedicated to delivering a wide range of primary care services
          that prioritize prevention, early detection, and personalized patient
          care. Our team of experienced and compassionate healthcare
          professionals at ELV Health Care Polyclinics is committed to being
          your primary partner in maintaining optimal health and well-being.
        </p>
        <p className="mb-4">
          {" "}
          At ELV, our Primary Care services cover a spectrum of healthcare
          needs, from routine check-ups to managing chronic conditions. Our
          skilled primary care physicians offer personalized consultations,
          thorough examinations, and evidence-based medical interventions. We
          understand the importance of building lasting relationships with our
          patients, and our primary care services are designed to ensure
          continuity of care and a holistic approach to your health.
        </p>
        <p className="mb-4">
          {" "}
          ELV Health Care Polyclinics places a strong emphasis on accessibility
          and convenience in our Primary Care services. Our online appointment
          scheduling, telemedicine options, and efficient in-person
          consultations are tailored to meet the diverse needs of our community.
          Whether you're seeking preventive care, vaccinations, or managing a
          chronic condition, ELV is here to provide expert guidance and support.
        </p>
        <p className="mb-4">
          {" "}
          As you explore our website, you'll discover a wealth of resources and
          information about our Primary Care services. ELV Health Care
          Polyclinics is more than just a hospital; we are your dedicated
          partner in health, offering a comprehensive approach to primary care
          that combines medical expertise with a patient-centered focus. Trust
          ELV for your primary care needs, where our commitment to your health
          is unwavering, and our primary care services reflect our dedication to
          providing quality and compassionate care.
        </p>
        <p className="mb-4">
          Experience the difference at ELV Health Care Polyclinics, where
          primary care is not just a service but a cornerstone of our commitment
          to community health. From preventive screenings to personalized
          treatment plans, ELV is your trusted source for primary care
          excellence. Let us be your primary healthcare partner, guiding you on
          your journey to a healthier and happier life.
        </p>
      </div>
    </>
  );
}

export default Primarycarecon;
