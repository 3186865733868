"use client"
import React, { useEffect, useState } from "react";
import { FaLocationDot } from "react-icons/fa6";
import { IoIosStar } from "react-icons/io";
import { MdMedicalServices } from "react-icons/md";
import { docterlist } from "./docterlist";

function Doctorconnew({doctor}:{doctor:any}) {
    const [update, setUpdate]=useState<any>([])
    useEffect(()=>{
        const newValue= docterlist.filter((elem)=>{
            return elem.name==doctor
        })
        setUpdate(newValue)
    },[])
 
  return (
    <>
      <section className="flex justify-center pb-8 pt-0">
        <div className="w-full">
          <div className="flex w-full mt-0">
            <div className="px-0 w-full">
              {update.map((elem:any) => {
                return (
                  <>
                    <div className="w-full p-2 flex">
                      <div
                        className="w-full "
                      >
                        <div className="flex flex-wrap   p-4 relative w-full">
                          <div className="w-full flex justify-center  sm:w-1/5">
                            <div className="w-full flex md:block justify-center">
                              <img
                                className=" mb-4 w-56 sm:w-full"
                                src={elem.img}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="w-full sm:w-4/5 pl-2">
                            <h6
                              style={{ color: "#3d7cc9" }}
                              className="font-medium"
                            >
                              {elem.name}
                            </h6>
                            {elem.dis}
                            <div className="w-full mt-3 flex">
                              <div className="w-1/3 flex items-center">
                                <IoIosStar />
                                <h6 className="text-gray-600 ml-1 md:ml-2 text-sm">
                                  4.6/5
                                </h6>
                              </div>
                              <div className="w-1/3 flex items-center">
                                <MdMedicalServices />
                                <h6 className="text-gray-600 ml-1 md:ml-2 text-sm">
                                  {`${elem.exper}+ Years`}
                                </h6>
                              </div>
                              <div className="w-1/3 flex items-center">
                                <FaLocationDot />
                                <h6 className="text-gray-600 ml-1 md:ml-2 text-sm">
                                  Bangalore
                                </h6>
                              </div>
                            </div>
                            <button className="border-inherit border py-1 px-3 rounded-md mt-3 text-white bg-gray-400 text-sm">
                              {elem.depat}
                            </button>
                            <div className="w-full mt-4 flex justify-between">
                              <div className="w-5/12 pr-3">
                                <a
                                  href="tel:9535264855"
                                  style={{ border: "1.5px solid #3d7cc9" }}
                                  className="border-inherit border-2 text-black p-1 rounded-md w-full hidden md:flex justify-center text-sm"
                                >
                                  9535264855
                                </a>
                                <a
                                  className="border-inherit border-2 text-black p-1 rounded-md w-full flex justify-center md:hidden text-sm"
                                  href="tel:9535264855"
                                >
                                  Call Us
                                </a>
                              </div>
                              <div className="w-7/12">
                                <div className="flex w-full h-full">
                                  <a
                                    href="tel:9535264855"
                                    style={{ backgroundColor: "#3d7cc9" }}
                                    className="border-inherit border py-1 px-3 rounded-md text-white w-full text-sm h-full text-center"
                                  >
                                    Appointment
                                  </a>
                                </div>
                              </div>
                            </div>
                            <p className="mt-4">{elem.con}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Doctorconnew;
