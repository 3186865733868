import React from "react";
import { Link } from "react-router-dom";

function Specializedclinics() {
  const menu = [
    {
      name: "Diverse Medical Specialties",
      dis1: "ELV Health Care Polyclinics offer a range of specialized clinics, each focusing on a specific medical specialty.",
      dis2: "These clinics cover various areas such as Internal Medicine, Orthopedics, Cardiology, and more, providing specialized care tailored to different healthcare needs.",
      link: "",
      img: "../photo/ss1.jpg",
    },
    {
      name: "Expert Medical Professionals",
      dis1: "Each specialized clinic at ELV is staffed with a team of experienced and dedicated medical professionals.",
      dis2: "Specialists in their respective fields, these healthcare professionals bring expertise and a deep understanding of specific medical conditions to ensure comprehensive and effective care.",
      link: "",
      img: "../photo/ss2.jpg",
    },
    {
      name: "Advanced Diagnostic Services",
      dis1: "ELV's specialized clinics are equipped with state-of-the-art diagnostic services and technologies.",
      dis2: "Services like ultrasonography, ECHO, X-rays, and blood tests are integrated into each clinic to facilitate accurate and timely diagnosis, contributing to effective treatment plans.",
      link: "",
      img: "../photo/ss3.jpg",
    },
    {
      name: "Patient-Centered Care",
      dis1: "The specialized clinics at ELV Health Care Polyclinics prioritize patient-centered care.",
      dis2: "The focus is on providing personalized and specialized services, addressing the unique needs of each patient within the context of their specific medical condition.",
      link: "",
      img: "../photo/ss4.jpg",
    },
  ];
  return (
    <>
      <div className="flex justify-center">
        <div className="containerbox">
          <div className="w-full grid grid-cols-1 gap-3">
            {menu.map((elem) => {
              return (
                <>
                  <div>
                    <div
                      style={{
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                      }}
                      className="w-full grid grid-cols-1 md:grid-cols-4 rounded-xl md:gap-4 p-4"
                    >
                      <div style={{ aspectRatio: 7 / 6 }} className="py-0">
                        <div
                          style={{ backgroundImage: `url(${elem.img})` }}
                          className="w-full h-full mainbanner rounded-md"
                        ></div>
                      </div>
                      <div className="col-span-3 mt-4 md:mt-0">
                        <h5
                          style={{ color: "#3d7cc9" }}
                          className="text-lg font-medium mb-2"
                        >
                          {elem.name}
                        </h5>
                        <p className="mt-0">{elem.dis1}</p>
                        <p className="mt-1">{elem.dis2}</p>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Specializedclinics;
