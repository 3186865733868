import React from "react";
import { BiClinic } from "react-icons/bi";
import { FaAddressBook, FaLocationCrosshairs } from "react-icons/fa6";
import { LiaFileMedicalSolid, LiaHospitalSymbolSolid } from "react-icons/lia";
import { TbReportMedical } from "react-icons/tb";
import {
  MdMilitaryTech,
  MdMoreTime,
  MdOutlineWifiCalling3,
} from "react-icons/md";
import { FaSearchLocation } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";

function Aboutbanner({ name, banner }: { name: string; banner: string }) {
  return (
    <>
      <div
        // style={{
        //   backgroundColor: "#e3eff1",
        //   backgroundImage: "url(../photo/sam.jpg)",
        // }}
        style={{
          backgroundImage: ` url(${banner})`,
        }}
        className="w-full block md:pt-10 pb-0 md:py-10 home-banner-in bg-cover"
      >
        <div className="flex justify-center">
          <div className="containerbox">
            <div className="flex flex-wrap">
              <div className="hidden md:block w-full md:w-1/2 xl:w-1/3 relative h-96 md:h-80">
                <div>
                  <img
                    className="w-64 md:w-64 mb-1"
                    src="../photo/log.png"
                    alt=""
                  />
                </div>
                <div className="absolute bottom-0">
                  <h5
                    style={{ color: "#48b95f" }}
                    className="text-4xl mb-1 font-bold"
                  >
                    {name}
                  </h5>
                  <h6 className="text-lg">Join the ELV Health Care Program!</h6>
                  <a
                    href="tel:9535264855"
                    style={{ backgroundColor: "#48b95f" }}
                    className="border-inherit border p-2 rounded-md mt-4 text-white flex items-center mr-2 justify-center"
                  >
                    <MdOutlineWifiCalling3 className="mr-1 text-xl" />
                    Book Appointment
                  </a>
                </div>
              </div>
              <div className="h-56 md:h-auto w-full md:hidden xl:flex xl:w-1/3 flex justify-center items-center p-5">
                <div className="md:flex justify-center">
                  <img
                    className="w-32 md:w-4/6"
                    src="../photo/logobanner.png"
                    alt=""
                  />
                  <h5
                    style={{ color: "#48b95f" }}
                    className="text-2xl ml-1.5 font-bold block md:hidden"
                  >
                    {name}
                  </h5>
                </div>
              </div>
              {/* <div className="md:w-1/2 xl:w-1/3 hidden md:block">
                <div className="w-full">
                  <div className="w-full flex justify-start">
                    <a
                      style={{ backgroundColor: "#00263e" }}
                      className="text-white w-52 text-center h-14 flex items-center text-lg font-bold justify-center rounded-tl-2xl rounded-tr-2xl"
                      href=""
                    >
                      Hospital Details
                    </a>
                  </div> */}
              {/* <div
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",
                  }}
                  className="w-full bg-white py-10 px-8 rounded-tr-xl rounded-bl-xl rounded-br-xl"
                >
                  <h6 className="font-medium mb-3">
                    Share your details and we will reach out to you with the
                    next steps
                  </h6>
                  <div className="w-full flex flex-wrap mb-3">
                    <div className="sm:pr-1.5 w-full sm:w-1/2 mb-3 sm:mb-0">
                      <input
                        className="border-x-2 border-y-2 h-10 pl-2 w-full border-gray-400 rounded"
                        type="text"
                        placeholder="Enter Name"
                      />
                    </div>
                    <div className="sm:pl-1.5 w-full sm:w-1/2 mb-0 sm:mb-0">
                      <input
                        className="border-x-2 border-y-2 h-10 pl-2 w-full border-gray-400 rounded"
                        type="text"
                        placeholder="Enter Phone"
                      />
                    </div>
                  </div>
                  <div className="w-full mb-3 flex flex-wrap">
                    <div className=" w-full">
                      <input
                        className="border-x-2 border-y-2 h-10 pl-2 w-full border-gray-400 rounded"
                        type="text"
                        placeholder="Enter Email"
                      />
                    </div>
                  </div>
                  <div className="w-full flex flex-wrap">
                    <div className=" w-full">
                      <input
                        className="border-x-2 border-y-2 h-10 pl-2 w-full border-gray-400 rounded"
                        type="text"
                        placeholder="Enter Message"
                      />
                    </div>
                  </div>

                  <div className="w-full flex justify-center mt-5">
                    <button
                      style={{ backgroundColor: "#48b95f" }}
                      className="w-1/2 h-12 text-center text-lg font-bold text-white rounded-md"
                    >
                      Submit
                    </button>
                  </div>
                </div> */}
              {/* <div
                    style={{
                      backgroundColor: "#fafafa",
                      boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                    }}
                    className="p-4 rounded-tr-xl rounded-bl-xl roun h-auto md:h-full lg:h-auto hidden md:block"
                    id="yourSectionId"
                  >
                    <div>
                      <h5
                        style={{ color: "#3d7cc9" }}
                        className="text-lg text-center font-medium"
                      >
                        ELV Health Care Polyclinics
                      </h5>
                      <div className="mt-2">
                        <div className="grid grid-cols-1 gap-3 sm:gap-4">
                          <div
                            style={{
                              boxShadow:
                                "rgba(0, 0, 0, 0.001) 0px 0px 0px 0.1px",
                            }}
                            className="bg-white py-2.5 px-2 sm:px-4 lg:px-1 xl:px-4 border rounded-md flex items-center"
                          >
                            <div>
                              <MdMoreTime className="text-3xl font-light text-gray-600" />
                            </div>
                            <div className="pl-1 sm:pl-3 lg:pl-2 xl:pl-3">
                              <h6 className="text-sm font-medium text-gray-600">
                                24/7 service
                              </h6>
                            </div>
                          </div>
                          <div
                            style={{
                              boxShadow:
                                "rgba(0, 0, 0, 0.001) 0px 0px 0px 0.1px",
                            }}
                            className="bg-white py-2.5 px-2 sm:px-4 lg:px-1 xl:px-4 border rounded-md flex items-center"
                          >
                            <div>
                              <LiaFileMedicalSolid className="text-3xl font-light text-gray-600" />
                            </div>
                            <div className="pl-1 sm:pl-3 lg:pl-2 xl:pl-3">
                              <h6 className="text-sm font-medium text-gray-600">
                                NABL certified lab reports will
                                receive with in 6 hrs
                              </h6>
                            </div>
                          </div>
                          <div
                            style={{
                              boxShadow:
                                "rgba(0, 0, 0, 0.001) 0px 0px 0px 0.1px",
                            }}
                            className="bg-white py-2.5 px-2 sm:px-4 lg:px-1 xl:px-4 border rounded-md flex items-center"
                          >
                            <div>
                              <IoLocationOutline className="text-3xl font-light text-gray-600" />
                            </div>
                            <div className="pl-1 sm:pl-3 lg:pl-2 xl:pl-3">
                              <h6 className="text-sm font-medium text-gray-600">
                                ELV Health Care Polyclinic, MR ASTER, Ground
                                Floor Vijayanagar, Whitefield, Bengaluru,
                                Karnataka 560066
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 flex justify-center">
                        <div>
                          <a
                            href="tel:9535264855"
                            style={{ backgroundColor: "#3d7cc9" }}
                            className="border-inherit border py-1 px-3 rounded-md text-white w-full"
                          >
                            Appointment
                          </a>
                        </div>
                      </div>
                    </div>
                  </div> */}
              {/* </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* <div style={{ padding: "0px 10px" }} className="block md:hidden">
          <div className="w-full flex justify-start">
            <a
              style={{ backgroundColor: "#00263e" }}
              className="text-white w-52 text-center h-14 flex items-center text-lg font-bold justify-center rounded-tl-2xl rounded-tr-2xl"
              href=""
            >
              Hospital Details
            </a>
          </div>
        </div> */}
      </div>
      {/* <div className="flex justify-center">
        <div style={{ padding: "0px 10px" }} className="w-full">
          <div
            style={{
              backgroundColor: "#fafafa",
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
            }}
            className="p-4 rounded-tr-xl rounded-bl-xl roun h-auto md:h-full lg:h-auto block md:hidden"
            id="yourSectionId"
          >
            <div>
              <h5
                style={{ color: "#3d7cc9" }}
                className="text-lg text-center font-medium"
              >
                ELV Health Care Polyclinics
              </h5>
              <div className="mt-2">
                <div className="grid grid-cols-1 gap-3 sm:gap-4">
                  <div
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.001) 0px 0px 0px 0.1px",
                    }}
                    className="bg-white py-2.5 px-2 sm:px-4 lg:px-1 xl:px-4 border rounded-md flex items-center"
                  >
                    <div>
                      <MdMoreTime className="text-3xl font-light text-gray-600" />
                    </div>
                    <div className="pl-1 sm:pl-3 lg:pl-2 xl:pl-3">
                      <h6 className="text-sm font-medium text-gray-600">
                        24/7 service
                      </h6>
                    </div>
                  </div>
                  <div
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.001) 0px 0px 0px 0.1px",
                    }}
                    className="bg-white py-2.5 px-2 sm:px-4 lg:px-1 xl:px-4 border rounded-md flex items-center"
                  >
                    <div>
                      <LiaFileMedicalSolid className="text-3xl font-light text-gray-600" />
                    </div>
                    <div className="pl-1 sm:pl-3 lg:pl-2 xl:pl-3">
                      <h6 className="text-sm font-medium text-gray-600">
                        NABL certified lab reports will receive with in 6 hrs
                      </h6>
                    </div>
                  </div>
                  <div
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.001) 0px 0px 0px 0.1px",
                    }}
                    className="bg-white py-2.5 px-2 sm:px-4 lg:px-1 xl:px-4 border rounded-md flex items-center"
                  >
                    <div>
                      <IoLocationOutline className="text-3xl font-light text-gray-600" />
                    </div>
                    <div className="pl-1 sm:pl-3 lg:pl-2 xl:pl-3">
                      <h6 className="text-sm font-medium text-gray-600">
                        ELV Health Care Polyclinic, MR ASTER, Ground Floor
                        Vijayanagar, Whitefield, Bengaluru, Karnataka 560066
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3 flex justify-center">
                <div>
                  <button
                    style={{ backgroundColor: "#3d7cc9" }}
                    className="border-inherit border py-1 px-3 rounded-md text-white w-full"
                  >
                    Appointment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Aboutbanner;
