"use client";
import { FaStar } from "react-icons/fa6";
import { AiOutlineLike } from "react-icons/ai";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function Youtube() {
  const settings: Settings = {
    infinite: true,
    speed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const menu = [
    {
      link: "https://www.youtube.com/embed/xvkPygy9_sA?si=rL1ajnH2flhi6VJF",
      name: "ELV Health Care Polyclinics Whitefield Bangalore | Health Tips",
    },
    {
      link: "https://www.youtube.com/embed/InSlp1F_PJw?si=Z5flQcoV6FUyyQHX",
      name: "Kidney Stone & Summer Surprise Link | ELV Healthcare Polyclinics.",
    },
    {
      link: "https://www.youtube.com/embed/PiTiMTz0Ovs?si=OtJt5c6A5t5EejGo",
      name: "These are the factors associated with the increasing risks of a heart attack",
    },
    {
      link: "https://www.youtube.com/embed/5AlP4Ewsq98?si=jeFU2mWF1REo9oZU",
      name: "Seasonal Affective Disorder What Is It? Health Awareness Tips",
    },
  ];
  return (
    <>
      <div className="flex justify-center py-12">
        <div className="containerbox">
          <div className="w-full flex justify-center">
            <h6 className="text-xl font-medium mb-3">Recent Updates</h6>
          </div>
          <div className="w-full overflow-hidden">
            <Slider {...settings}>
              {menu.map((elem) => {
                return (
                  <>
                    <div className="w-full p-2.5">
                      <div className="w-full p-0 rounded">
                        <div className="w-full">
                          <div className="flex items-center">
                            <iframe
                              width="100%"
                              style={{ aspectRatio: 1.5 / 1 }}
                              src={elem.link}
                              title="YouTube video player"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            ></iframe>
                          </div>
                        </div>
                        <div className="flex p-2 pb-3">
                          <div className="flex">
                            <div>
                              <div className="mt-1.5 w-9 h-9 font-medium text-white flex justify-center border bg-gray-100 rounded-full mr-3 items-center">
                                <img
                                  className="p-1"
                                  src="../photo/logobanner.png"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="w-auto">
                              <h6 className="font-medium line-clamp-2">
                                {elem.name}
                              </h6>
                              <div className="flex items-center">
                                <h6 className="font-medium text-gray-600 flex items-center">
                                  ELV
                                </h6>
                                <div className="h-full w-4 flex justify-center items-center">
                                  <div className="w-1 h-1 bg-black rounded mt-1"></div>
                                </div>
                                <h6 className="font-medium text-gray-600 flex items-center">
                                  21k subscribers
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </Slider>
          </div>
          <div className="w-full justify-center">
            <div className="flex justify-center mt-4">
              <a
                href="https://www.youtube.com/@elvhealthcarepolyclinics"
                style={{ backgroundColor: "#3d7cc9" }}
                className="border-inherit border py-1 px-4 rounded-md mt-4 text-white flex items-center sm:mr-2 mr-"
              >
                View More Update
              </a>
              <a
                href="tel:9535264855"
                style={{ backgroundColor: "#3d7cc9" }}
                className="border-inherit border py-1 px-4 rounded-md mt-4 text-white hidden sm:flex items-center ml-2"
              >
                Book Appointment
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
