import React, { useContext } from "react";
import { data } from "../../../App";

function Dermatologycon() {
  const blo = [
    {
      name: "Chemical peeling",
      img: "../photo/der1.jpg",
      cont1:
        "Welcome to ELV Health Care Polyclinics, where we pride ourselves on blending health and aesthetics seamlessly through our specialized Chemical Peeling services. Our clinic is dedicated to offering a comprehensive range of transformative treatments, focusing on enhancing the natural beauty of your skin.",
      cont2:
        "At ELV, our team of skilled professionals, specializing in chemical peeling, employs advanced techniques with a keen eye for artistry to provide personalized and rejuvenating solutions for your skin. ELV Health Care Polyclinics offers a spectrum of Chemical Peeling services, addressing concerns such as uneven skin tone, pigmentation, and fine lines. Our experienced practitioners understand the importance of radiant and refreshed skin in boosting overall confidence and well-being.",
      cont3:
        "Explore our website to uncover the variety of Chemical Peeling services available at ELV Health Care Polyclinics. From the initial consultation to post-treatment care, our commitment to enhancing the natural beauty of your skin is evident in every step of our process. Trust ELV to be your partner in achieving a revitalized and youthful complexion that reflects your individuality. Experience the difference at ELV, where Chemical Peeling is not just a procedure but a commitment to helping you achieve the radiant skin of your dreams.",
    },
    {
      name: "Dermaroller, Dermabrasion",
      img: "../photo/der2.jpg",
      cont1:
        "Welcome to ELV Health Care Polyclinics, where we specialize in rejuvenating skin treatments, including Dermaroller and Dermabrasion. Our clinic is dedicated to providing transformative solutions that enhance your skin's natural radiance. With Dermaroller, our skilled professionals use micro-needling techniques to stimulate collagen production, promoting skin renewal and a smoother texture. This non-invasive procedure is designed to address concerns such as fine lines, scars, and uneven skin tone, leaving you with a refreshed and revitalized complexion.",
      cont2:
        "Dermabrasion, another key service at ELV, involves the gentle removal of the outer layer of skin, promoting cell turnover and revealing a more youthful appearance. This technique is effective in treating issues like acne scars, sun damage, and wrinkles, ensuring your skin looks and feels its best.",
      cont3:
        "Explore our website to discover the range of Dermaroller and Dermabrasion services available at ELV Health Care Polyclinics. From consultation to aftercare, our commitment to enhancing your skin's natural beauty is evident in every step of our process. Trust ELV to be your partner in achieving radiant and rejuvenated skin that reflects your unique glow. Experience the difference at ELV, where Dermaroller and Dermabrasion are not just procedures but a commitment to helping you achieve the vibrant and refreshed skin you desire.",
    },
    {
      name: "Vitiligo treatment",
      img: "../photo/der3.jpg",
      cont1:
        "Welcome to ELV Health Care Polyclinics, where we specialize in comprehensive Vitiligo treatment, dedicated to restoring confidence and promoting skin health. Our clinic understands the unique challenges posed by Vitiligo and is committed to providing personalized solutions that address your specific needs.",
      cont2:
        "At ELV, our experienced team of professionals utilizes advanced techniques and treatments to target and manage Vitiligo effectively. We offer a range of therapies, including phototherapy, topical treatments, and innovative approaches tailored to your skin type and condition. Our goal is not only to minimize the visible effects of Vitiligo but also to enhance overall skin health.",
      cont3:
        "Explore our website to discover the variety of Vitiligo treatment options available at ELV Health Care Polyclinics. From the initial consultation to ongoing care, our commitment to supporting individuals with Vitiligo is evident in every step of our process. Trust ELV to be your partner in achieving skin restoration and renewed confidence. Experience the difference at ELV, where Vitiligo treatment is not just a service but a commitment to helping you embrace your unique beauty.",
    },
    {
      name: "Anti-aging solutions",
      img: "../photo/der4.jpg",
      cont1:
        "Welcome to ELV Health Care Polyclinics, where we specialize in cutting-edge anti-aging solutions tailored to rejuvenate and enhance your natural beauty. Our clinic is dedicated to providing personalized treatments that address the diverse aspects of aging, helping you look and feel your best.",
      cont2:
        "At ELV, our team of skilled professionals offers a range of anti-aging solutions, including dermal fillers, Botox, and advanced skincare treatments. These non-invasive and minimally invasive procedures are designed to target fine lines, wrinkles, and sagging skin, promoting a more youthful and refreshed appearance.",
      cont3:
        "Explore our website to discover the variety of anti-aging solutions available at ELV Health Care Polyclinics. From personalized consultations to ongoing skincare regimes, our commitment to enhancing your natural beauty is evident in every step of our process. Trust ELV to be your partner in achieving a revitalized and age-defying look that aligns with your unique preferences and goals. Experience the difference at ELV, where anti-aging solutions are not just treatments but a commitment to helping you embrace the beauty of every stage in life.",
    },
    {
      name: "Botox",
      img: "../photo/der5.jpg",
      cont1:
        "Welcome to ELV Health Care Polyclinics, where we specialize in Botox treatments to help you achieve a refreshed and rejuvenated appearance. Our clinic is dedicated to providing personalized Botox solutions that address fine lines, wrinkles, and facial expressions, allowing you to look and feel your best.",
      cont2:
        "At ELV, our team of skilled professionals employs Botox, a minimally invasive and FDA-approved treatment, to temporarily relax facial muscles, smoothing out wrinkles and lines. This procedure is tailored to enhance your natural beauty while maintaining your unique facial expressions.",
      cont3:
        "Explore our website to discover the benefits and details of Botox treatments at ELV Health Care Polyclinics. From the initial consultation to post-treatment care, our commitment to delivering natural and subtle results is evident in every step of our process. Trust ELV to be your partner in achieving a more youthful and radiant appearance. Experience the difference at ELV, where Botox is not just a treatment but a personalized approach to enhancing your confidence and beauty.",
    },
    {
      name: "Fillers",
      img: "../photo/der6.jpg",
      cont1:
        "Welcome to ELV Health Care Polyclinics, where we specialize in rejuvenating and enhancing your natural beauty through advanced dermal filler treatments. Our clinic is dedicated to providing personalized filler solutions that address volume loss, fine lines, and wrinkles, allowing you to achieve a more youthful and refreshed look.",
      cont2:
        "At ELV, our team of skilled professionals utilizes a range of dermal fillers, each tailored to specific areas and concerns. These non-surgical treatments can enhance facial contours, restore lost volume, and smoothen out lines, providing natural and long-lasting results.",
      cont3:
        "Explore our website to discover the variety of filler options available at ELV Health Care Polyclinics. From initial consultations to personalized treatment plans, our commitment to enhancing your natural beauty is evident in every step of our process. Trust ELV to be your partner in achieving a rejuvenated and radiant appearance. Experience the difference at ELV, where fillers are not just treatments but a personalized approach to enhancing your confidence and beauty.",
    },
  ];

  const changer = useContext(data);
  return (
    <>
      <div>
        <img className="w-full mb-4" src="../photo/aa14.jpg" alt="" />
        <h1 style={{ color: "#3d7cc9" }} className="text-lg font-medium mb-4">
          Best Dermatology Services in Whitefield, Bangalore.
        </h1>
        <p className="mb-4">
          Welcome to ELV Health Care Polyclinics, a premier destination for
          comprehensive Dermatology services, where our team of experienced
          dermatologists is dedicated to providing expert care for skin health
          and aesthetic well-being. At ELV, we understand the significance of
          dermatology in maintaining healthy skin, and our Dermatology services
          are designed to address a range of skin conditions and aesthetic
          concerns. Our hospital offers a spectrum of dermatological care, from
          routine skin check-ups to advanced dermatologic treatments.
        </p>
        <p className="mb-4">
          ELV Health Care Polyclinics provides a wide array of Dermatology
          services, including dermatological consultations, skin cancer
          screenings, acne treatments, and cosmetic dermatology procedures. Our
          dermatologists utilize state-of-the-art technology and advanced
          skincare techniques to ensure accurate diagnosis and personalized
          treatment plans. From medical dermatology to aesthetic enhancements,
          ELV is your trusted partner in achieving optimal skin health.
        </p>
        <p className="mb-4">
          Explore our website to discover the comprehensive Dermatology services
          available at ELV Health Care Polyclinics. We prioritize accessibility
          and convenience, offering online appointment scheduling and
          tele-dermatology options for dermatology consultations. ELV is
          committed to providing a patient-centered approach, ensuring that each
          individual receives personalized care that addresses their specific
          dermatological needs and concerns.
        </p>
        <p className="mb-4">
          ELV Health Care Polyclinics takes pride in offering specialized
          Dermatology subspecialty services, including pediatric dermatology,
          laser dermatology, and dermatologic surgery. Our multidisciplinary
          approach allows us to provide tailored care for patients of all ages,
          addressing a wide range of dermatological conditions. Our
          collaborative team of dermatologists works together to deliver
          comprehensive and integrated healthcare solutions for individuals with
          diverse skin challenges.
        </p>
        <p className="mb-4">
          Experience the difference at ELV Health Care Polyclinics, where our
          commitment to excellence in Dermatology services is evident in the
          quality of our care, the expertise of our dermatologists, and the
          positive impact we strive to make on the skin health and well-being of
          our community. Whether you require routine dermatological check-ups or
          specialized skin treatments, ELV is your preferred destination for
          dermatology care, where our focus on expertise and compassionate
          service sets us apart as a leader in Dermatology services.
        </p>
      </div>
      <div className="w-full grid grid-cols-1 md:grid-cols-1 gap-3">
        {blo.map((elem: any, num: number) => {
          return (
            <>
              <div>
                <div className="w-full h-full grid grid-cols-1 md:grid-cols-3 rounded-xl md:gap-0 p-2">
                  <div style={{ aspectRatio: 3 / 2 }} className="py-0 w-full">
                    <div
                      style={{ backgroundImage: `url(${elem.img})` }}
                      className="w-full h-full mainbanner rounded-md"
                    ></div>
                  </div>
                  <div className=" col-span-2 mt-4 md:mt-0 py-0 md:px-3">
                    <h5 className="text-lg font-medium mb-1 ">{elem.name}</h5>
                    <p className="mt-0 line-clamp-4 mb-2 ">{elem.cont1}</p>
                    <button
                      onClick={() => {
                        changer.setTopser(elem);
                      }}
                      style={{ color: "#3d7cc9" }}
                      className="font-medium text-sm"
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}

export default Dermatologycon;
