import React from "react";
import { FaHospital, FaUserDoctor } from "react-icons/fa6";
import { GiHospitalCross } from "react-icons/gi";
import { ImTrophy } from "react-icons/im";

function Infobox() {
  const menu = [
    {
      name: "Happy Patients",
      num: "18k+",
      icon: <GiHospitalCross className="text-5xl font-bold" />,
    },
    {
      name: "OPD Departments",
      num: "14+",
      icon: <FaHospital className="text-5xl font-bold" />,
    },
    {
      name: "Experienced Doctors",
      num: "25+",
      icon: <FaUserDoctor className="text-5xl font-bold" />,
    },
    {
      name: "Awards",
      num: "6+",
      icon: <ImTrophy className="text-5xl font-bold" />,
    },
  ];
  return (
    <>
      <section className="flex justify-center pt-3 pb-4">
        <div className="containerbox">
          <div className="w-full pt-4 flex flex-wrap">
            {menu.map((elem) => {
              return (
                <>
                  <div className="w-full sm:w-1/2 lg:w-1/4 p-2">
                    <div
                      // style={{
                      //   boxShadow:
                      //     "rgba(50, 50, 93, 0.1) 0px 50px 100px -20px, rgba(0, 0, 0, 0.1) 0px 30px 60px -30px, rgba(10, 37, 64, 0.1) 0px -2px 6px 0px inset",
                      // }}
                      style={{
                        border: "1.5px solid #3d7cc9",
                      }}
                      className="flex justify-between bg-white shadow-sm rounded-md p-3 relative items-center border"
                    >
                      <div>
                        <h4 className="text-3xl font-medium">{elem.num}</h4>
                        <h6 style={{ color: "#3d7cc9" }} className="font-bold">
                          {elem.name}
                        </h6>
                      </div>
                      <div style={{ color: "#48b95f" }}>{elem.icon}</div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

export default Infobox;
