import React, { useContext } from "react";
import { AiFillInstagram } from "react-icons/ai";
import { FaGoogle, FaLocationDot, FaYoutube } from "react-icons/fa6";
import { IoMdCall } from "react-icons/io";
import { MdCancel, MdOutlineRestorePage } from "react-icons/md";
import { Link } from "react-router-dom";
import { data } from "../../App";

function Fourmenu() {
  const changer = useContext(data);
  return (
    <div
      style={{ transition: "0.4s" }}
      className={`fixed top-0 z-50 ${
        changer.menufour ? "w-full" : "w-0"
      } h-full scroll-container overflow-y-scroll scrollbar-hidden`}
    >
      <div
        style={{ backgroundColor: "#fafafa" }}
        className="w-56 h-full border-r-2"
      >
        <div className="flex justify-center pt-5">
          <img className="w-44" src="../photo/logo.png" alt="" />
        </div>
        <div style={{ backgroundColor: "#fafafa" }} className="w-full">
          <div className="pt-5 px-3 w-full">
            <h5 style={{ color: "#3d7cc9" }} className="text-lg font-medium">
              Diagnostic Services
            </h5>
            <Link
              onClick={() => {
                changer.setMenufirst(false);
                changer.setMenufour(false);
              }}
              to={"/bestultrascoundservicesinwhitefield"}
              className="px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 relative flex "
            >
              <MdOutlineRestorePage className="text-xl mr-1" />
              Ultrascound
            </Link>
            <Link
              onClick={() => {
                changer.setMenufirst(false);
                changer.setMenufour(false);
              }}
              to={"/bestechoservicesinwhitefield"}
              className=" flex  px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 relative"
            >
              <MdOutlineRestorePage className="text-xl mr-1" />
              ECHO
            </Link>
            <Link
              onClick={() => {
                changer.setMenufirst(false);
                changer.setMenufour(false);
              }}
              to={"/bestpregnancyscansinwhitefield"}
              className=" flex  px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 relative"
            >
              <MdOutlineRestorePage className="text-xl mr-1" />
              Pregnancy scans
            </Link>
            <Link
              onClick={() => {
                changer.setMenufirst(false);
                changer.setMenufour(false);
              }}
              to={"/bestxrayinwhitefield"}
              className="flex  px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 relative"
            >
              <MdOutlineRestorePage className="text-xl mr-1" />X ray
            </Link>
            <Link
              onClick={() => {
                changer.setMenufirst(false);
                changer.setMenufour(false);
              }}
              to={"/bestbloodtestinwhitefield"}
              className=" flex  px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 relative"
            >
              <MdOutlineRestorePage className="text-xl mr-1" />
              Blood test
            </Link>
            <Link
              onClick={() => {
                changer.setMenufirst(false);
                changer.setMenufour(false);
              }}
              to={"/besthomebloodservicesinwhitefield"}
              className=" flex  px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 relative"
            >
              <div>
                <MdOutlineRestorePage className="text-xl mr-1" />
              </div>
              Home blood sample collection
            </Link>
          </div>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="py-3 px-3 w-full"
          >
            <h5 style={{ color: "#3d7cc9" }} className="text-lg font-medium">
              Contact Info
            </h5>
            <p>
              ELV Health Care Polyclinic, MR ASTER, Ground Floor Vijayanagar,
              Whitefield, Bengaluru, Karnataka 560066
            </p>
            <a
              href="tel:095352 64855"
              className=" flex pt-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 relative"
            >
              <IoMdCall className="text-xl mr-1" />
              095352 64855
            </a>
            <a
              href="tel:095352 64855"
              className=" flex pt-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 relative"
            >
              <IoMdCall className="text-xl mr-1" />
              9535264855
            </a>
          </div>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="w-full flex justify-center pb-5"
          >
            <div className="flex">
              <FaYoutube className="text-2xl p-1" />
              <FaGoogle className="text-2xl p-1" />
              <FaLocationDot className="text-2xl p-1" />
              <AiFillInstagram className="text-2xl p-1" />
            </div>
          </div>
        </div>
      </div>
      <MdCancel
        onClick={() => {
          changer.setMenufour(false);
        }}
        style={{ color: "#3d7cc9" }}
        className="text-3xl absolute z-40 top-2 right-2"
      />
    </div>
  );
}

export default Fourmenu;
