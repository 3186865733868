import React from "react";

function Aboutcon() {
  return (
    <>
      <div>
        <img className="w-full mb-4" src="../photo/hos3.jpg" alt="" />
        <div className="mb-4">
          <h6 style={{ color: "#3d7cc9" }} className="text-lg font-medium mb-1">
            Location and Accessibility:
          </h6>
          <ul className="list-disc pl-5">
            <li className="mb-2">
              <p>
                ELV Health Care Polyclinics are situated in Bengaluru,
                specifically at MR ASTER, Ground Floor Vijayanagar, Whitefield,
                Karnataka 560066.
              </p>
            </li>
            <li>
              <p>
                The location is strategically chosen to ensure accessibility for
                residents in the heart of Bengaluru.
              </p>
            </li>
          </ul>
        </div>
        <div className="mb-4">
          <h6 style={{ color: "#3d7cc9" }} className="text-lg font-medium mb-1">
            Comprehensive Healthcare Services:
          </h6>
          <ul className="list-disc pl-5">
            <li className="mb-2">
              <p>
                ELV Health Care Polyclinics serve as pillars of comprehensive
                healthcare services, catering to a diverse range of medical
                needs.
              </p>
            </li>
            <li>
              <p>
                They offer a spectrum of medical offerings, ranging from a
                state-of-the-art day care center and minor OT facilities to a
                fully stocked pharmacy and primary care services.
              </p>
            </li>
          </ul>
        </div>
        <div className="mb-4">
          <h6 style={{ color: "#3d7cc9" }} className="text-lg font-medium mb-1">
            Specialized Departments:
          </h6>
          <ul className="list-disc pl-5">
            <li className="mb-2">
              <p>
                Distinguished by specialized departments including Internal
                Medicine, Orthopedics, Cardiology, and more.
              </p>
            </li>
            <li>
              <p>
                Each department houses a team of dedicated professionals
                committed to delivering top-notch healthcare.
              </p>
            </li>
          </ul>
        </div>
        <div className="mb-4">
          <h6 style={{ color: "#3d7cc9" }} className="text-lg font-medium mb-1">
            Cutting-edge Diagnostic Services:
          </h6>
          <ul className="list-disc pl-5">
            <li className="mb-2">
              <p>
                ELV Health Care Polyclinics provide cutting-edge diagnostic
                services, including ultrasonography, ECHO, X-rays, and blood
                tests.
              </p>
            </li>
            <li>
              <p>
                This emphasis on advanced diagnostics ensures accurate and
                timely medical assessments.
              </p>
            </li>
          </ul>
        </div>
        <div className="mb-4">
          <h6 style={{ color: "#3d7cc9" }} className="text-lg font-medium mb-1">
            Patient Convenience:
          </h6>
          <ul className="list-disc pl-5">
            <li className="mb-2">
              <p>
                Noteworthy is their emphasis on patient convenience, exemplified
                by home blood sample collection services.
              </p>
            </li>
            <li>
              <p>
                ELV Health Care Polyclinics aim to seamlessly integrate
                healthcare into individuals' lives, recognizing it as not just a
                necessity but an essential part of one's life.
              </p>
            </li>
          </ul>
        </div>
        <div className="mb-4">
          <h6 style={{ color: "#3d7cc9" }} className="text-lg font-medium mb-1">
            One-stop Healthcare Solution:
          </h6>
          <ul className="list-disc pl-5">
            <li className="mb-0">
              <p>
                ELV Health Care strives to be a one-stop solution for various
                medical requirements, consolidating services to meet the diverse
                needs of their patients.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Aboutcon;
