import React from "react";
import { FaLocationCrosshairs, FaUser } from "react-icons/fa6";
import { LuPhoneCall } from "react-icons/lu";

function Contcon() {
  return (
    <>
      <div className="flex justify-center py-16">
        <div className="containerbox">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div className="">
              <h1
                style={{ color: "#3d7cc9" }}
                className="text-2xl font-bold leading-snug mb-3"
              >
                ELV Health Care Polyclinics
              </h1>
              <p className="font-medium mb-3">
                ELV Health Care Poly Clinics stand as pillars of comprehensive
                and accessible healthcare services, fostering a haven of
                well-being in the heart of Bengaluru. With a spectrum of medical
                offerings, these clinics cater to a diverse range of needs,
                ensuring that every individual receives the attention and care
                they deserve. From a state-of-the-art day care center and minor
                OT facilities to a fully stocked pharmacy and primary care
                services, ELV Health Care strives to be a one-stop solution for
                various medical requirements.
              </p>
              <h1
                style={{ color: "#3d7cc9" }}
                className="text-lg font-bold leading-snug mb-3"
              >
                Contct Info
              </h1>
              <div className="flex items-center mb-3">
                <div className="p-2.5 border-2 border-gray-700 rounded-full w-10 h-10 flex justify-center items-center">
                  <LuPhoneCall className="text-3xl" />
                </div>
                <div>
                  <p
                    style={{ color: "#3d7cc9" }}
                    className="text-lg font-bold leading-snug ml-2"
                  >
                    95352 64855
                  </p>
                </div>
              </div>
              <div className="flex items-center mb-3">
                <div className="p-2.5 border-2 border-gray-700 rounded-full w-10 h-10 flex justify-center items-center">
                  <FaUser className="text-3xl" />
                </div>
                <div>
                  <p
                    style={{ color: "#3d7cc9" }}
                    className="text-lg font-bold leading-snug ml-2"
                  >
                    95352 64854
                  </p>
                </div>
              </div>
              <div className="flex mb-3">
                <div className="p-2.5 border-2 border-gray-700 rounded-full w-10 h-10 flex justify-center items-center">
                  <FaLocationCrosshairs className="text-3xl" />
                </div>
                <div>
                  <p className="font-bold leading-snug ml-2">
                    ELV Health Care Polyclinic, MR ASTER, Ground Floor
                    Vijayanagar, Whitefield, Bengaluru, Karnataka 560066
                  </p>
                </div>
              </div>
              <div className="mt-6">
                <div
                  style={{
                    backgroundColor: "#eff6ff",
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                  }}
                  className="grid grid-cols-1 gap-4 sm:gap-2 sm:grid-cols-3 p-5 rounded"
                >
                  <div>
                    <div>
                      <h1 className="text-xl font-medium text-center">18k+</h1>
                      <h6 className="text-center">Happy Patients</h6>
                    </div>
                  </div>
                  <div>
                    <div>
                      <h1 className="text-xl font-medium text-center">14+</h1>
                      <h6 className="text-center">OPD Departments</h6>
                    </div>
                  </div>
                  <div>
                    <div>
                      <h1 className="text-xl font-medium text-center">25+</h1>
                      <h6 className="text-center">Experienced Doctors</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.9282359572185!2d77.75578167491842!3d12.976441787339382!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae11b060be9779%3A0x18df43749fa8a121!2sELV%20Health%20Care%20Polyclinic!5e0!3m2!1sen!2sin!4v1702573937040!5m2!1sen!2sin"
                style={{ width: "100%" }}
                className="h-96 lg:h-full"
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contcon;
