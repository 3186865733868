"use client";
import { useState } from "react";
import ButtonQna from "../Home/ButtonQna";
export default function Qns() {
  const [number, setNumber] = useState<number>(0);
  const menu = [
    {
      ques: "What services does ELV Health Care Poly Clinics provide?",
      ans: (
        <p className="mt-3 mb-5">
          ELV Health Care Poly Clinics pride themselves on offering a
          comprehensive array of medical services to cater to the diverse
          healthcare needs of their patients. The clinic provides a nurturing
          environment equipped with a day care center, minor OT facilities, and
          a fully stocked pharmacy, ensuring convenient access to essential
          medications. With a focus on primary care, ELV Health Care Poly
          Clinics deliver a range of services aimed at promoting overall
          well-being. The clinics commitment extends to various medical
          specialties, including Internal Medicine, Diabetes, Dental care,
          Orthopedic, General surgery, Pediatrics, ENT, Physiotherapy, Urology,
          Neurology, Psychiatry, Obstetrics and Gynecology, Cardiology, and
          Dermatology. Whether its routine check-ups, specialized consultations,
          or emergency medical assistance, ELV Health Care Poly Clinics stand as
          a reliable healthcare hub, dedicated to providing quality and
          compassionate care to their community.
        </p>
      ),
    },
    {
      ques: "What are the main departments at ELV Health Care Poly Clinics?",
      ans: (
        <p className="mt-3 mb-4">
          ELV Health Care Poly Clinics boast a diverse range of medical
          departments, each committed to delivering specialized care in its
          respective field. Internal Medicine stands as a cornerstone
          department, addressing a spectrum of adult health concerns and
          providing comprehensive diagnostic and treatment solutions. The
          Diabetes department focuses on managing and preventing
          diabetes-related issues, employing cutting-edge approaches to enhance
          patients quality of life. The Dental Care unit ensures optimal oral
          health through a variety of services, promoting overall well-being.
          <br></br>Orthopedics specializes in musculoskeletal health, offering
          advanced treatments for bone and joint conditions. The General Surgery
          department is dedicated to surgical interventions, encompassing a wide
          range of procedures to address various medical conditions. Pediatrics
          caters to the unique healthcare needs of children, emphasizing
          preventive care and early intervention. The ENT department addresses
          disorders related to the ear, nose, and throat, while Physiotherapy
          focuses on rehabilitation and physical well-being. Specialized
          departments such as Urology, Neurology, Psychiatry, Obstetrics and
          Gynecology, Cardiology, and Dermatology contribute to the clinics
          holistic approach, ensuring comprehensive healthcare coverage for
          diverse medical concerns. ELV Health Care Poly Clinics stand as a
          multidisciplinary healthcare facility, providing expert care across a
          spectrum of medical disciplines to meet the varying needs of their
          patients.
        </p>
      ),
    },
    {
      ques: "Do they provide specialized clinics?",
      ans: (
        <p className="mt-3 mb-4">
          Certainly! ELV Health Care Poly Clinics take pride in offering
          specialized clinics to address specific medical needs with precision
          and expertise. These clinics are tailored to focus on particular
          health conditions, ensuring that patients receive specialized care
          from skilled professionals. Whether its the intricate management of
          diabetes, intricate dental procedures, or the complexities of
          obstetrics and gynecology, ELV Health Care Poly Clinics have dedicated
          clinics staffed with knowledgeable healthcare professionals who are
          well-versed in the intricacies of their respective fields.<br></br>
          These specialized clinics not only provide targeted treatments but
          also offer a holistic approach to patient care. Patients benefit from
          the collective expertise of specialized teams, cutting-edge diagnostic
          tools, and tailored treatment plans. Whether seeking consultation in
          Cardiology for heart-related concerns, Dermatology for skin
          conditions, or Psychiatry for mental health, patients can expect
          personalized attention and comprehensive care. ELV Health Care Poly
          Clinics commitment to specialized clinics reflects their dedication to
          delivering high-quality, patient-centric healthcare across a spectrum
          of medical disciplines.
        </p>
      ),
    },
    {
      ques: "What diagnostic services are available?",
      ans: (
        <p className="mt-3 mb-4">
          ELV Health Care Poly Clinics prioritize accurate diagnosis through a
          comprehensive range of diagnostic services, demonstrating a commitment
          to thorough and precise healthcare. The clinics diagnostic offerings
          encompass advanced medical imaging techniques such as Ultrascound,
          ECHO, and X-ray, providing detailed insights into various
          physiological aspects. These imaging services play a crucial role in
          the diagnosis and monitoring of conditions affecting organs, tissues,
          and vital systems within the body.<br></br>In addition to imaging, ELV
          Health Care Poly Clinics facilitate diagnostic processes through
          specialized services like Pregnancy scans and a variety of Blood
          tests. These services contribute to a thorough understanding of a
          patients health status, enabling healthcare professionals to formulate
          tailored treatment plans. Moreover, the clinic goes the extra mile by
          offering the convenience of Home Blood Sample Collection, recognizing
          the importance of accessibility and patient comfort in the diagnostic
          process. ELV Health Care Poly Clinics commitment to providing a
          comprehensive suite of diagnostic services reflects their dedication
          to ensuring accurate and timely assessments for optimal patient care.
        </p>
      ),
    },
    {
      ques: "Can I receive emergency medical services at ELV Health Care Poly Clinics?",
      ans: (
        <p className="mt-3 mb-4">
          Absolutely, ELV Health Care Poly Clinics are well-equipped to provide
          prompt and efficient emergency medical services to address urgent
          healthcare needs. Recognizing the critical nature of emergencies, the
          clinics have dedicated resources and a trained emergency medical team
          ready to respond swiftly. Whether its a sudden illness, injury, or any
          other medical crisis, patients can trust ELV Health Care Poly Clinics
          to deliver immediate and expert care.<br></br>
          The emergency services at ELV Health Care Poly Clinics are designed to
          offer a comprehensive response, including initial assessments,
          stabilization, and appropriate interventions. The clinics are equipped
          with essential emergency medical equipment and have protocols in place
          to ensure a quick and effective response to emergencies. Patients
          seeking emergency medical attention can rely on ELV Health Care Poly
          Clinics for compassionate and timely care, reinforcing the clinics
          commitment to being a reliable healthcare hub for the communitys
          urgent healthcare needs.
        </p>
      ),
    },
    {
      ques: "Which medical specialties are covered at ELV Health Care Poly Clinics?",
      ans: (
        <p className="mt-3 mb-4">
          ELV Health Care Poly Clinics take pride in offering a diverse range of
          medical specialties, ensuring that patients receive comprehensive and
          specialized care for a wide array of health concerns. Within the
          clinic, the Internal Medicine department addresses adult health
          issues, providing thorough diagnostic evaluations and personalized
          treatment plans. The Diabetes department focuses on managing and
          preventing diabetes-related complications, employing advanced
          approaches to enhance patients overall well-being.<br></br>
          The clinic extends its expertise to Dental Care, Orthopedics, General
          Surgery, Pediatrics, ENT, Physiotherapy, Urology, Neurology,
          Psychiatry, Obstetrics and Gynecology, Cardiology, and Dermatology.
          Each department is staffed with skilled healthcare professionals
          dedicated to their specific field, offering patients tailored and
          expert care. Whether its the intricate musculoskeletal interventions
          in Orthopedics, the sensitive care for children in Pediatrics, or the
          specialized treatments in Cardiology or Dermatology, ELV Health Care
          Poly Clinics strive to provide holistic healthcare coverage, meeting
          the diverse medical needs of their community.
        </p>
      ),
    },
  ];
  return (
    <>
      <section
        style={{ backgroundColor: "#fdfffe" }}
        className="flex justify-center py-0"
      >
        <div className="containerbox">
          <div className="flex flex-wrap">
            <div className="w-full px-0">
              <h1 className="text-4xl font-bold leading-snug mb-4">
                Frequently Asked Questions
              </h1>
              {/* <h1
                style={{
                  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                }}
                className="text-3xl font-bold leading-snug mb-4"
              >
                Frequently Asked Questions
              </h1> */}
              {menu.map((elem, num) => {
                return (
                  <>
                    <ButtonQna
                      num={num}
                      number={number}
                      setNumber={setNumber}
                      ques={elem.ques}
                      ans={elem.ans}
                    />
                  </>
                );
              })}
            </div>
            <p>
              Health Care Poly Clinics deliver a range of services aimed at
              promoting overall well-being. The clinics commitment extends to
              various medical specialties, including Internal Medicine,
              Diabetes, Dental care, Orthopedic, General surgery, Pediatrics,
              ENT, Physiotherapy, Urology, Neurology, Psychiatry, Obstetrics and
              Gynecology, Cardiology, and Dermatology. Whether its routine
              check-ups, specialized consultations, or emergency medical
              assistance, ELV Health Care Poly Clinics stand as a reliable
              healthcare hub, dedicated to providing quality and compassionate
              care to their community.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
