import React, { useEffect } from "react";
import Infiry from "../../About/Infiry";
import Callin from "../../About/Callin";
import Servicebanner from "../../Services/Servicebanner";
import NumberInfo from "../../Home/NumberInfo";
import Doctorbox from "../../Home/Docterbox";
import Divser from "../../About/Divser";
import Divider from "../../Divider";
import ServiceReview from "../../Services/ServiceReview";
import Pediatricscon from "./Pediatricscon";
import { movetop } from "../../../fun";

function Pediatrics() {
  type elem = {
    oneBody?: JSX.Element;
    twoBody?: JSX.Element;
    threeBody?: JSX.Element;
  };
  const elemnt: elem = {
    oneBody: <Pediatricscon />,
    twoBody: <Infiry />,
    threeBody: <Callin />,
  };

  useEffect(() => {
    movetop();
  }, []);
  return (
    <>
      <Servicebanner name="Pediatrics" banner={"../photo/dd17.jpg"} />
      <NumberInfo test="test-black" />
      <Doctorbox />
      <Divser elemnt={elemnt} />
      {/* <div className="pt-6">
            <Divider />
          </div>
          <Doctorbox /> */}
      <div className="pt-10 pb-4">
        <Divider />
      </div>
      <ServiceReview />
    </>
  );
}

export default Pediatrics;
