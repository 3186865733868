import React, { useEffect, useState } from "react";
import Infiry from "./Infiry";
import Qns from "../Services/Qns";
import Callin from "./Callin";

function Divser({ elemnt }: { elemnt: any }) {
  const [height, setHeignt] = useState<number>(NaN);
  useEffect(() => {
    const num: any = document.getElementById("second");
    setHeignt(num.clientHeight);
  });

  return (
    <div className="flex justify-center py-6">
      <div className="containerbox">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <div className="lg:col-span-2" id="second">
            {elemnt.oneBody}
          </div>
          <div>
            <div className="sticky top-32">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-4">
                <div>{elemnt.twoBody}</div>
                <div>{elemnt.threeBody}</div>
                <div>{elemnt.fourBody}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Divser;
