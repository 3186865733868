import React, { useEffect } from "react";
import Infobox from "../About/Infobox";
import Aboutbanner from "../About/Aboutbanner";
import Callin from "../About/Callin";
import Infiry from "../About/Infiry";
import Divser from "../About/Divser";
import ServiceReview from "../Services/ServiceReview";
import Blogcon from "../Blog/Blogcon";
import Doctorcon from "./Doctorcon";
import { movetop } from "../../fun";
import ServiceList from "../Services/ServiceList";
import Doctorboxtwo from "./Doctorboxtwo";
import Doctorconnew from "./Doctorconnew";


function Doctorpage({doctor,banner}:{doctor:any,banner:any}) {
    const height: number = NaN;
    type elem = {
      oneBody?: JSX.Element;
      twoBody?: JSX.Element;
      threeBody?: JSX.Element;
      // fourBody?: JSX.Element;
    };
    const elemnt: elem = {
      oneBody: <Doctorconnew doctor={doctor}/>,
      twoBody: <Infiry />,
      threeBody: <Callin />,
      // fourBody:<Doctorboxtwo/>
    };
  
    useEffect(() => {
      movetop();
    }, []);
  return (
    <>
    <Aboutbanner name={doctor} banner={banner} />
    {/* <ServiceList /> */}
    {/* <div style={{ backgroundColor: "#eff6ff" }} className="pb-2">
      <Infobox />
    </div> */}
    <div className="pt-2 pb-6"></div>
    <Divser elemnt={elemnt} />
    <ServiceReview />
    </>
  );
}

export default Doctorpage;
