import React from "react";
import { MdOutlineWifiCalling3 } from "react-icons/md";

function Contactbynum({ color }: { color: string }) {
  return (
    <section className="flex justify-center">
      <div
        style={{
          backgroundColor: color,
          boxShadow:
            color === "#0f172a"
              ? "0px 0px 0px white"
              : "rgba(0, 0, 0, 0.1) 0px 4px 12px",
        }}
        className="containerbox py-12 w-full flex flex-wrap justify-center"
      >
        <div className="flex pt-12 lg:p-0 justify-center md:w-2/3 lg:w-1/5 items-center">
          <img
            className="w-60 mb-0"
            src="../photo/concept-online-consultation-doctor-online-medicine-healthcare-medical-diagnostics_365941-619-removebg-preview.png"
            alt=""
          />
        </div>
        <div className="pb-12 px-5 md:px-0 pt-6 lg:py-12 w-full md:w-3/5">
          <h1
            className={`text-2xl font-bold leading-snug text-center mt-3 ${
              color === "#0f172a" ? "text-white" : "text-black"
            }`}
          >
            Contact via Phone Call.
          </h1>
          <p
            className={`text-center ${
              color === "#0f172a" ? "text-white" : "text-black"
            }`}
          >
            Feel free to reach out to us with any questions or specific needs
            you may have. Our team is eager to assist you promptly and ensure
            you receive the support and information you require.
          </p>
          <div className="flex flex-wrap justify-center my-2">
            <a
              href="tel:9535264855"
              style={{ backgroundColor: "#48b95f" }}
              className="border-inherit border p-2 rounded-md mt-1 text-white flex items-center mr-2"
            >
              <MdOutlineWifiCalling3 className="mr-1 text-xl" />
              Book Appointment
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contactbynum;
