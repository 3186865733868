"use client";
import { useEffect, useState } from "react";
import { MdAddComment } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
export default function ButtonQna({
  num,
  number,
  setNumber,
  ques,
  ans,
}: {
  num: number;
  number: number;
  setNumber: any;
  ques: any;
  ans: any;
}) {
  const [dis, setDis] = useState<string>("none");
  useEffect(() => {
    setDis("none");
    if (num === number) {
      setDis("block");
    }
  }, [num, number]);
  return (
    <>
      <div className="w-full mt-2 mb-6 border-b-2">
        <button
          onClick={() => {
            setNumber(num);
          }}
          className="w-full my-2 flex items-center justify-between text-left  pb-4"
        >
          <h6 className="font-medium text-lg">{ques}</h6>
          <div className="flex items-center ml-1">
            <FaPlus style={{ color: "#48b95f" }} className="text-xl" />
          </div>
        </button>
        <div className="text-sm mb-10" style={{ display: `${dis}` }}>
          {ans}
        </div>
      </div>
    </>
  );
}
