"use client";
import React from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function ModileSlide() {
  const settings: Settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  const menu = [
    ["../photo/24x7.jpg", "../photo/4ert.jpg"],
    ["../photo/24x7-PHARMECY.jpg", "../photo/5errt.jpg"],
    ["../photo/HOSPITAL.jpg", "../photo/rt1.jpg"],
    ["../photo/gynocology.jpg", "../photo/2er.jpg"],
    ["../photo/orthopedic.jpg", "../photo/3ert.jpg"],
  ];
  return (
    <>
      <div className="w-full h-full overflow-hidden">
        <Slider {...settings}>
          {menu.map((elem) => {
            return (
              <>
                <div className="hidden sm:block">
                  <a className="w-full" href="tel:9535264855">
                    <img className="w-full " src={elem[0]} alt="" />
                  </a>
                </div>
                <div className="block sm:hidden">
                  <a className="w-full" href="tel:9535264855">
                    <img className="w-full " src={elem[1]} alt="" />
                  </a>
                </div>
              </>
            );
          })}
        </Slider>
      </div>
    </>
  );
}
