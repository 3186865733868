import logo from "./logo.svg";
import "./App.css";
import FixHeader from "./components/Home/FixHeader";
import Header from "./components/header";
import Footer from "./components/Footer";
import SliderInfo from "./components/Home/SliderInfo";
import ModileSlide from "./components/Home/ModileSlide";
import DirectContact from "./components/Home/DirectContact";
import Infobox from "./components/Home/Infobox";
import Specilities from "./components/Home/Specilities";
import Doctorbox from "./components/Home/Docterbox";
import NumberInfo from "./components/Home/NumberInfo";
import ThreeBoxin from "./components/Home/ThreeBoxin";
import HealthTip from "./components/Home/HealthTip";
import BlogHome from "./components/Home/BlogHome";
import Prequentquation from "./components/Home/Prequentquation";
import Servicebanner from "./components/Services/Servicebanner";
import Serviceinfo from "./components/Services/Serviceinfo";
import Wecare from "./components/Home/Wecare";
import Divider from "./components/Divider";
import ServiceReview from "./components/Services/ServiceReview";
import ServiceList from "./components/Services/ServiceList";
import Callsection from "./components/Home/Callsection";
import Conatinfobox from "./components/Services/Conatinfobox";
import Qns from "./components/Services/Qns";
import Youtube from "./components/Services/Youtube";
import Whychoose from "./components/Home/Whychoose";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Home";
import About from "./components/About";
import Orthopedic from "./components/Servicepage/Orthopedic/Orthopedic";
import Generalsurgery from "./components/Servicepage/Generalsurgery/Generalsurgery";
import Pediatrics from "./components/Servicepage/Pediatrics/Pediatrics";
import Ent from "./components/Servicepage/ent/ent";
import Physiotherapy from "./components/Servicepage/Physiotherapy/Physiotherapy";
import Urology from "./components/Servicepage/Urology/Urology";
import Neurology from "./components/Servicepage/Neurology/Neurology";
import Psychiatry from "./components/Servicepage/Psychiatry/Psychiatry";
import Obg from "./components/Servicepage/Obg/Obg";
import Cardiology from "./components/Servicepage/Cardiology/Cardiology";
import Dermatology from "./components/Servicepage/Dermatology/Dermatology";
import Daycare from "./components/Servicepage/Daycare/Daycare";
import Minor from "./components/Servicepage/Minor/Minor";
import Pharmacy from "./components/Servicepage/Pharmacy/Pharmacy";
import Primarycare from "./components/Servicepage/Primarycare/Primarycare";
import Ultrascoundcon from "./components/Servicepage/Ultrascound/Ultrascoundcon";
import ECHO from "./components/Servicepage/ECHO/ECHO";
import Pregnancyscans from "./components/Servicepage/Pregnancyscans/Pregnancyscans";
import Xray from "./components/Servicepage/Xray/Xray";
import Bloodtest from "./components/Servicepage/Bloodtest/Bloodtest";
import Bloodtestcon from "./components/Servicepage/Bloodtest/Bloodtestcon";
import Homeblood from "./components/Servicepage/Homeblood/Homeblood";
import Specialize from "./components/Servicepage/SpecializedClinics/Specialize";
import EmergencyServicescon from "./components/Servicepage/mergencyServices/EmergencyServicescon";
import EmergencyServices from "./components/Servicepage/mergencyServices/EmergencyServices";
import Blog from "./components/Blog/Blog";
import Firstmenu from "./components/Mobile/Firstmenu";
import { createContext, useEffect, useState } from "react";
import Secondmenu from "./components/Mobile/Secondmenu";
import Threemenu from "./components/Mobile/Threemenu";
import Fourmenu from "./components/Mobile/Fourmenu";
import Doctor from "./components/Doctor/Doctor";
import Contact from "./components/Contact/Contact";
import Contactbutton from "./components/Mobile/Contactbutton";
import Topservices from "./components/Services/Topservices";
import Diser from "./components/Servicepage/Diabetes/Diser";
import Dentalser from "./components/Servicepage/Dentalcare/Dentalser";
import INser from "./components/Servicepage/InternalMedicine/INser";
import Cosmetic from "./components/Servicepage/cosmetictreatments/Cosmetic";
import Doctorpage from "./components/Doctor/Doctorpage";

const data = createContext<any>(undefined);
function App() {
  const [menufirst, setMenufirst] = useState<boolean>(false);
  const [menusecond, setMenusecond] = useState<boolean>(false);
  const [menuThree, setMenuThree] = useState<boolean>(false);
  const [menufour, setMenufour] = useState<boolean>(false);
  const [topser, setTopser] = useState<any | undefined>(undefined);
  const menus = {
    menufirst: menufirst,
    setMenufirst: setMenufirst,
    menusecond: menusecond,
    setMenusecond: setMenusecond,
    menuThree: menuThree,
    setMenuThree: setMenuThree,
    menufour: menufour,
    setMenufour: setMenufour,
    topser: topser,
    setTopser: setTopser,
  };
  useEffect(() => {
    const event = () => {
      setMenufirst(false);
      setMenusecond(false);
      setMenuThree(false);
      setMenufour(false);
    };
    window.addEventListener("resize", event);
    return () => {
      window.removeEventListener("resize", event);
    };
  }, []);
  return (
    <>
      <data.Provider value={menus}>
        <BrowserRouter>
          {/* <div className="overflow-hidden"> */}
          <Topservices />
          <FixHeader />
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route
              path="/bestinternalmedicineinwhitefield"
              element={<INser />}
            />
            <Route path="/bestdiabetesinwhitefield" element={<Diser />} />
            <Route path="/bestdentalinwhitefield" element={<Dentalser />} />
            <Route
              path="/bestorthopedicservicesinwhitefield"
              element={<Orthopedic />}
            />

            <Route
              path="/bestobstetricsandgynecologytreatmentsinwhitefield-by-dr-d-vijaya-meenakshi"
              element={<Doctorpage banner={"../photo/sam1.jpg"} doctor={"Dr. D Vijaya Meenakshi"}/>}
            />
            <Route
              path="/bestorthopedictreatmentsinwhitefield-by-dr-ajay-s-s"
              element={<Doctorpage banner={"../photo/sam1.jpg"} doctor={"Dr. Ajay S S"}/>}
            />

            <Route
              path="/bestneurologytreatmentsinwhitefield-by-dr-ns-santhosh"
              element={<Doctorpage banner={"../photo/sam1.jpg"} doctor={"Dr. N.S Santhosh"} />}
            />
            <Route
              path="/bestdermatologytreatmentsinwhitefield-by-dr-lipika-debbarma"
              element={<Doctorpage banner={"../photo/sam1.jpg"} doctor={"Dr. Lipika Debbarma"} />}
            />
            <Route
              path="/bestenttreatmentsinwhitefield-by-dr-razia-sultana"
              element={<Doctorpage banner={"../photo/sam1.jpg"} doctor={"Dr. Razia Sultana"} />}
            />
            <Route
              path="/bestcardiologytreatmentsinwhitefield-by-dr-anirban-bhowmik"
              element={<Doctorpage banner={"../photo/sam1.jpg"} doctor={"Dr. Anirban Bhowmik"} />}
            />
            {/* <Route
              path="/besturologytreatmentsinwhitefield-by-dr-lokesh-gowda-t-g"
              element={<Doctorpage banner={"../photo/sam1.jpg"} doctor={"Dr. Lokesh Gowda T G"} />}
            /> */}
            {/* <Route
              path="/bestdietitianandnutritionisttreatmentsinwhitefield-by-ms-jeeveetha-ragupathy"
              element={<Doctorpage banner={"../photo/sam1.jpg"} doctor={"Ms. Jeeveetha Ragupathy"} />}
            /> */}
            <Route
              path="/bestdietitianandnutritionisttreatmentsinwhitefield-by-mr-naveen-kodandaram"
              element={<Doctorpage banner={"../photo/sam1.jpg"} doctor={"Mr. Naveen Kodandaram"} />}
            />
            <Route
              path="/bestphysiotherapyintreatmentsinwhitefield-by-dr-binila"
              element={<Doctorpage banner={"../photo/sam1.jpg"} doctor={"Dr. Binila"} />}
            />
            {/* <Route
              path="/bestnephrologytreatmentsinwhitefield-by-dr-vishnuvardhan-br"
              element={<Doctorpage banner={"../photo/sam1.jpg"} doctor={"Dr - vishnuvardhan BR"} />}
            /> */}
            <Route
              path="/bestgynecologytreatmentsinwhitefield-by-dr-shalini-kumari"
              element={<Doctorpage banner={"../photo/sam1.jpg"} doctor={"Dr. Shalini Kumari"} />}
            />

            <Route
              path="/bestgeneralsurgeryinwhitefield"
              element={<Generalsurgery />}
            />
            <Route
              path="/bestpediatricsservicesinwhitefield"
              element={<Pediatrics />}
            />
            <Route path="/bestentinwhitefield" element={<Ent />} />
            <Route
              path="/bestphysiotherapyservicesinwhitefield"
              element={<Physiotherapy />}
            />
            <Route
              path="/besturologyservicesinwhitefield"
              element={<Urology />}
            />
            <Route
              path="/bestneurologyservicesinwhitefield"
              element={<Neurology />}
            />
            <Route
              path="/bestpsychiatryservicesinwhitefield"
              element={<Psychiatry />}
            />
            <Route path="/bestobgservicesinwhitefield" element={<Obg />} />
            <Route
              path="/bestcardiologyservicesinwhitefield"
              element={<Cardiology />}
            />
            <Route
              path="/bestdermatologyservicesinwhitefield"
              element={<Dermatology />}
            />
            <Route
              path="/bestdaycareservicesinwhitefield"
              element={<Daycare />}
            />
            <Route
              path="/bestminorotservicesinwhitefield"
              element={<Minor />}
            />
            <Route
              path="/bestpharmacyservicesinwhitefield"
              element={<Pharmacy />}
            />
            <Route
              path="/bestprimarycareservicesinwhitefield"
              element={<Primarycare />}
            />
            <Route
              path="/bestultrascoundservicesinwhitefield"
              element={<Ultrascoundcon />}
            />
            <Route path="/bestechoservicesinwhitefield" element={<ECHO />} />
            <Route
              path="/bestpregnancyscansinwhitefield"
              element={<Pregnancyscans />}
            />
            <Route path="/bestxrayinwhitefield" element={<Xray />} />
            <Route
              path="/bestbloodtestinwhitefield"
              element={<Bloodtestcon />}
            />
            <Route
              path="/besthomebloodservicesinwhitefield"
              element={<Homeblood />}
            />
            <Route
              path="/bestspecilitiesinwhitefield"
              element={<Specialize />}
            />
            <Route
              path="/bestemergencyservicesinwhitefield"
              element={<EmergencyServices />}
            />
            <Route path="/blog" element={<Blog />} />
            <Route path="/bestdoctor" element={<Doctor />} />
            <Route path="/contact" element={<Contact />} />
            <Route
              path="/bestcosmetictreatmentsinwhitefield"
              element={<Cosmetic />}
            />
            
          </Routes>
          <Footer />
          <Firstmenu />
          <Secondmenu />
          <Threemenu />
          <Fourmenu />
          <Contactbutton />
          {/* </div> */}
        </BrowserRouter>
      </data.Provider>
    </>
  );
}

export default App;
export { data };
