import React from "react";
import ServiceReview from "../Services/ServiceReview";
import Divser from "../About/Divser";
import Infobox from "../Home/Infobox";
import Aboutbanner from "../About/Aboutbanner";
import Callin from "../About/Callin";
import Infiry from "../About/Infiry";
import Doctorcon from "../Doctor/Doctorcon";
import Contcon from "./Contcon";

function Contact() {
  const height: number = NaN;
  type elem = {
    oneBody?: JSX.Element;
    twoBody?: JSX.Element;
    threeBody?: JSX.Element;
  };
  const elemnt: elem = {
    oneBody: <Doctorcon />,
    twoBody: <Infiry />,
    threeBody: <Callin />,
  };
  return (
    <>
      <Aboutbanner name="Contact Us" banner={"../photo/sam4.jpg"} />
      <Contcon />
    </>
  );
}

export default Contact;
