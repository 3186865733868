import React, { useEffect } from "react";
import Infobox from "../About/Infobox";
import Aboutbanner from "../About/Aboutbanner";
import Callin from "../About/Callin";
import Infiry from "../About/Infiry";
import Divser from "../About/Divser";
import ServiceReview from "../Services/ServiceReview";
import Blogcon from "../Blog/Blogcon";
import Doctorcon from "./Doctorcon";
import { movetop } from "../../fun";

function Doctor() {
  const height: number = NaN;
  type elem = {
    oneBody?: JSX.Element;
    twoBody?: JSX.Element;
    threeBody?: JSX.Element;
  };
  const elemnt: elem = {
    oneBody: <Doctorcon />,
    twoBody: <Infiry />,
    threeBody: <Callin />,
  };

  useEffect(() => {
    movetop();
  }, []);
  return (
    <>
      <Aboutbanner name="Doctor" banner={"../photo/sam1.jpg"} />
      <div style={{ backgroundColor: "#eff6ff" }} className="pb-2">
        <Infobox />
      </div>
      <div className="pt-0 pb-6"></div>
      <Divser elemnt={elemnt} />
      <ServiceReview />
    </>
  );
}

export default Doctor;
