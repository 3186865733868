import { IoLogoWhatsapp } from "react-icons/io";
import { IoCall } from "react-icons/io5";

export default function Callsection() {
  return (
    <>
      <section
        style={{ backgroundColor: "#0f172a" }}
        className="flex justify-center"
      >
        <div className="containerbox relative flex items-center z-20 flex-wrap">
          <div className=" relative sm:absolute flex justify-center w-full pt-14 sm:py-0">
            <div>
              <h1 className="text-5xl text-white font-medium text-center">
                ELV Health Care Polyclinics
              </h1>
              <h4 className="text-2xl text-white font-medium text-center mt-4">
                Contact us for prompt assistance.
              </h4>
              <div className="w-full sm:flex justify-center mt-4 mb-0 sm: my-4">
                <div className=" w-full sm:w-auto flex justify-center">
                  <a
                    href="https://api.whatsapp.com/send?phone=9535264855&text=Hello%20there!"
                    className="bg-black flex p-2 items-center border rounded sm:mr-2 mb-3 sm:mb-0"
                  >
                    <IoLogoWhatsapp
                      className="text-5xl mx-2"
                      style={{ color: "#4ec72e" }}
                    />
                    <div className="mr-2">
                      <h6 className="text-white">Whatsapp</h6>
                      <h5 className="text-1xl font-bold text-white">
                        +91 9535264855
                      </h5>
                    </div>
                  </a>
                </div>
                <div className=" w-full sm:w-auto flex justify-center">
                  <a
                    href="tel:9535264855"
                    className="bg-black flex p-2 items-center border rounded"
                  >
                    <IoCall
                      className="text-5xl mx-2"
                      style={{ color: "#4ec72e" }}
                    />
                    <div className="mr-2">
                      <h6 className="text-white">Phone Call</h6>
                      <h5 className="text-1xl font-bold text-white">
                        +91-953526 4855
                      </h5>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-center sm:justify-between relative pb-6 md:pb-0 pt-0 md:pt-16 -z-20">
            <div className="w-1/2 md:w-2/6 relative hidden sm:block">
              <img
                className="h-64 md:h-64 lg:h-80 w-auto"
                src="../photo/fun-3d-cartoon-illustration-indian-doctor_183364-114361-removebg-preview.png"
                alt=""
              />
            </div>
            <div className="w-full sm:w-1/2 md:w-2/6 flex justify-center sm:justify-end">
              <img
                className="h-64 md:h-64 lg:h-80 w-auto"
                src="../photo/business-contacts-cartoon-style-vector-concept-illustration_92926-1503-removebg-preview.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
