"use client";
import { IoIosStar } from "react-icons/io";
import { MdMedicalServices } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { useEffect, useState } from "react";
import React from "react";
import Slider, { Settings } from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Doctorbox() {
  const [number, setNumber] = useState<number>(NaN);

  const settings: Settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  function handleWindowLoad() {
    if (window.innerWidth > 1023) {
      setNumber(9);
    } else if (window.innerWidth > 767) {
      setNumber(12);
    } else if (window.innerWidth > 639) {
      setNumber(8);
    } else {
      setNumber(6);
    }
  }
  useEffect(() => {
    handleWindowLoad();
    window.addEventListener("resize", handleWindowLoad);
    return () => {
      window.removeEventListener("resize", handleWindowLoad);
    };
  }, []);
  const menu = [
    {
      name: "Dr. D Vijaya Meenakshi",
      dis: (
        <h6 className="pt-2 font-medium">
          MBBS, MD -<br></br> Obstetrics & Gynaecology
        </h6>
      ),
      img: "../photo/doc1.jpg",
      rate: "",
      exper: "20",
      depat: "Obstetrics and Gynecology",
    },
    {
      name: "Dr. Ajay S S",
      dis: (
        <h6 className="pt-2 font-medium">
          MS -<br></br> Orthopaedics, MBBS
        </h6>
      ),
      img: "../photo/doc15.jpg",
      rate: "",
      exper: "6",
      depat: "Orthopedic",
    },
    // {
    //   name: "Dr. SACHIN PATIL ",
    //   dis: (
    //     <h6 className="pt-2 font-medium">
    //       M. S. GENERAL SURGERY<br></br> M.B.B.S. (2009 – 2015)
    //     </h6>
    //   ),
    //   img: "../photo/doc3.jpg",
    //   rate: "",
    //   exper: "5",
    //   depat: "General Surgery",
    // },
    // {
    //   name: "Dr. Sainath T V",
    //   dis: (
    //     <h6 className="pt-2 font-medium">
    //       MBBS, DCH, DNB PED, Fellowship in Neonatology, NNF
    //     </h6>
    //   ),
    //   img: "../photo/doc4.jpg",
    //   rate: "",
    //   exper: "7",
    //   depat: "Neonatologist & Pediatrician",
    // },
    {
      name: "Dr. N.S Santhosh",
      dis: (
        <h6 className="pt-2 font-medium">
          MBBS<br></br> DNB-Neurology
        </h6>
      ),
      img: "../photo/doc5.jpg",
      rate: "",
      exper: "21",
      depat: "Neurologist",
    },

    // {
    //   name: "Dr. Pruthviraj MO",
    //   dis: (
    //     <h6 className="pt-2 font-medium">
    //       MBBS, DNB - OBG<br></br> PGDip-Ultrasonography
    //     </h6>
    //   ),
    //   img: "../photo/doc6.jpg",
    //   rate: "",
    //   exper: "10",
    //   depat: "Obstetrics and Gynecology",
    // },
    {
      name: "Dr. Lipika Debbarma",
      dis: (
        <h6 className="pt-2 font-medium">
          MBBS, DFM(RCGP,UK), <br></br> PGDCD
        </h6>
      ),
      img: "../photo/doc7.jpg",
      rate: "",
      exper: "5",
      depat: "Dermatologist & Cosmetologist",
    },
    {
      name: "Dr. Razia Sultana",
      dis: (
        <h6 className="pt-2 font-medium">
          MBBS, DLO <br></br> (ENT)
        </h6>
      ),
      img: "../photo/doc8.jpg",
      rate: "",
      exper: "5",
      depat: "ENT Speialist",
    },
    // {
    //   name: "Dr. Vijyanta Suman",
    //   dis: (
    //     <h6 className="pt-2 font-medium">
    //       BDS, MDS
    //       <br></br> Prostho dentist
    //     </h6>
    //   ),
    //   img: "../photo/doc9.jpg",
    //   rate: "",
    //   exper: "5",
    //   depat: "Prostho dentist",
    // },
    {
      name: "Dr. Anirban Bhowmik",
      dis: (
        <h6 className="pt-2 font-medium">
          MBBS
          <br></br> (PGDCC), (PGDD)
        </h6>
      ),
      img: "../photo/doc10.jpg",
      rate: "",
      exper: "5",
      depat: "Cardiologist",
    },
    // {
    //   name: "Dr. Lokesh Gowda T G",
    //   dis: (
    //     <h6 className="pt-2 font-medium">
    //       MBBS, MS, MCh (Urology)
    //       <br></br>
    //       Urologist
    //     </h6>
    //   ),
    //   img: "../photo/doc11.jpg",
    //   rate: "",
    //   exper: "5",
    //   depat: "Urology",
    // },
    // {
    //   name: "Ms. Jeeveetha Ragupathy",
    //   dis: (
    //     <h6 className="pt-2 font-medium">
    //       Dietitian
    //       <br></br>& Nutritionist
    //     </h6>
    //   ),
    //   img: "../photo/doc12.jpg",
    //   rate: "",
    //   exper: "5",
    //   depat: "Dietitian & Nutritionist",
    // },
    {
      name: "Mr. Naveen Kodandaram",
      dis: (
        <h6 className="pt-2 font-medium">
          Dietitian
          <br></br>& Nutritionist
        </h6>
      ),
      img: "../photo/doc13.jpg",
      rate: "",
      exper: "5",
      depat: "Dietitian & Nutritionist",
    },
    {
      name: "Dr. Binila",
      dis: (
        <h6 className="pt-2 font-medium">
          BPT, MIAP
          <br></br>
          Physiotherapist
        </h6>
      ),
      img: "../photo/doc14.jpg",
      rate: "",
      exper: "20",
      depat: "Physiotherapist",
    },
    // {
    //   name: "Dr Ajay S S",
    //   dis: (
    //     <h6 className="pt-2 font-medium">
    //       MBBS, MS (Orthopaedics)
    //       <br></br>FJR, FSCRM Orthopaedic Surgeon
    //     </h6>
    //   ),
    //   img: "../photo/doc15.jpg",
    //   rate: "",
    //   exper: "5",
    //   depat: "Orthopaedic Surgeon",
    // },
    // {
    //   name: "Dr Pavithra",
    //   dis: (
    //     <h6 className="pt-2 font-medium">
    //       MBBS
    //       <br></br>--
    //     </h6>
    //   ),
    //   img: "../photo/doc16.jpg",
    //   rate: "",
    //   exper: "5",
    //   depat: "--",
    // },

    // {
    //   name: "Dr - vishnuvardhan BR",
    //   dis: (
    //     <h6 className="pt-2 font-medium">
    //       MBBS, MD, general medicine <br></br>DM Nephrology Renal specialist
    //     </h6>
    //   ),
    //   img: "../photo/doc2.jpg",
    //   rate: "",
    //   exper: "5",
    //   depat: "Nephrologist",
    // },
    // {
    //   name: "Dr. Chandrika Chandran",
    //   dis: (
    //     <h6 className="pt-2 font-medium">
    //       MBBS, DGO <br></br>Gynecologist and Obstetrician
    //     </h6>
    //   ),
    //   img: "../photo/02-04-24.jpg",
    //   rate: "",
    //   exper: "50",
    //   depat: "Gynecologist and Obstetrician",
    // },

    {
      name: "Dr. Shalini Kumari",
      dis: (
        <h6 className="pt-2 font-medium">
          MBBS, MS( OBG ),<br></br>Fellowship (RGUHS), MRCOG
        </h6>
      ),
      img: "../photo/dr-shalini-kumari.png",
      rate: "",
      exper: "11",
      depat: "Gynecologist and Obstetrician",
    },
  ];
  const menus = [
    {
      name: "Dermatology",
      img: "../photo/dermatology (1).png",
      link: "",
    },
    {
      name: "Diabetes",
      img: "../photo/glucometer.png",
      link: "",
    },
    {
      name: "Dental care",
      img: "../photo/teeth.png",
      link: "",
    },
    {
      name: "Orthopedic",
      img: "../photo/orthopedics.png",
      link: "",
    },
    {
      name: "General surgery",
      img: "../photo/surgery-tools.png",
      link: "",
    },
    {
      name: "Pediatrics",
      img: "../photo/pediatric.png",
      link: "",
    },
    {
      name: "ENT",
      img: "../photo/pharynx.png",
      link: "",
    },
    {
      name: "Physiotherapy",
      img: "../photo/physical-therapy.png",
      link: "",
    },
    {
      name: "Urology",
      img: "../photo/kidney.png",
      link: "",
    },
    {
      name: "Neurology",
      img: "../photo/brain (1).png",
      link: "",
    },
    {
      name: "Psychiatry",
      img: "../photo/8842496.png",
      link: "",
    },
    {
      name: "Obstetrics and Gynecology",
      img: "../photo/12106197.png",
      link: "",
    },
    {
      name: "Cardiology",
      img: "../photo/1028356.png",
      link: "",
    },
    {
      name: "Internal Medicine",
      img: "../photo/8752669.png",
      link: "",
    },
  ];
  return (
    <>
      <section className="flex justify-center pb-8 pt-0">
        <div className="containerbox">
          {/* <h1
            style={{
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
            }}
            className="text-3xl font-bold leading-snug"
          >
            Our Esteemed Doctors
          </h1> */}
          {/* <div className="w-full flex justify-center">
            <h6 className="text-xl font-medium mb-3">Our Esteemed Doctors</h6>
          </div> */}
          <div className="col-span-2 flex items-center mt-4">
            <div className="px-0 overflow-hidden">
              <Slider {...settings}>
                {menu.map((elem) => {
                  return (
                    <>
                      <div className="w-full p-2 flex">
                        <div className="w-full rounded-lg bg-white">
                          <div className="flex flex-wrap  shadow-sm rounded-md p-4 relative w-full">
                            <div className="w-1/5">
                              <img
                                className="rounded-md w-full"
                                src={elem.img}
                                alt=""
                              />
                            </div>
                            <div className="w-4/5 md:w-2/3 pl-2">
                              <h6
                                style={{ color: "#3d7cc9" }}
                                className="font-medium"
                              >
                                {elem.name}
                              </h6>
                              {elem.dis}
                            </div>
                            <div className="w-full mt-3 flex">
                              <div className="w-1/3 flex items-center">
                                <IoIosStar />
                                <h6 className="text-gray-600 ml-1 md:ml-2 text-sm">
                                  4.6/5
                                </h6>
                              </div>
                              <div className="w-1/3 flex items-center">
                                <MdMedicalServices />
                                <h6 className="text-gray-600 ml-1 md:ml-2 text-sm">
                                  {`${elem.exper}+ Years`}
                                </h6>
                              </div>
                              <div className="w-1/3 flex items-center">
                                <FaLocationDot />
                                <h6 className="text-gray-600 ml-1 md:ml-2 text-sm">
                                  Bangalore
                                </h6>
                              </div>
                            </div>
                            <button className="border-inherit border py-1 px-3 rounded-md mt-3 text-white bg-gray-400 text-sm">
                              {elem.depat}
                            </button>
                            <div className="w-full mt-4 flex justify-between">
                              <div className="w-5/12 pr-3">
                                <a
                                  href="tel:9535264855"
                                  style={{ border: "1.5px solid #3d7cc9" }}
                                  className="border-inherit border-2 text-black p-1 rounded-md w-full hidden md:flex justify-center text-sm"
                                >
                                  9535264855
                                </a>
                                <a
                                  className="border-inherit border-2 text-black p-1 rounded-md w-full flex justify-center md:hidden text-sm"
                                  href="tel:9535264855"
                                >
                                  Call Us
                                </a>
                              </div>
                              <div className="w-7/12">
                                <div className="flex w-full">
                                  <a
                                    href="tel:9535264855"
                                    style={{ backgroundColor: "#3d7cc9" }}
                                    className="border-inherit border py-1 px-3 rounded-md text-white w-full text-sm text-center h-full"
                                  >
                                    Appointment
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </Slider>
            </div>
          </div>
          {/* <div className="w-full justify-center">
            <div className="flex justify-center mt-4">
              <button
                style={{ backgroundColor: "#3d7cc9" }}
                className="border-inherit border py-1 px-4 rounded-md mt-4 text-white flex items-center mr-2 mr-"
              >
                Book Appointment
              </button>
              <button
                style={{ backgroundColor: "#3d7cc9" }}
                className="border-inherit border py-1 px-4 rounded-md mt-4 text-white flex items-center ml-2"
              >
                Book Appointment
              </button>
            </div>
          </div> */}
          {/* <div>
            <div className="grid grid-cols-3  sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-9 mt-6">
              {menus.map((elem, num) => {
                if (num < number) {
                  return (
                    <>
                      <div className="w-full mb-6 px-2">
                        <div className="w-full flex justify-center mb-2">
                          <div
                            style={{
                              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                            }}
                            className="w-20 h-20 border flex justify-center items-center"
                          >
                            <img className="w-16" src={elem.img} alt="" />
                          </div>
                        </div>
                        <p className="text-center">{elem.name}</p>
                      </div>
                    </>
                  );
                }
              })}
            </div>
          </div> */}
          {/* <div className="w-full flex justify-center">
            <button
              onClick={() => {
                number !== menus.length + 1
                  ? setNumber(menus.length + 1)
                  : handleWindowLoad();
              }}
              style={{ backgroundColor: "#48b95f" }}
              className="border-inherit border px-4 py-2 rounded-md mt-2 text-white"
            >
              {number !== menus.length + 1 ? "Read More" : "Read Less"}
            </button>
          </div> */}
        </div>
      </section>
    </>
  );
}
