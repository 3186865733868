import React from "react";
import { FaBusinessTime } from "react-icons/fa6";
import { Link } from "react-router-dom";

function ServiceHome() {
  return (
    <div className="flex justify-center py-16">
      <div className="containerbox">
        <div className="w-full grid sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
          <div className="p-2 w-full">
            <div>
              <h1 className="text-4xl font-bold">Our Specialities</h1>
              <h6 className="font-medium text-gray-700 flex items-center mt-4">
                Certainly! ELV Health Care Poly Clinics in Bengaluru provide a
                wide range of comprehensive and accessible healthcare services.
                Our commitment is to create a haven of well-being for
                individuals in the heart of the city. Our services are designed
                to address diverse medical needs, ensuring that each person
                receives the attention and care they deserve.
              </h6>
              <h6 className="font-medium text-gray-700 flex items-center mt-4">
                At ELV Health Care, we offer a spectrum of medical services,
                including a state-of-the-art day care center and minor OT
                facilities. This ensures that patients receive specialized care
                in a comfortable environment.
              </h6>
              <div className="flex">
                <a
                  href="tel:9535264855"
                  style={{ backgroundColor: "#3d7cc9" }}
                  className="border-inherit border py-2 px-4 rounded-md mt-4 text-white flex items-center sm:mr-2 mr-"
                >
                  Book Appointment
                </a>
              </div>
            </div>
          </div>
          <div className="w-full p-2.5 relative mt-0">
            <div
              style={{
                boxShadow:
                  "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
              }}
              className="p-5 relative"
            >
              <img
                className="absolute w-14 right-6 top-6"
                src="../photo/12106197.png"
                alt=""
              />
              <img className="w-full" src="../photo/den6.jpg" alt="" />
              <h6 className="text-3xl font-bold mt-2">Dental care</h6>
              <p className="font-medium text-gray-700 mt-2 w-full line-clamp-4">
                Welcome to ELV Health Care Polyclinics, where your oral health
                is our priority. Our hospital is dedicated to providing
                comprehensive and expert Dental Care services to ensure the
                well-being of your teeth and gums. At ELV, our team of skilled
                dentists offers a wide range of dental treatments, including
                preventive care, routine check-ups, and specialized procedures,
                all designed to cater to your unique dental needs.
              </p>
              <Link
                to={"/bestdentalinwhitefield"}
                style={{ color: "#3d7cc9" }}
                className="font-medium"
              >
                Read More
              </Link>
              <div className="flex items-center mt-3">
                <FaBusinessTime className="text-2xl mr-2" />
                <p className="text-lg font-medium">
                  Contact Us For Any Inquiries
                </p>
              </div>
            </div>
          </div>
          <div className="w-full p-2.5 relative">
            <div
              style={{
                boxShadow:
                  "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
              }}
              className="p-5 relative"
            >
              <img
                className="absolute w-14 right-6 top-6"
                src="../photo/orthopedics.png"
                alt=""
              />
              <img className="w-full" src="../photo/aa14.jpg" alt="" />
              <h6 className="text-3xl font-bold mt-2">Dermatology</h6>
              <p className="font-medium text-gray-700 mt-2 w-full line-clamp-4">
                Welcome to ELV Health Care Polyclinics, a premier destination
                for comprehensive Dermatology services, where our team of
                experienced dermatologists is dedicated to providing expert care
                for skin health and aesthetic well-being. At ELV, we understand
                the significance of dermatology in maintaining healthy skin, and
                our Dermatology services are designed to address a range of skin
                conditions and aesthetic concerns. Our hospital offers a
                spectrum of dermatological care, from routine skin check-ups to
                advanced dermatologic treatments. <a href="">Read More</a>
              </p>
              <Link
                to={"/bestdermatologyservicesinwhitefield"}
                style={{ color: "#3d7cc9" }}
                className="font-medium"
              >
                Read More
              </Link>
              <div className="flex items-center mt-3">
                <FaBusinessTime className="text-2xl mr-2" />
                <p className="text-lg font-medium">
                  Contact Us For Any Inquiries
                </p>
              </div>
            </div>
          </div>
          <div className="w-full p-2.5 relative">
            <div
              style={{
                boxShadow:
                  "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
              }}
              className="p-5 relative"
            >
              <img
                className="absolute w-14 right-6 top-6"
                src="../photo/surgery-tools.png"
                alt=""
              />
              <img className="w-full" src="../photo/cos1.jpg" alt="" />
              <h6 className="text-3xl font-bold mt-2">Cosmetic Treatments</h6>
              <p className="font-medium text-gray-700 mt-2 w-full line-clamp-4">
                Welcome to ELV Health Care Polyclinics, where we prioritize the
                beauty and health of your smile through our dedicated Cosmetic
                Treatments. Our clinic is committed to providing comprehensive
                and expert Cosmetic Dentistry services to enhance the aesthetics
                of your teeth while ensuring their overall well-being.{" "}
                <a href="">Read More</a>
              </p>
              <Link
                to={"/bestcosmetictreatmentsinwhitefield"}
                style={{ color: "#3d7cc9" }}
                className="font-medium"
              >
                Read More
              </Link>
              <div className="flex items-center mt-3">
                <FaBusinessTime className="text-2xl mr-2" />
                <p className="text-lg font-medium">
                  Contact Us For Any Inquiries
                </p>
              </div>
            </div>
          </div>
          <div className="w-full p-2.5 relative">
            <div
              style={{
                boxShadow:
                  "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
              }}
              className="p-5 relative"
            >
              <img
                className="absolute w-14 right-6 top-6"
                src="../photo/pediatric.png"
                alt=""
              />
              <img className="w-full" src="../photo/newser4.jpg" alt="" />
              <h6 className="text-3xl font-bold mt-2">Pediatrics</h6>
              <p className="font-medium text-gray-700 mt-2 w-full line-clamp-4">
                Pediatrics is a specialized branch of medicine that focuses on
                the healthcare needs of infants, children, and adolescents. At
                ELV Health Care Polyclinics, our Pediatrics Department is
                committed to providing comprehensive and compassionate medical
                care for the youngest members of our community.{" "}
                <a href="">Read More</a>
              </p>
              <Link
                to={"/bestpediatricsservicesinwhitefield"}
                style={{ color: "#3d7cc9" }}
                className="font-medium"
              >
                Read More
              </Link>
              <div className="flex items-center mt-3">
                <FaBusinessTime className="text-2xl mr-2" />
                <p className="text-lg font-medium">
                  Contact Us For Any Inquiries
                </p>
              </div>
            </div>
          </div>
          <div className="w-full p-2.5 relative">
            <div
              style={{
                boxShadow:
                  "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
              }}
              className="p-5 relative"
            >
              <img
                className="absolute w-14 right-6 top-6"
                src="../photo/kidney.png"
                alt=""
              />
              <img className="w-full" src="../photo/newser5.jpg" alt="" />
              <h6 className="text-3xl font-bold mt-2">Urology</h6>
              <p className="font-medium text-gray-700 mt-2 w-full line-clamp-4">
                Urology is a medical specialty that focuses on the diagnosis and
                treatment of conditions related to the urinary tract and male
                reproductive system. At ELV Health Care Polyclinics, our Urology
                Department is dedicated to providing comprehensive and advanced
                urological services to address a wide range of health concerns
                related to the kidneys, bladder, prostate, and other urological
                organs.<a href="">Read More</a>
              </p>
              <Link
                to={"/besturologyservicesinwhitefield"}
                style={{ color: "#3d7cc9" }}
                className="font-medium"
              >
                Read More
              </Link>
              <div className="flex items-center mt-3">
                <FaBusinessTime className="text-2xl mr-2" />
                <p className="text-lg font-medium">
                  Contact Us For Any Inquiries
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceHome;
