import React, { useState } from "react";

function ContactwithWat() {
  const [wats, setWats] = useState<string>("");
  return (
    <section className="flex justify-center">
      <div
        style={{
          backgroundImage:
            " linear-gradient(#0f172a, #0f172a), url(../photo/sam.jpg)",
        }}
        className="containerbox py-12 w-full flex flex-wrap justify-center px-5 lg:px-0"
      >
        <div className="flex justify-center w-full md:w-2/5 lg:w-1/5 items-center pt-12 lg:pt-0">
          <img
            className="w-60 mb-0"
            src="../photo/whatsapp-mobile-phone-mockup-icon-3d_437242-2895-removebg-preview.png"
            alt=""
          />
        </div>
        <div className="pt-6 pb-12 lg:py-12 w-full md:w-3/5">
          <h1 className="text-2xl font-bold leading-snug text-center mt-3 text-white">
            Contact via WhatsApp.
          </h1>
          <p className="text-center text-white">
            Feel free to reach out with any questions or needs; our team is
            ready and eager to assist you promptly.
          </p>
          <div className="flex flex-wrap justify-center my-2">
            <input
              onChange={(e) => {
                setWats(e.target.value);
              }}
              className="w-full sm:w-2/3 lg:w-1/3 h-10 border rounded pl-2"
              type="text"
              placeholder="Whatsapp Message"
            />
            <a
              href={`https://api.whatsapp.com/send?phone=9535264855&text=${wats}`}
              style={{ backgroundColor: "#48b95f" }}
              className="border-inherit border px-4 py-2 rounded-md mt-2 sm:mt-0 text-white"
            >
              Send
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactwithWat;
