import React, { useEffect } from "react";
import FixHeader from "./components/Home/FixHeader";
import Header from "./components/header";
import SliderInfo from "./components/Home/SliderInfo";
import ModileSlide from "./components/Home/ModileSlide";
import DirectContact from "./components/Home/DirectContact";
import ServiceList from "./components/Services/ServiceList";
import Infobox from "./components/Home/Infobox";
import Specilities from "./components/Home/Specilities";
import Youtube from "./components/Services/Youtube";
import NumberInfo from "./components/Home/NumberInfo";
import Callsection from "./components/Home/Callsection";
import Whychoose from "./components/Home/Whychoose";
import Qns from "./components/Services/Qns";
import Divider from "./components/Divider";
import ServiceReview from "./components/Services/ServiceReview";
import Speclityslide from "./components/Home/Speclityslide";
import Wecare from "./components/Home/Wecare";
import Specilizedser from "./components/Services/Specilizedser";
import SecondBlog from "./components/Services/SecondBlog";
import ContactwithWat from "./components/Services/ContactwithWat";
import Contactbynum from "./components/Services/Contactbynum";
import ServiceHome from "./components/Services/ServiceHome";
import { movetop } from "./fun";

const Home = () => {
  useEffect(() => {
    movetop();
  }, []);
  return (
    <>
      {/* <div className="hidden lg:flex">
        <SliderInfo />
      </div> */}
      <ModileSlide />
      <ServiceList />
      {/* <NumberInfo /> */}
      <Infobox />
      <Contactbynum color={"#0f172a"} />
      {/* <div
        style={{
          backgroundImage:
            " linear-gradient(#0f172a, #0f172a), url(../photo/sam.jpg)",
        }}
        className="py-8 bg-cover bg-center bg-fixed"
      > */}
      {/* <NumberInfo test="text-white" /> */}
      {/* </div> */}
      {/* <Speclityslide /> */}
      {/* <Specilities /> */}
      {/* <Specilizedser /> */}
      <ServiceHome />
      <Callsection />
      <Whychoose />
      <ServiceReview />
      <div className="py-0 bg-cover bg-center bg-fixed">
        <ContactwithWat />
      </div>
      <Specilizedser />
      {/* <SecondBlog /> */}
      {/* <Divider /> */}
      {/* <Doctorbox /> */}
      {/* <Youtube /> */}
      {/* <ThreeBoxin /> */}
      {/* <HealthTip /> */}
      {/* <BlogHome /> */}
      {/* <Prequentquation /> */}
      {/* <Qns /> */}
      {/* <Conatinfobox /> */}
      {/* <Qns /> */}

      {/* <Servicebanner />
      <div style={{ backgroundColor: "#e3eff1" }} className="py-0">
        <NumberInfo />
      </div>
      <Doctorbox />
      <Serviceinfo />
      <ServiceReview />
      <Divider />
      <Wecare /> */}
    </>
  );
};

export default Home;
