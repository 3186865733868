import { log } from "console";
import React, { useEffect, useState } from "react";
import { TfiTimer } from "react-icons/tfi";
import { LiaHospitalSymbolSolid } from "react-icons/lia";
import { BiClinic } from "react-icons/bi";
import { MdMilitaryTech } from "react-icons/md";
import { FaLocationCrosshairs } from "react-icons/fa6";

function Infiry() {
  const [scrollDifference, setScrollDifference] = useState<number>(0);

  useEffect(() => {
    const handleScroll = () => {
      const sectionElement = document.getElementById("yourSectionId");
      const screenHeight: number = window.innerHeight;

      if (sectionElement) {
        const rect = sectionElement.getBoundingClientRect();
        const topOfScreen = window.scrollY;
        if (rect.top < 128) {
          setScrollDifference(-1 * rect.top);
        } else {
          setScrollDifference(0);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div
        style={{
          backgroundColor: "#fafafa",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
        }}
        className="p-4 rounded-xl h-auto md:h-full lg:h-auto"
        id="yourSectionId"
      >
        <div>
          <h5
            style={{ color: "#3d7cc9" }}
            className="text-lg text-center font-medium"
          >
            ELV Health Care Polyclinics
          </h5>
          <div className="mt-4">
            <div className="grid grid-cols-2 gap-3 sm:gap-4">
              <div
                style={{ boxShadow: "rgba(0, 0, 0, 0.001) 0px 0px 0px 0.1px" }}
                className="bg-white py-2.5 px-2 sm:px-4 lg:px-1 xl:px-4 border rounded-md flex items-center"
              >
                <div>
                  <LiaHospitalSymbolSolid className="text-3xl font-light text-gray-600" />
                </div>
                <div className="pl-1 sm:pl-3 lg:pl-2 xl:pl-3">
                  <h6 className="text-sm font-medium text-gray-600">
                    Comprehensive Healthcare
                  </h6>
                </div>
              </div>
              <div
                style={{ boxShadow: "rgba(0, 0, 0, 0.001) 0px 0px 0px 0.1px" }}
                className="bg-white py-2.5 px-2 sm:px-4 lg:px-1 xl:px-4 border rounded-md flex items-center"
              >
                <div>
                  <BiClinic className="text-3xl font-light text-gray-600" />
                </div>
                <div className="pl-1 sm:pl-3 lg:pl-2 xl:pl-3">
                  <h6 className="text-sm font-medium text-gray-600">
                    Patient-Centric Approach
                  </h6>
                </div>
              </div>
              <div
                style={{ boxShadow: "rgba(0, 0, 0, 0.001) 0px 0px 0px 0.1px" }}
                className="bg-white py-2.5 px-2 sm:px-4 lg:px-1 xl:px-4 border rounded-md flex items-center"
              >
                <div>
                  <FaLocationCrosshairs className="text-3xl font-light text-gray-600" />
                </div>
                <div className="pl-1 sm:pl-3 lg:pl-2 xl:pl-3">
                  <h6 className="text-sm font-medium text-gray-600">
                    Strategic Location
                  </h6>
                </div>
              </div>
              <div
                style={{ boxShadow: "rgba(0, 0, 0, 0.001) 0px 0px 0px 0.1px" }}
                className="bg-white py-2.5 px-2 sm:px-4 lg:px-1 xl:px-4 border rounded-md flex items-center"
              >
                <div>
                  <MdMilitaryTech className="text-3xl font-light text-gray-600" />
                </div>
                <div className="pl-1 sm:pl-3 lg:pl-2 xl:pl-3">
                  <h6 className="text-sm font-medium text-gray-600">
                    State-of-the-Art Facilities
                  </h6>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="mt-4 flex justify-center">
            <div>
              <button
                style={{ backgroundColor: "#3d7cc9" }}
                className="border-inherit border py-1 px-3 rounded-md text-white w-full"
              >
                Appointment
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Infiry;
