import React from "react";
import { MdOutlineWifiCalling3 } from "react-icons/md";

function Callin() {
  return (
    <section className="flex justify-center h-auto md:h-full lg:h-auto">
      <div
        style={{
          backgroundColor: "#fafafa",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
        }}
        className="containerbox py-12 w-full flex flex-wrap justify-center"
      >
        <div className="w-full p-4">
          <h1
            style={{ color: "#3d7cc9" }}
            className="text-lg text-center font-medium"
          >
            Contact via Phone Call.
          </h1>
          <p className="text-center">
            Feel free to reach out to us with any questions or specific needs
            you may have. Our team is eager to assist you promptly and ensure
            you receive the support and information you require.
          </p>
          <div className="flex flex-wrap justify-center my-2">
            <div>
              <a
                href="tel:9535264855"
                style={{ backgroundColor: "#3d7cc9" }}
                className="border-inherit border py-1 px-3 rounded-md text-white w-full"
              >
                Appointment
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Callin;
