"use client";
import { FaStar } from "react-icons/fa6";
import { AiOutlineLike } from "react-icons/ai";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function ServiceReview() {
  const settings: Settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const menu = [
    {
      name: "Rajeshwari N",
      img: "../photo/unnamed (3).png",
      dis: "9 reviews·7 photos",
      date: "3 months ago",
      mes: "Hello, Rajeshwari N, It's wonderful to hear about your positive experiences with the healthcare services you've received for your elderly mother. A clean and hygienic environment, along with professionalism and kindness from the staff and doctors, can make a significant difference in a patient's comfort and overall satisfaction. Additionally, painless injections can certainly provide peace of mind. We wish you and your family a healthy future.",
    },
    {
      name: "Anshu Kashyap",
      img: "../photo/unnamed (2).png",
      dis: "Local Guide·65 reviews·5 photos",
      date: "3 months ago",
      mes: "Well, I must thank this clinic for the superb hospitality service. Special thanks to Madhu, who have been grateful all the time and must say if u are afraid of injections/vaccines , you must try to get it by her -  it's totally painless. She must be very professional and trained for the same. Thanks to all the staffs as well, they are very polite, humble and response to each and every query very efficiently.",
    },
    {
      name: "Bindumadhav G",
      img: "../photo/unnamed (1).png",
      dis: "9 reviews",
      date: "a month ago",
      mes: "Well, I must thank this clinic for the superb hospitality service. Special thanks to Madhu, who have been grateful all the time and must say if u are afraid of injections/vaccines , you must try to get it by her -  it's totally painless. She must be very professional and trained for the same. Thanks to all the staffs as well, they are very polite, humble and response to each and every query very efficiently.",
    },
    {
      name: "Mangalamma Mangalamma",
      img: "../photo/unnamed.png",
      dis: "1 review",
      date: "2 months ago",
      mes: "I am very thankful to this hospital  for curing my father's health problem withthere skillful treatment. I really appreciate all the doctors and nursing staff for their commitment. I am very thankful to this hospital and all the staff very humble and  carefull the hospitality is very clean we are proud of that I don't know about other clinic but this clinic is best for us 😊",
    },
    {
      name: "Meenakshi Sharma",
      img: "../photo/unnamed.png",
      dis: "1 review",
      date: "2 months ago",
      mes: "I had an amazing experience with the home service injection nurse(Rani)who visited my home recently. They were punctual, professional, and caring. Their expertise in administering injections was top-notch, and they prioritized hygiene and safety. I highly recommend their services to anyone in need of at-home medical care!",
    },
  ];
  return (
    <>
      <div className="flex justify-center py-12">
        <div className="containerbox">
          <div className="w-full flex justify-center">
            <h6 className="text-xl font-medium mb-3">Our Patient Love Us</h6>
          </div>
          <div className="w-full overflow-hidden">
            <Slider {...settings}>
              {menu.map((elem) => {
                return (
                  <>
                    <div className="w-full p-2.5">
                      <div
                        style={{
                          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        }}
                        className="w-full p-5 rounded-xl"
                      >
                        <div className="w-full">
                          <div className="flex items-center">
                            <div className="w-9 h-9 font-medium text-white flex justify-center items-center bg-gray-400 rounded-full mr-3">
                              {elem.name.slice(0, 2).toLocaleUpperCase()}
                            </div>
                            <div className="block">
                              <h6>{elem.name}</h6>
                              <div className="flex ">
                                <div className="flex items-center">
                                  <FaStar className="mr-0.5 text-orange-400 text-sm" />
                                  <FaStar className="mr-0.5 text-orange-400 text-sm" />
                                  <FaStar className="mr-0.5 text-orange-400 text-sm" />
                                  <FaStar className="mr-0.5 text-orange-400 text-sm" />
                                  <FaStar className="mr-0.5 text-orange-400 text-sm" />
                                  <h6 className=" text-sm">5/5</h6>
                                </div>
                                <div className="flex ml-2 items-center">
                                  <AiOutlineLike className="text-green-700 text-sm" />
                                  <h6 className="text-green-700 text-sm">
                                    {" "}
                                    Recommends
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mt-2 pb-4 border-b-2 border-gray-400">
                            <div className="w-full h-36 overflow-y-scroll">
                              <p className="text-sm">{elem.mes}</p>
                            </div>
                          </div>
                          <div className="mt-1">
                            <h6>City: Bangaluru</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </Slider>
          </div>
          <div className="w-full justify-center">
            <div className="flex justify-center mt-4">
              <a
                href="https://www.google.co.in/search?q=elv+healthcare+polyclinic&sca_esv=590870356&sxsrf=AM9HkKnBVoQs1oCUaydc0i-gH-hCLXTu_A%3A1702554843068&source=hp&ei=2-x6ZcXQAZXC4-EP8_Ol6A0&iflsig=AO6bgOgAAAAAZXr660FKwQdnoUlC5zzT1p0xPduE41aC&oq=elv&gs_lp=Egdnd3Mtd2l6IgNlbHYqAggAMgoQIxiABBiKBRgnMgQQIxgnMgoQIxiABBiKBRgnMggQLhixAxiABDILEC4YgwEYsQMYgAQyCBAAGIAEGLEDMggQABiABBixAzILEAAYgAQYsQMYgwEyCBAAGIAEGLEDMgsQABiABBixAxiDAUifGlAAWMMJcAB4AJABAJgB2AGgAfwDqgEFMC4yLjG4AQHIAQD4AQHCAhEQLhiABBixAxiDARjHARjRA8ICDhAAGIAEGIoFGLEDGIMBwgIFEC4YgATCAggQLhiABBixAw&sclient=gws-wiz#ip=1&lrd=0x3bae11b060be9779:0x18df43749fa8a121,1,,,,"
                style={{ backgroundColor: "#3d7cc9" }}
                className="border-inherit border py-1 px-4 rounded-md mt-4 text-white flex items-center sm:mr-2 mr-"
              >
                Read More Review
              </a>
              <a
                href="https://www.google.co.in/search?q=elv+healthcare+polyclinic&sca_esv=590870356&sxsrf=AM9HkKnBVoQs1oCUaydc0i-gH-hCLXTu_A%3A1702554843068&source=hp&ei=2-x6ZcXQAZXC4-EP8_Ol6A0&iflsig=AO6bgOgAAAAAZXr660FKwQdnoUlC5zzT1p0xPduE41aC&oq=elv&gs_lp=Egdnd3Mtd2l6IgNlbHYqAggAMgoQIxiABBiKBRgnMgQQIxgnMgoQIxiABBiKBRgnMggQLhixAxiABDILEC4YgwEYsQMYgAQyCBAAGIAEGLEDMggQABiABBixAzILEAAYgAQYsQMYgwEyCBAAGIAEGLEDMgsQABiABBixAxiDAUifGlAAWMMJcAB4AJABAJgB2AGgAfwDqgEFMC4yLjG4AQHIAQD4AQHCAhEQLhiABBixAxiDARjHARjRA8ICDhAAGIAEGIoFGLEDGIMBwgIFEC4YgATCAggQLhiABBixAw&sclient=gws-wiz#ip=1&lrd=0x3bae11b060be9779:0x18df43749fa8a121,3,,,,"
                style={{ backgroundColor: "#3d7cc9" }}
                className="border-inherit border py-1 px-4 rounded-md mt-4 text-white hidden sm:flex items-center ml-2"
              >
                Write More Review
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
