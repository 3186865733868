import { data } from "../../App";
import React, { useContext } from "react";
import { Link } from "react-router-dom";

function Blogcon({ blo, setSelect }: { blo: any; setSelect: any }) {
  const changer = useContext(data);
  return (
    <>
      <div className="flex justify-center">
        <div className="containerbox">
          <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-3">
            {blo.map((elem: any, num: number) => {
              return (
                <>
                  <div>
                    <div
                      style={{
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        backgroundColor: "#eff6ff",
                      }}
                      className="w-full h-full grid grid-cols-1 md:grid-cols-1 rounded-xl md:gap-0 p-5"
                    >
                      <div
                        style={{ aspectRatio: 3 / 2 }}
                        className="py-0 w-full"
                      >
                        <div
                          style={{ backgroundImage: `url(${elem.img})` }}
                          className="w-full h-full mainbanner rounded-md"
                        ></div>
                      </div>
                      <div className="col-span-3 mt-4 md:mt-2">
                        <h5 className="text-lg font-medium mb-2 ">
                          {elem.name}
                        </h5>
                        <p className="mt-0 line-clamp-3 mb-2 ">{elem.cont1}</p>
                      </div>
                      <button
                        onClick={() => {
                          changer.setTopser(elem);
                        }}
                        className="flex relative bottom-0"
                      >
                        <a href="#section-heading">
                          <div className="flex items-center  px-5 py-0.5 rounded-md">
                            <img
                              className="w-12 mr-2"
                              src="../photo/logobanner.png"
                              alt=""
                            />

                            <h6
                              style={{ color: "#0f172a" }}
                              className="font-bold"
                            >
                              Read More
                            </h6>
                          </div>
                        </a>
                      </button>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Blogcon;
