export default function Whychoose() {
  return (
    <>
      <section className="w-full grid grid-cols-1 lg:grid-cols-3">
        <div className="px-8 py-12 flex justify-center items-center">
          <div>
            <h1 className="text-2xl font-bold leading-snug text-center mt-0">
              ELV Health Care
            </h1>
            <p className="text-center mt-4 line-clamp-3">
              ELV Health Care Poly Clinics stand as pillars of comprehensive and
              accessible healthcare services, fostering a haven of well-being in
              the heart of Bengaluru. With a spectrum of medical offerings,
              these clinics cater to a diverse range of needs
            </p>
            <div className="flex justify-center">
              <a
                href="tel:9535264855"
                style={{ backgroundColor: "#48b95f" }}
                className="border-inherit border py-2 px-4 rounded-md text-white flex items-center mt-4 mr-"
              >
                Book Appointment
              </a>
            </div>
          </div>
        </div>
        <div className="grid grid-rows-2 md:grid-cols-2 lg:grid-cols-1 md:grid-rows-1 lg:grid-rows-2 gap-4 px-8 pt-12 pb-6 md:py-12 bg-blue-50">
          <div>
            <h1
              style={{ color: "#48b95f" }}
              className="text-5xl font-bold mt-0"
            >
              01.
            </h1>
            <h6 className="text-lg font-medium">
              How does ELV Health Care contribute to community health?
            </h6>
            <p style={{ lineHeight: "20px" }} className="line-height-2 mt-2">
              ELV Health Care Poly Clinics contribute to community health by
              providing accessible healthcare services, promoting preventive
              care, and striving to be a trusted partner in maintaining a
              healthy and fulfilling life for individuals in Bengaluru.
            </p>
          </div>
          <div className="">
            <h1
              style={{ color: "#48b95f" }}
              className="text-5xl font-bold mt-0"
            >
              02.
            </h1>
            <h6 className="text-lg font-medium mt-2">
              What facilities make ELV Health Care a one-stop solution?
            </h6>
            <p style={{ lineHeight: "20px" }} className="line-height-2 mt-2">
              ELV Health Care Poly Clinics offer a state-of-the-art day care
              center, minor OT facilities, a fully stocked pharmacy, and primary
              care services. This diverse range of facilities positions ELV
              Health Care as a comprehensive one-stop solution for various
              medical needs.
            </p>
          </div>
        </div>
        <div className="grid grid-rows-2 md:grid-cols-2 lg:grid-cols-1 md:grid-rows-1 lg:grid-rows-2 gap-4 px-8 pt-0 pb-12 lg:py-12  bg-blue-50">
          <div>
            <h1
              style={{ color: "#48b95f" }}
              className="text-5xl font-bold mt-0"
            >
              03.
            </h1>
            <h6 className="text-lg font-medium mt-2">
              What sets ELV Health Care Poly Clinics apart from others?
            </h6>
            <p style={{ lineHeight: "20px" }} className="line-height-2 mt-2">
              ELV Health Care Poly Clinics differentiate themselves by providing
              comprehensive and accessible healthcare services in the heart of
              Bengaluru. The clinics serve as pillars of well-being, offering a
              diverse range of medical services.
            </p>
          </div>
          <div className="">
            <h1
              style={{ color: "#48b95f" }}
              className="text-5xl font-bold mt-1 md:mt-0"
            >
              04.
            </h1>
            <h6 className="text-lg font-medium mt-2">
              How does ELV Health Care ensure personalized attention?
            </h6>
            <p style={{ lineHeight: "20px" }} className="line-height-2 mt-2">
              ELV Health Care Poly Clinics prioritize individual needs, ensuring
              that every person receives the attention and care they deserve.
              The clinics cater to a spectrum of medical requirements, fostering
              a haven of well-being for the community.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
