"use client";
import { useContext, useState } from "react";
import { FaLocationDot } from "react-icons/fa6";
import { IoIosArrowDown } from "react-icons/io";
import { MdLocationSearching } from "react-icons/md";
import { TfiMenu } from "react-icons/tfi";
import { Link } from "react-router-dom";
import { data } from "../App";

export default function Header() {
  const changer = useContext(data);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <section className="z-10 relative">
        <div>
          <div className="flex bg-slate-900 p-1 justify-center">
            <div className="containerbox flex justify-center sm:justify-between">
              <div>
                <Link to={"/"}>
                  <img
                    className="w-56 md:w-64"
                    src="../photo/logo.png"
                    alt=""
                  />
                </Link>
              </div>
              <div className="flex items-center">
                <div className="relative text-left group hidden lg:inline-block">
                  <Link
                    to={"/about"}
                    className="inline-flex items-center justify-center w-full px-2 py-2 font-medium  text-white rounded-md  focus:outline-none active:bg-gray-200 active:text-gray-800"
                  >
                    About Us
                  </Link>
                </div>
                <div className="relative text-left group hidden lg:inline-block">
                  <Link
                    to={"/bestdoctor"}
                    className="inline-flex items-center justify-center w-full px-2 py-2 font-medium  text-white rounded-md  focus:outline-none active:bg-gray-200 active:text-gray-800"
                  >
                    Doctor
                  </Link>
                </div>
                <div className="relative text-left group hidden lg:inline-block">
                  <Link
                    to={"/blog"}
                    className="inline-flex items-center justify-center w-full px-2 py-2 font-medium  text-white rounded-md  focus:outline-none active:bg-gray-200 active:text-gray-800"
                  >
                    Blog
                  </Link>
                </div>
                <div className="relative text-left group hidden sm:inline-block">
                  <a
                    href="https://www.google.co.in/maps?sca_esv=590870356&sxsrf=AM9HkKnBVoQs1oCUaydc0i-gH-hCLXTu_A:1702554843068&iflsig=AO6bgOgAAAAAZXr660FKwQdnoUlC5zzT1p0xPduE41aC&gs_lp=Egdnd3Mtd2l6IgNlbHYqAggAMgoQIxiABBiKBRgnMgQQIxgnMgoQIxiABBiKBRgnMggQLhixAxiABDILEC4YgwEYsQMYgAQyCBAAGIAEGLEDMggQABiABBixAzILEAAYgAQYsQMYgwEyCBAAGIAEGLEDMgsQABiABBixAxiDAUifGlAAWMMJcAB4AJABAJgB2AGgAfwDqgEFMC4yLjG4AQHIAQD4AQHCAhEQLhiABBixAxiDARjHARjRA8ICDhAAGIAEGIoFGLEDGIMBwgIFEC4YgATCAggQLhiABBixAw&um=1&ie=UTF-8&fb=1&gl=in&sa=X&geocode=KXmXvmCwEa47MSGhqJ90Q98Y&daddr=MR+ASTER,+Ground+Floor+Vijayanagar,+Whitefield,+Bengaluru,+Karnataka+560066"
                    type="button"
                    className="inline-flex items-center justify-center w-full px-2 py-2 font-medium  text-white rounded-md  focus:outline-none active:bg-gray-200 active:text-gray-800"
                  >
                    <FaLocationDot className="text-white mr-1" />
                    Location
                  </a>
                </div>
                <div className="pl-2 hidden sm:inline-block">
                  <a
                    href={`https://api.whatsapp.com/send?phone=9535264855&text=Hello`}
                    className="border-inherit border text-white p-2 rounded-md"
                  >
                    Book Appointment
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center py-1.5 shadow-lg">
            <div className="containerbox flex justify-between items-center">
              <div className="flex justify-center items-center">
                <button
                  onClick={() => {
                    changer.setMenufirst(true);
                  }}
                  className="inline-block lg:hidden"
                >
                  <TfiMenu className="text-2xl " />
                </button>
                <div className="relative text-left group hidden lg:inline-block">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center w-full px-2 py-2 text-sm font-medium text-gray-700 bg-white border-gray-300 rounded-md  focus:outline-none active:bg-gray-200 active:text-gray-800"
                  >
                    Medical Services
                    <svg
                      className="w-5 h-5 ml-0 mr-1 text-gray-500 transition-transform group-hover:rotate-180"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0l4 4a1 1 0 001.414 0l4-4a1 1 0 011.414 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>

                  <div className="absolute hidden left-0 pt-2.5 w-48 group-hover:block">
                    <div className="origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Link
                          to="/bestdaycareservicesinwhitefield"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 relative"
                        >
                          Day care center
                        </Link>
                        <Link
                          to={"/bestminorotservicesinwhitefield"}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        >
                          Minor OT
                        </Link>
                        <Link
                          to={"/bestpharmacyservicesinwhitefield"}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        >
                          Pharmacy
                        </Link>
                        <Link
                          to={"/bestprimarycareservicesinwhitefield"}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        >
                          Primary care
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="relative text-left group hidden lg:inline-block">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center w-full px-2 py-2 text-sm font-medium text-gray-700 bg-white border-gray-300 rounded-md  focus:outline-none active:bg-gray-200 active:text-gray-800"
                  >
                    Departments
                    <svg
                      className="w-5 h-5 ml-0 mr-1 text-gray-500 transition-transform group-hover:rotate-180"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0l4 4a1 1 0 001.414 0l4-4a1 1 0 011.414 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>

                  <div className="absolute hidden left-0 pt-2.5 w-48 group-hover:block">
                    <div className="origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Link
                          to={"/bestdentalinwhitefield"}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        >
                          Dental care
                        </Link>
                        <Link
                          to={"/bestdermatologyservicesinwhitefield"}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        >
                          Dermatology
                        </Link>
                        <Link
                          to={"/bestcosmetictreatmentsinwhitefield"}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        >
                          Cosmetic Treatments
                        </Link>
                        <Link
                          to={"/bestinternalmedicineinwhitefield"}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 relative"
                        >
                          Internal Medicine
                        </Link>
                        <Link
                          to={"/bestdiabetesinwhitefield"}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        >
                          Diabetes
                        </Link>
                        <Link
                          to={"/bestorthopedicservicesinwhitefield"}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        >
                          Orthopedic
                        </Link>
                        <Link
                          to={"/bestgeneralsurgeryinwhitefield"}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        >
                          General surgery
                        </Link>
                        <Link
                          to={"/bestpediatricsservicesinwhitefield"}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        >
                          Pediatrics
                        </Link>
                        <Link
                          to="/bestentinwhitefield"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        >
                          ENT
                        </Link>
                        <Link
                          to={"/bestphysiotherapyservicesinwhitefield"}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        >
                          Physiotherapy
                        </Link>
                        <Link
                          to={"/besturologyservicesinwhitefield"}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        >
                          Urology
                        </Link>
                        <Link
                          to={"/bestneurologyservicesinwhitefield"}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        >
                          Neurology
                        </Link>
                        <Link
                          to={"/bestpsychiatryservicesinwhitefield"}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        >
                          psychiatry
                        </Link>
                        <Link
                          to={"/bestobgservicesinwhitefield"}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        >
                          Obstetrics and Gynecology
                        </Link>
                        <Link
                          to={"/bestcardiologyservicesinwhitefield"}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        >
                          Cardiology
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="relative text-left group hidden lg:inline-block">
                  <Link
                    to={"/bestspecilitiesinwhitefield"}
                    className="inline-flex items-center justify-center w-full px-2 py-2 text-sm font-medium text-gray-700 bg-white border-gray-300 rounded-md  focus:outline-none active:bg-gray-200 active:text-gray-800"
                  >
                    Specialized Clinics
                  </Link>
                </div>
                <div className="relative  text-left group hidden lg:inline-block">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center w-full px-2 py-2 text-sm font-medium text-gray-700 bg-white border-gray-300 rounded-md  focus:outline-none active:bg-gray-200 active:text-gray-800"
                  >
                    Diagnostic Services
                    <svg
                      className="w-5 h-5 ml-0 mr-1 text-gray-500 transition-transform group-hover:rotate-180"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0l4 4a1 1 0 001.414 0l4-4a1 1 0 011.414 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>

                  <div className="absolute hidden left-0 pt-2.5 w-48 group-hover:block">
                    <div className="origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Link
                          to={"/bestultrascoundservicesinwhitefield"}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 relative"
                        >
                          Ultrascound
                        </Link>
                        <Link
                          to={"/bestechoservicesinwhitefield"}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        >
                          ECHO
                        </Link>
                        <Link
                          to={"/bestpregnancyscansinwhitefield"}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        >
                          Pregnancy scans
                        </Link>
                        <Link
                          to={"/bestxrayinwhitefield"}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        >
                          X ray
                        </Link>
                        <Link
                          to={"/bestbloodtestinwhitefield"}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        >
                          Blood test
                        </Link>
                        <Link
                          to={"/besthomebloodservicesinwhitefield"}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        >
                          Home blood sample collection
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="relative text-left group hidden lg:inline-block">
                  <Link
                    to={"/bestemergencyservicesinwhitefield"}
                    className="inline-flex items-center justify-center w-full px-2 py-2 text-sm font-medium text-gray-700 bg-white border-gray-300 rounded-md  focus:outline-none active:bg-gray-200 active:text-gray-800"
                  >
                    Emergency Services
                  </Link>
                </div>
              </div>
              <div className="relative md:block lg:hidden xl:block">
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  type="button"
                  className="flex h-full"
                >
                  <div
                    style={{ backgroundColor: "#48b95f" }}
                    className="flex py-1 px-2 items-center"
                  >
                    <MdLocationSearching className="text-slate-800" />
                    <h6 className="ml-1 text-slate-800">Services</h6>
                  </div>
                  <input
                    className="border border-lime-600 h-8 w-32 sm:w-64"
                    type="text"
                  />
                </button>
                {isOpen && (
                  <div className="absolute right-0 w-64 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1 flex flex-wrap p-0.5">
                      <div className="w-1/2 p-0.5">
                        <Link
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ backgroundColor: "#48b95f" }}
                          to={"/bestinternalmedicineinwhitefield"}
                          className="block px-4 py-2 text-sm text-black hover:bg-gray-100 hover:text-gray-900 "
                        >
                          Internal Medicine
                        </Link>
                      </div>
                      <div className="w-1/2 p-0.5">
                        <Link
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ backgroundColor: "#48b95f" }}
                          to={"/bestgeneralsurgeryinwhitefield"}
                          className="block px-4 py-2 text-sm text-black hover:bg-gray-100 hover:text-gray-900 "
                        >
                          General surgery
                        </Link>
                      </div>
                      <div className="w-1/2 p-0.5 h-full">
                        <Link
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ backgroundColor: "#48b95f" }}
                          to={"/bestdiabetesinwhitefield"}
                          className="flex px-4 py-2 text-sm text-black hover:bg-gray-100 hover:text-gray-900 h-full"
                        >
                          Diabetes
                        </Link>
                      </div>
                      <div className="w-1/2 p-0.5">
                        <Link
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ backgroundColor: "#48b95f" }}
                          to={"/bestdentalinwhitefield"}
                          className="block px-4 py-2 text-sm text-black hover:bg-gray-100 hover:text-gray-900 "
                        >
                          Dental care
                        </Link>
                      </div>
                      <div className="w-1/2 p-0.5">
                        <Link
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ backgroundColor: "#48b95f" }}
                          to={"/bestorthopedicservicesinwhitefield"}
                          className="block px-4 py-2 text-sm text-black hover:bg-gray-100 hover:text-gray-900 "
                        >
                          Orthopedic
                        </Link>
                      </div>

                      <div className="w-1/2 p-0.5">
                        <Link
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ backgroundColor: "#48b95f" }}
                          to={"/bestpediatricsservicesinwhitefield"}
                          className="block px-4 py-2 text-sm text-black hover:bg-gray-100 hover:text-gray-900 "
                        >
                          Pediatrics
                        </Link>
                      </div>
                      <div className="w-1/2 p-0.5">
                        <Link
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ backgroundColor: "#48b95f" }}
                          to="/bestentinwhitefield"
                          className="block px-4 py-2 text-sm text-black hover:bg-gray-100 hover:text-gray-900 "
                        >
                          ENT
                        </Link>
                      </div>
                      <div className="w-1/2 p-0.5">
                        <Link
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ backgroundColor: "#48b95f" }}
                          to={"/bestphysiotherapyservicesinwhitefield"}
                          className="block px-4 py-2 text-sm text-black hover:bg-gray-100 hover:text-gray-900 "
                        >
                          Physiotherapy
                        </Link>
                      </div>
                      <div className="w-1/2 p-0.5">
                        <Link
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ backgroundColor: "#48b95f" }}
                          to={"/besturologyservicesinwhitefield"}
                          className="block px-4 py-2 text-sm text-black hover:bg-gray-100 hover:text-gray-900 "
                        >
                          Urology
                        </Link>
                      </div>
                      <div className="w-1/2 p-0.5">
                        <Link
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ backgroundColor: "#48b95f" }}
                          to={"/bestneurologyservicesinwhitefield"}
                          className="block px-4 py-2 text-sm text-black hover:bg-gray-100 hover:text-gray-900 "
                        >
                          Neurology
                        </Link>
                      </div>
                      <div className="w-1/2 p-0.5">
                        <Link
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ backgroundColor: "#48b95f" }}
                          to={"/bestpsychiatryservicesinwhitefield"}
                          className="block px-4 py-2 text-sm text-black hover:bg-gray-100 hover:text-gray-900 "
                        >
                          psychiatry
                        </Link>
                      </div>

                      <div className="w-1/2 p-0.5">
                        <Link
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ backgroundColor: "#48b95f" }}
                          to={"/bestcardiologyservicesinwhitefield"}
                          className="block px-4 py-2 text-sm text-black hover:bg-gray-100 hover:text-gray-900 "
                        >
                          Cardiology
                        </Link>
                      </div>
                      <div className="w-1/2 p-0.5">
                        <Link
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ backgroundColor: "#48b95f" }}
                          to={"/bestdermatologyservicesinwhitefield"}
                          className="block px-4 py-2 text-sm text-black hover:bg-gray-100 hover:text-gray-900 "
                        >
                          Dermatology
                        </Link>
                      </div>
                      <div className="w-1/2 p-0.5">
                        <Link
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ backgroundColor: "#48b95f" }}
                          to={"/bestultrascoundservicesinwhitefield"}
                          className="block px-4 py-2 text-sm text-black hover:bg-gray-100 hover:text-gray-900 "
                        >
                          Ultrascound
                        </Link>
                      </div>
                      <div className="w-1/2 p-0.5 h-full flex items-center">
                        <Link
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ backgroundColor: "#48b95f" }}
                          to="/bestechoservicesinwhitefield"
                          className="block h-full px-4 py-2 text-sm text-black hover:bg-gray-100 hover:text-gray-900 w-full"
                        >
                          ECHO
                        </Link>
                      </div>
                      <div className="w-1/2 p-0.5">
                        <Link
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ backgroundColor: "#48b95f" }}
                          to={"/bestpregnancyscansinwhitefield"}
                          className="block px-4 py-2 text-sm text-black hover:bg-gray-100 hover:text-gray-900 "
                        >
                          Pregnancy scans
                        </Link>
                      </div>
                      <div className="w-1/2 p-0.5">
                        <Link
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ backgroundColor: "#48b95f" }}
                          to={"/bestxrayinwhitefield"}
                          className="block px-4 py-2 text-sm text-black hover:bg-gray-100 hover:text-gray-900 "
                        >
                          X ray
                        </Link>
                      </div>
                      <div className="w-1/2 p-0.5">
                        <Link
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ backgroundColor: "#48b95f" }}
                          to={"/bestbloodtestinwhitefield"}
                          className="block px-4 py-2 text-sm text-black hover:bg-gray-100 hover:text-gray-900 "
                        >
                          Blood test
                        </Link>
                      </div>
                      <div className="w-1/2 p-0.5">
                        <Link
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ backgroundColor: "#48b95f" }}
                          to={"/besthomebloodservicesinwhitefield"}
                          className="block px-4 py-2 text-sm text-black hover:bg-gray-100 hover:text-gray-900 "
                        >
                          Home blood sample collection
                        </Link>
                      </div>
                      <div className="w-1/2 p-0.5">
                        <Link
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ backgroundColor: "#48b95f" }}
                          to={"/bestobgservicesinwhitefield"}
                          className="block px-4 py-2 text-sm text-black hover:bg-gray-100 hover:text-gray-900 "
                        >
                          Obstetrics and Gynecology
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
