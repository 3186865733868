import React from "react";

function Minorcon() {
  return (
    <>
      <div>
        <img className="w-full mb-4" src="../photo/aa16.jpg" alt="" />
        <h1 style={{ color: "#3d7cc9" }} className="text-lg font-medium mb-4">
          Optimal Home Minor OT Collection Services in Whitefield, Bangalore.
        </h1>
        <p className="mb-4">
          Welcome to ELV Health Care Polyclinics, where our commitment to
          comprehensive healthcare extends to our state-of-the-art Minor
          Operating Theatre (OT) services. Understanding the importance of swift
          and precise medical interventions, our Minor OT is equipped with
          cutting-edge technology and staffed by a skilled team of surgeons and
          healthcare professionals. At ELV, we prioritize efficiency and
          precision in our Minor OT services to ensure that minor surgical
          procedures are conducted seamlessly and with the utmost care.
        </p>
        <p className="mb-4">
          {" "}
          Our Minor OT services at ELV Health Care Polyclinics cover a wide
          range of minor surgical procedures, allowing us to address diverse
          medical needs within the comfort of our hospital setting. From minor
          dermatological procedures to simple orthopedic interventions, our
          specialized team is dedicated to providing expert care. The goal is to
          offer a streamlined and efficient experience for our patients,
          minimizing discomfort and facilitating a swift recovery.
        </p>
        <p className="mb-4">
          {" "}
          ELV Health Care Polyclinics understands that trust is paramount when
          it comes to medical interventions. Our Minor OT services are
          characterized by a patient-centric approach, ensuring that individuals
          feel informed, supported, and cared for throughout their surgical
          journey. Our team is not only focused on the technical aspects of the
          procedure but also on creating a reassuring and empathetic environment
          for our patients.
        </p>
        <p className="mb-4">
          {" "}
          As you explore our website, we invite you to discover the range of
          minor surgical procedures offered at ELV Health Care Polyclinics. From
          pre-operative consultations to post-operative care, our Minor OT
          services are designed to prioritize the well-being and comfort of our
          patients. Experience healthcare excellence with a personal touch at
          ELV, where our commitment to your health is unwavering, and our Minor
          OT services reflect our dedication to providing quality and
          compassionate care.
        </p>
      </div>
    </>
  );
}

export default Minorcon;
