import { FaBedPulse, FaChildDress } from "react-icons/fa6";
import { FaHospital } from "react-icons/fa";
import { FaUserDoctor } from "react-icons/fa6";
import { BsFillHospitalFill } from "react-icons/bs";
import { GrRestroomWomen } from "react-icons/gr";
import { GiBrokenBone } from "react-icons/gi";

export default function Infobox() {
  return (
    <>
      <section
        style={{ backgroundColor: "#fdfffe" }}
        className="flex justify-center pb-12 relative"
      >
        <div className="containerbox">
          <div className="w-full flex flex-wrap">
            <div className="w-full lg:w-1/2 flex items-center">
              <div>
                <h1 className="text-4xl font-bold leading-snug mb-0">
                  ELV Health <br></br>Care Polyclinics
                </h1>
                {/* <h1
            style={{
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
            }}
            className="text-3xl font-bold leading-snug"
          >
            ELV Health Care Polyclinics
          </h1> */}
                <p className="pt-4">
                  ELV Health Care Poly Clinics stand as pillars of comprehensive
                  and accessible healthcare services, fostering a haven of
                  well-being in the heart of Bengaluru. With a spectrum of
                  medical offerings, these clinics cater to a diverse range of
                  needs, ensuring that every individual receives the attention
                  and care they deserve. From a state-of-the-art day care center
                  and minor OT facilities to a fully stocked pharmacy and
                  primary care services, ELV Health Care strives to be a
                  one-stop solution for various medical requirements.
                </p>

                <div className="w-full justify-center">
                  <div className="flex mt-0">
                    <a
                      href="tel:9535264855"
                      style={{ backgroundColor: "#3d7cc9" }}
                      className="border-inherit border py-2 px-4 rounded-md mt-4 text-white flex items-center sm:mr-2 mr-"
                    >
                      Book Appointment
                    </a>
                  </div>
                </div>
                <h6 className="text-xl mt-4">
                  <i>ELV Health Care Poly Clinics stand as pillars</i>
                </h6>
                <div
                  style={{
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 3px 10px 0px",
                  }}
                  className=" w-full py-8 mt-4 flex lg:hidden xl:flex flex-wrap bg-white rounded-lg"
                >
                  <div className="flex justify-center w-full sm:w-4/12 border-r-0 sm:border-r-2 pb-5 sm:pb-0">
                    <div className="px-3">
                      <div className="flex justify-center mb-2">
                        <GiBrokenBone
                          style={{ color: "#3d7cc9" }}
                          className="text-5xl"
                        />
                      </div>
                      <h6 className="text-center">
                        Premier Healthcare Center for Exceptional Orthopedic
                        Care.
                      </h6>
                    </div>
                  </div>
                  <div className="flex justify-center w-full sm:w-4/12 border-r-0 sm:border-r-2 pb-5 sm:pb-0">
                    <div className="px-3">
                      <div className="flex justify-center mb-2">
                        <GrRestroomWomen
                          style={{ color: "#3d7cc9" }}
                          className="text-5xl"
                        />
                      </div>
                      <h6 className="text-center">
                        Premier Healthcare Center for Exceptional OBG Services.
                      </h6>
                    </div>
                  </div>
                  <div className="flex justify-center w-full sm:w-4/12">
                    <div className="px-3">
                      <div className="flex justify-center mb-2">
                        <FaChildDress
                          style={{ color: "#3d7cc9" }}
                          className="text-5xl"
                        />
                      </div>
                      <h6 className="text-center">
                        The premier healthcare center for outstanding pediatric
                        care.
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-1/2 flex justify-center relative items-center mt-12 lg:mt-0">
              <img
                className="w-full lg:pl-5 bottom-0 rounded-xl"
                src="../photo/hoss.png"
                alt=""
              />
            </div>
          </div>
          {/* <p className="pt-4">
            Distinguished by specialized departments such as Internal Medicine,
            Orthopedics, Cardiology, and more, ELV Health Care Poly Clinics
            house a team of dedicated professionals committed to delivering
            top-notch healthcare. Cutting-edge diagnostic services, including
            ultrasonography, ECHO, X-rays, and blood tests, further bolster
            their commitment to accurate and timely medical assessments.
            Noteworthy is their emphasis on patient convenience, exemplified by
            home blood sample collection services, ensuring that healthcare is
            not just a necessity but a seamlessly integrated part of ones life.
            Situated at MR ASTER, Ground Floor Vijayanagar, Whitefield,
            Bengaluru, Karnataka 560066, ELV Health Care Polyclinic stands as a
            beacon of health and healing in the community, embodying the ethos
            of compassionate and comprehensive healthcare.
          </p> */}
          {/* <div className="mt-4">
            <div className="grid grid-cols-1 sm:grid-cols-3 h-96 md:h-72 lg:h-96 gap-0.5 sm:gap-2">
              <div
                style={{ backgroundImage: "url(../photo/hos1.jpg" }}
                className="border bg-cover bg-left"
              ></div>
              <div className="hidden sm:grid w-full grid-rows-2 gap-0.5 sm:gap-2">
                <div
                  style={{ backgroundImage: "url(../photo/hos2.jpg" }}
                  className=" bg-cover bg-center border h-full"
                ></div>
                <div
                  style={{ backgroundImage: "url(../photo/hos3.jpg" }}
                  className=" bg-cover bg-center border h-full"
                ></div>
              </div>
              <div
                style={{ backgroundImage: "url(../photo/hos4.jpg" }}
                className="border bg-cover bg-right"
              ></div>
            </div>
          </div> */}
          <div className="w-full flex flex-wrap mt-4">
            {/* <div className="w-full sm:w-1/2 lg:w-3/12 p-1">
              <div
                style={{
                  boxShadow:
                    "rgba(50, 50, 93, 0.1) 0px 50px 100px -20px, rgba(0, 0, 0, 0.1) 0px 30px 60px -30px, rgba(10, 37, 64, 0.1) 0px -2px 6px 0px inset",
                }}
                className="border border-lime-600 p-2 flex justify-center rounded"
              >
                <FaHospital style={{ color: "#48b95f" }} className="text-2xl" />
                <h6 className="ml-2">Comprehensive Healthcare Hu</h6>
              </div>
            </div>
            <div className="w-full sm:w-1/2 lg:w-3/12 p-1">
              <div
                style={{
                  boxShadow:
                    "rgba(50, 50, 93, 0.1) 0px 50px 100px -20px, rgba(0, 0, 0, 0.1) 0px 30px 60px -30px, rgba(10, 37, 64, 0.1) 0px -2px 6px 0px inset",
                }}
                className="border border-lime-600 p-2 flex justify-center rounded"
              >
                <FaUserDoctor
                  style={{ color: "#48b95f" }}
                  className="text-2xl"
                />
                <h6 className="ml-2">Specialized Expertise</h6>
              </div>
            </div>
            <div className="w-full sm:w-1/2 lg:w-3/12 p-1">
              <div
                style={{
                  boxShadow:
                    "rgba(50, 50, 93, 0.1) 0px 50px 100px -20px, rgba(0, 0, 0, 0.1) 0px 30px 60px -30px, rgba(10, 37, 64, 0.1) 0px -2px 6px 0px inset",
                }}
                className="border border-lime-600 p-2 flex justify-center rounded"
              >
                <FaBedPulse style={{ color: "#48b95f" }} className="text-2xl" />
                <h6 className="ml-2">Cutting-edge Diagnostics</h6>
              </div>
            </div>
            <div className="w-full sm:w-1/2 lg:w-3/12 p-1">
              <div
                style={{
                  boxShadow:
                    "rgba(50, 50, 93, 0.1) 0px 50px 100px -20px, rgba(0, 0, 0, 0.1) 0px 30px 60px -30px, rgba(10, 37, 64, 0.1) 0px -2px 6px 0px inset",
                }}
                className="border border-lime-600 p-2 flex justify-center rounded"
              >
                <BsFillHospitalFill
                  style={{ color: "#48b95f" }}
                  className="text-2xl"
                />
                <h6 className="ml-2">Patient-Centric Convenience</h6>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}
