import { useState } from "react";

export default function Wecare() {
  const [nums, setNum] = useState<number>(NaN);
  const menu = [
    {
      name: "Explore how ELV Health Care prioritizes a patient-centered approach, ensuring that every individual receives personalized and compassionate care tailored to their unique needs.",
      img: "../photo/ww1.jpg",
    },
    {
      name: "Highlight the range of comprehensive medical services provided by ELV Health Care, demonstrating their commitment to addressing diverse healthcare needs and offering a holistic approach to well-being",
      img: "../photo/ww2.jpg",
    },
    {
      name: "Discuss ELV's commitment to extending care beyond the clinic by offering home healthcare services, emphasizing the convenience and comfort of receiving medical attention in the familiar surroundings of one's home",
      img: "../photo/ww3.jpg",
    },
    {
      name: "Explore ELV Health Care's emergency services and how they prioritize rapid response to critical medical situations, embodying the We Care For You! ethos during urgent healthcare needs.",
      img: "../photo/ww4.jpg",
    },
    {
      name: "Showcase how ELV integrates innovative medical technologies and cutting-edge diagnostics in their services, emphasizing their commitment to accurate and advanced medical assessments for the benefit of their patients.",
      img: "../photo/ww5.jpg",
    },
    {
      name: "Delve into ELV Health Care's community health initiatives, illustrating how they extend their care beyond individual patients to contribute to the overall health and well-being of the community they serve.",
      img: "../photo/ww6.jpg",
    },
  ];
  return (
    <>
      <div className="flex justify-center py-12">
        <div className="containerbox">
          <h1 className="text-4xl font-bold leading-snug mb-4 text-center">
            We Care For You!
          </h1>
          <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-start">
            {menu.map((elem, num) => {
              return (
                <>
                  <button
                    onClick={() => {
                      setNum(num);
                    }}
                    className="p-2 relative z-10"
                  >
                    <div
                      style={{
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                      }}
                      className="w-full flex flex-wrap rounded-xl px-3"
                    >
                      <div className="w-1/3 py-3 px-2">
                        <img
                          style={{ aspectRatio: 5 / 4 }}
                          className="w-full rounded-md"
                          src={elem.img}
                          alt=""
                        />
                      </div>
                      <div className={`w-2/3 flex items-center pl-1 py-3 `}>
                        <h6
                          className={`font-medium text-left ${
                            num == nums ? "line-clamp-none" : "line-clamp-2"
                          }`}
                        >
                          {elem.name}
                        </h6>
                      </div>
                    </div>
                  </button>
                </>
              );
            })}
          </div>
          {/* <div className="w-full justify-center">
            <div className="flex justify-center mt-4">
              <button
                style={{ backgroundColor: "#3d7cc9" }}
                className="border-inherit border py-1 px-4 rounded-md mt-4 text-white flex items-center sm:mr-2 mr-"
              >
                Book Appointment
              </button>
              <button
                style={{ backgroundColor: "#3d7cc9" }}
                className="border-inherit border py-1 px-4 rounded-md mt-4 text-white hidden sm:flex items-center ml-2"
              >
                Book Appointment
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
