import React from "react";
import { FaBusinessTime } from "react-icons/fa6";

function Specilizedser() {
  return (
    <div className="flex justify-center py-16">
      <div className="containerbox">
        <div className="w-full flex flex-wrap">
          <div className="w-full md:w-1/2 xl:w-1/3">
            <div className="flex flex-wrap">
              <div className="p-2 w-full">
                <div>
                  <h1 className="text-4xl font-bold">Scope Of Services</h1>
                  <h6 className="font-medium text-gray-700 flex items-center mt-4">
                    Absolutely! At ELV Health Care Poly Clinics in Bengaluru, we
                    strive to offer a broad spectrum of inclusive and easily
                    accessible healthcare services. Our goal is to establish a
                    sanctuary of well-being right in the midst of the city.
                  </h6>
                  <h6 className="font-medium text-gray-700 flex items-center mt-4">
                    Tailored to cater to various medical requirements, our
                    services guarantee personalized attention and care for every
                    individual, emphasizing their well-deserved support.
                  </h6>
                </div>
              </div>
              <div className="w-full p-2.5 relative mt-3">
                <img
                  className="absolute w-2/12 right-5 top-0"
                  src="../photo/WhatsApp_Image_2023-12-13_at_20.10.53_7fc2d60b-removebg-preview.png"
                  alt=""
                />
                <div
                  style={{
                    boxShadow:
                      "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
                  }}
                  className="p-5 relative"
                >
                  <h1 className="text-2xl font-medium">01</h1>
                  <h6 className="text-3xl font-bold mt-2">
                    General & Special Surgery
                  </h6>
                  <p className="font-medium text-gray-700 line-clamp-6 mt-2 w-2/3">
                    General surgery is like the versatile Swiss Army knife in
                    the medical field, encompassing a wide range of procedures
                    to address various surgical needs. General surgeons are the
                  </p>
                  <div className="flex items-center mt-3">
                    <FaBusinessTime className="text-2xl mr-2" />
                    <p className="text-lg font-medium">
                      ELV HEALTH CARE POLYCLINICS
                    </p>
                  </div>
                  <div className="h-full xl:h-80 overflow-y-scroll">
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      01 OUT PATIENT DEPARTMENT
                    </p>
                    {/* <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      02 IN-PATIENT DEPARTMENT
                    </p> */}
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      02 INCISION AND DRAINAGE
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      03 URS WITH DJ STENTING
                    </p>
                    {/* <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      05 STENT REMOVAL
                    </p> */}
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      04 TRANSURETHRAL RESECTION OF THE PROSTATE [TURP]
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      05 URETHRAL DIALATION
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      06 HYDROCLECTOMY
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      07 SUTURING OF WOUNDS
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      08 TRAUMA AND HEAD INJURY
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      09 GENERAL AND LAPROCOPIC PROCEDURES
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 xl:w-1/3">
            <div className="flex flex-wrap">
              <div className="w-full p-2.5 relative mt-3">
                <img
                  className="absolute w-2/12 right-5 top-0"
                  src="../photo/3d-male-medical-figure-landing-one-foot-removebg-preview.png"
                  alt=""
                />
                <div
                  style={{
                    boxShadow:
                      "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
                  }}
                  className="p-5 relative"
                >
                  <h1 className="text-2xl font-medium">02</h1>
                  <h6 className="text-3xl font-bold mt-2">Orthopedic</h6>
                  <p className="font-medium text-gray-700 line-clamp-6 mt-2 w-2/3">
                    Orthopedics is like the architectural engineering of the
                    human body, specializing in the musculoskeletal system that
                    provides the framework for our daily movements
                  </p>
                  <div className="flex items-center mt-3">
                    <FaBusinessTime className="text-2xl mr-2" />
                    <p className="text-lg font-medium">
                      ELV HEALTH CARE POLYCLINICS
                    </p>
                  </div>
                  <div className="h-full md:h-96 lg:h-40 overflow-y-scroll">
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      01 OUT PATIENT DEPARTMENT
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      02 IN-PATIENT DEPARTMENT
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      03 CLOSED REDUCTION SURGERY
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      04 TRAUMA CARE
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      05 MAJOR BONE FRACTURES
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      06 SPINE SURGERIES
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      07 EPIDURAL STEROID
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      08 DEBRIDEMENT & SUTURING
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      09 TENDON REPAIR
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      10 ARTHROSCOPY
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      11 ARTHROSCOPIC SURGERY
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      12 OPEN REDUCTION SURGERY
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      13 DISCECTOMY
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      14 ACL RECONSTRUCTION
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      15 KNEE REPLACEMENT SURGERY
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      06 K WIRE FIXATION
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full p-2.5 relative mt-3">
                <img
                  className="absolute w-2/12 right-5 top-0"
                  src="../photo/WhatsApp_Image_2023-12-13_at_23.02.13_703cc1fe-removebg-preview.png"
                  alt=""
                />
                <div
                  style={{
                    boxShadow:
                      "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
                  }}
                  className="p-5 relative"
                >
                  <h1 className="text-2xl font-medium">03</h1>
                  <h6 className="text-3xl font-bold mt-2">Pediatrics</h6>
                  <p className="font-medium text-gray-700 line-clamp-6 mt-2 w-2/3">
                    CGeneral surgery is like the versatile Swiss Army knife in
                    the medical field, encompassing a wide range of procedures
                    to address various surgical needs. General surgeons are the
                  </p>
                  <div className="flex items-center mt-3">
                    <FaBusinessTime className="text-2xl mr-2" />
                    <p className="text-lg font-medium">
                      ELV HEALTH CARE POLYCLINICS
                    </p>
                  </div>
                  <div className=" h-full md:h-64 xl:h-40 overflow-y-scroll">
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      01 OUT PATIENT DEPARTMENT
                    </p>
                    {/* <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      02 IN-PATIENT DEPARTMENT
                    </p> */}
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      02 NEW BORN AND IMMUNIZATION
                    </p>
                    {/* <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      04 NEONATAL ICU
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      05 SICK NEONATES
                    </p> */}
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      03 SEPSIS BRONCHOPNEUMONIA
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      04 GE & FEVERS
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      05 FITS NEBULIZATION
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2 xl:w-1/3 block md:hidden xl:block">
            <div className="flex flex-wrap">
              <div className="w-full p-2.5 relative mt-3">
                <img
                  className="absolute w-2/12 right-5 top-0"
                  src="../photo/WhatsApp_Image_2023-12-13_at_23.09.46_01bcc831-removebg-preview-fotor-20231213231217.png"
                  alt=""
                />
                <div
                  style={{
                    boxShadow:
                      "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
                  }}
                  className="p-5 relative"
                >
                  <h1 className="text-2xl font-medium">04</h1>
                  <h6 className="text-3xl font-bold mt-2">
                    Obstetrics and Gynecology
                  </h6>
                  <p className="font-medium text-gray-700 line-clamp-6 mt-2 w-2/3">
                    Obstetrics and Gynecology (OB-GYN) is like the specialized
                    caretaker of women's reproductive health, encompassing the
                    fields of pregnancy, childbirth, and gynecological care
                  </p>
                  <div className="flex items-center mt-3">
                    <FaBusinessTime className="text-2xl mr-2" />
                    <p className="text-lg font-medium">
                      ELV HEALTH CARE POLYCLINICS
                    </p>
                  </div>
                  <div className="h-full overflow-y-scroll">
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      01 OUT PATIENT DEPARTMENT
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      02 IN-PATIENT DEPARTMENT
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      03 ANTENATAL CARE
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      04 INFERTILITY WORK UP [HYSTEROSALPINGOGRAPHY]
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      05 NON-DESENT VAGINAL HYSTERECTOMY
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      06 TUBECTOMY
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      07 LAPROSCOPIC TUBAL LIGATION
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      08 INTRA-UTERINE CONTRACEPTIVE DEVICE
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      09 PAPANICOLAOU SMEAR
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      10 ENDOMETRIAL AND CERVICAL BIOPSIES
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      11 HIGH-RISK PREGNANCY
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      12 NORMAL/INSTRUMENTAL DELIVERIES
                    </p>
                    <p className="font-semiboldmedium border-b-2 border-gray-600 border-dotted pb-3 pt-2">
                      13 CESARIAN DELIVERY
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Specilizedser;
