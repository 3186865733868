import React, { useEffect } from "react";
import Specializedclinics from "./Specializedclinics";
import Infiry from "../../About/Infiry";
import Callin from "../../About/Callin";
import Servicebanner from "../../Services/Servicebanner";
import NumberInfo from "../../Home/NumberInfo";
import Doctorbox from "../../Home/Docterbox";
import Divser from "../../About/Divser";
import Divider from "../../Divider";
import ServiceReview from "../../Services/ServiceReview";
import Infobox from "../../About/Infobox";
import Qns from "../../Services/Qns";
import Contactform from "../../About/Contactform";
import { movetop } from "../../../fun";

function Specialize() {
  type elem = {
    oneBody?: JSX.Element;
    twoBody?: JSX.Element;
    threeBody?: JSX.Element;
  };
  const elemnt: elem = {
    oneBody: <Specializedclinics />,
    twoBody: <Infiry />,
    threeBody: <Callin />,
  };
  const elemnt2: elem = {
    oneBody: <Qns />,
    twoBody: <Contactform />,
  };

  useEffect(() => {
    movetop();
  }, []);
  return (
    <>
      <Servicebanner name="Specialized Clinics" banner={"../photo/dd23.jpg"} />
      <Infobox />
      {/* <Doctorbox /> */}
      <div className="flex justify-center py-6">
        <div className="containerbox">
          <p className="mb-4">
            ELV Health Care Polyclinics in Bengaluru offer a wide range of
            medical services, ensuring that individuals receive the attention
            and care they need. From a day care center and minor OT facilities
            to a fully stocked pharmacy and primary care services, ELV Health
            Care aims to be a one-stop solution for various medical
            requirements. Specialized departments, including Internal Medicine,
            Orthopedics, Cardiology, and more, house dedicated professionals
            committed to delivering top-notch healthcare.
          </p>
          <p>
            Certainly! ELV Health Care Polyclinics offer an extensive range of
            specialized clinics, ensuring a comprehensive approach to addressing
            the diverse healthcare needs of individuals. Here are some of the
            dedicated clinics available at ELV Health Care Polyclinics:
          </p>
        </div>
      </div>
      <Divser elemnt={elemnt} />

      <div className="pt-10 pb-10">
        <Divider />
      </div>
      <Divser elemnt={elemnt2} />

      <div className="pt-10 pb-4">
        <Divider />
      </div>
      <ServiceReview />
    </>
  );
}

export default Specialize;
