"use client";
import { IoIosStar } from "react-icons/io";
import { MdMedicalServices } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { useEffect, useState } from "react";
import React from "react";
import Slider, { Settings } from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
export default function ServiceList() {
  const [number, setNumber] = useState<number>(NaN);
  const settings: Settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  function handleWindowLoad() {
    if (window.innerWidth > 1280) {
      setNumber(6);
    } else if (window.innerWidth > 1023) {
      setNumber(4);
    } else if (window.innerWidth > 767) {
      setNumber(5);
    } else if (window.innerWidth > 639) {
      setNumber(5);
    } else {
      setNumber(5);
    }
  }
  useEffect(() => {
    handleWindowLoad();
    window.addEventListener("resize", handleWindowLoad);
    return () => {
      window.removeEventListener("resize", handleWindowLoad);
    };
  }, []);
  const menus = [
    {
      name: "Dermatology",
      img: "../photo/dermatology (1).png",
      link: "/bestdermatologyservicesinwhitefield",
    },
    {
      name: "Diabetes",
      img: "../photo/glucometer.png",
      link: "/bestdiabetesinwhitefield",
    },
    {
      name: "Dental care",
      img: "../photo/teeth.png",
      link: "/bestdentalinwhitefield",
    },
    {
      name: "Orthopedic",
      img: "../photo/orthopedics.png",
      link: "/bestorthopedicservicesinwhitefield",
    },
    {
      name: "General surgery",
      img: "../photo/surgery-tools.png",
      link: "/bestgeneralsurgeryinwhitefield",
    },
    {
      name: "Pediatrics",
      img: "../photo/pediatric.png",
      link: "/bestpediatricsservicesinwhitefield",
    },
    {
      name: "ENT",
      img: "../photo/pharynx.png",
      link: "/bestentinwhitefield",
    },
    {
      name: "Physiotherapy",
      img: "../photo/physical-therapy.png",
      link: "/bestphysiotherapyservicesinwhitefield",
    },
    {
      name: "Urology",
      img: "../photo/kidney.png",
      link: "/besturologyservicesinwhitefield",
    },
    {
      name: "Neurology",
      img: "../photo/brain (1).png",
      link: "/bestneurologyservicesinwhitefield",
    },
    {
      name: "Psychiatry",
      img: "../photo/8842496.png",
      link: "/bestpsychiatryservicesinwhitefield",
    },
    {
      name: "Obstetrics and Gynecology",
      img: "../photo/12106197.png",
      link: "/bestobgservicesinwhitefield",
    },
    {
      name: "Cardiology",
      img: "../photo/1028356.png",
      link: "/bestcardiologyservicesinwhitefield",
    },
    {
      name: "Internal Medicine",
      img: "../photo/8752669.png",
      link: "/bestinternalmedicineinwhitefield",
    },
  ];
  return (
    <>
      <section
        style={{ backgroundColor: "#fdfffe" }}
        className="w-full flex justify-center"
      >
        <div className="containerbox w-full pb-6">
          <div>
            <div className="grid grid-cols-2 w-full sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-7 mt-6">
              {menus.map((elem, num) => {
                if (num < number) {
                  return (
                    <>
                      <Link to={`${elem.link}`} className="w-full mb-3 px-1">
                        <div
                          style={{
                            boxShadow: "rgba(0, 0, 0, 0.1) 0px 3px 6px",
                            // border: "1.5px solid #3d7cc9",
                          }}
                          className="w-full flex justify-center mb-2 rounded-xl"
                        >
                          <div className="w-12 h-16 sm:w-20 sm:h-20 flex justify-center items-center rounded">
                            <img
                              className="w-10 sm:w-16 p-1 sm:p-2.5"
                              src={elem.img}
                              alt=""
                            />
                            <div>
                              <p className="text-center">{elem.name}</p>
                            </div>
                          </div>
                        </div>
                        {/* <p className="text-center">{elem.name}</p> */}
                      </Link>
                    </>
                  );
                }
              })}
              <button className="w-full mb-3 px-1">
                <div
                  onClick={() => {
                    number !== menus.length + 1
                      ? setNumber(menus.length + 1)
                      : handleWindowLoad();
                  }}
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 3px 6px",
                    // border: "1.5px solid #3d7cc9",
                  }}
                  className="w-full flex justify-center mb-2 bg-blue-500 rounded-xl"
                >
                  <div className="w-12 h-16 sm:w-20 sm:h-20 flex justify-center items-center rounded">
                    <img
                      className="w-10 sm:w-16 p-1 sm:p-2.5"
                      src="../photo/button.png"
                      alt=""
                    />
                    <div>
                      <p className="text-center text-white font-medium">
                        {number !== menus.length + 1
                          ? "Read More"
                          : "Read Less"}
                      </p>
                    </div>
                  </div>
                </div>
                {/* <p className="text-center">{elem.name}</p> */}
              </button>
            </div>
          </div>
          {/* <div className="w-full flex justify-center">
            <button
              onClick={() => {
                number !== menus.length + 1
                  ? setNumber(menus.length + 1)
                  : handleWindowLoad();
              }}
              style={{ backgroundColor: "#48b95f" }}
              className="border-inherit border px-4 py-1 rounded-md mt-2 text-white"
            >
              {number !== menus.length + 1 ? "Read More" : "Read Less"}
            </button>
          </div> */}
        </div>
      </section>
    </>
  );
}
